import { FC, ReactElement, useEffect, useState } from 'react';
import { AlertMessage, TopbarPublic } from 'components';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from 'core/enums';
import { useMessageSession } from 'hooks';

export type ContactInputs = {
  name: string;
  contact_details: string;
  subject: string;
  message: string;
};

const schema: yup.SchemaOf<ContactInputs> = yup
  .object()
  .shape({
    name: yup.string().required(ErrorMessage.REQUIRED),
    contact_details: yup.string().required(ErrorMessage.REQUIRED),
    subject: yup.string().test('subject', ErrorMessage.REQUIRED, (value) => {
      if (!value || value === 'NONE') return false;
      return true;
    }),
    message: yup.string().required(ErrorMessage.REQUIRED),
  })
  .defined();

export const ContactPage: FC = (): ReactElement => {
  const history = useHistory();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const {
    isLoading: isSubmitting,
    isError,
    mutateAsync,
    reset: resetApi,
  } = useMessageSession();
  const {
    register,
    unregister,
    errors,
    handleSubmit,
    reset: resetForm,
  } = useForm<ContactInputs>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    return () => {
      unregister(['name', 'contact_details', 'subject', 'message']);
    };
  }, [unregister]);

  const renderPage = (): ReactElement => (
    <>
      <header>
        <TopbarPublic />
        <div className="hero contact">
          <Container className="content">
            <h1>Contact Us</h1>
          </Container>
        </div>
      </header>
      <main className="hero-main">
        <Container className="hero-form">
          <Card body>
            <AlertMessage visible={isSuccess} variant="success">
              Message Sent!
            </AlertMessage>
            <AlertMessage visible={isError} />
            <Form onSubmit={handleSubmit(contact)}>
              <Form.Group controlId="name">
                <Form.Label className="required">Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your name"
                  name="name"
                  ref={register}
                  isInvalid={!!errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name?.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="contact_details">
                <Form.Label className="required">Contact Details</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your email address or any contact number"
                  name="contact_details"
                  ref={register}
                  isInvalid={!!errors.contact_details}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.contact_details?.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="subject">
                <Form.Label className="required">Subject</Form.Label>
                <Form.Control
                  as="select"
                  name="subject"
                  ref={register}
                  isInvalid={!!errors.subject}
                >
                  <option value="NONE">Select a subject</option>
                  <option value="Teletherapy Program">
                    Teletherapy Program
                  </option>
                  <option value="Partnership/Collaboration">
                    Partnership/Collaboration
                  </option>
                  <option value="Therapy Needs">Therapy Needs</option>
                  <option value="Other Queries">Other Queries</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.subject?.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="message">
                <Form.Label className="required">Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  placeholder="Write your message here"
                  name="message"
                  ref={register}
                  isInvalid={!!errors.message}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.message?.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Button
                variant="light"
                disabled={isSubmitting}
                onClick={redirectToHomePage}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                className="ml-2"
                disabled={isSubmitting}
              >
                Send
              </Button>
            </Form>
          </Card>
        </Container>
      </main>
    </>
  );

  const contact = async (inputs: ContactInputs): Promise<void> => {
    resetApi();
    setIsSuccess(false);
    await mutateAsync(inputs);
    setIsSuccess(true);
    resetForm({
      name: '',
      contact_details: '',
      subject: 'NONE',
      message: '',
    });
  };

  const redirectToHomePage = (): void => {
    history.push('/');
  };

  return renderPage();
};

export default ContactPage;
