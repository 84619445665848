import { FC, ReactElement } from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { UnauthenticatedPage, UnauthorizedPage } from 'pages';
import Container from 'react-bootstrap/Container';
import { TopbarPrivate } from '..';
import { Role } from 'core/enums';
import { useCurrentUser } from 'hooks';

export interface PrivateRouteProps extends RouteProps {
  roles?: Role[];
}

export const PrivateRoute: FC<PrivateRouteProps> = (props): ReactElement => {
  const { roles } = props;
  const {
    isLoading,
    data: user,
    isAuthenticated,
    isRegistrationStepsCompleted,
  } = useCurrentUser();

  const renderRoute = (): ReactElement => {
    if (isLoading) return <>Loading... Please Wait</>;
    if (!isAuthenticated || !user) return renderUnauthenticatedPage();
    if (!isRegistrationStepsCompleted) return redirectToUserSetupPage();
    if (!!roles && roles.length > 0 && !roles.includes(user.role))
      return renderUnauthorizedPage();
    return renderPage();
  };

  const renderUnauthenticatedPage = (): ReactElement => <UnauthenticatedPage />;

  const redirectToUserSetupPage = (): ReactElement => (
    <Redirect to="/users/setup" />
  );

  const renderUnauthorizedPage = (): ReactElement => <UnauthorizedPage />;

  const renderPage = (): ReactElement => (
    <>
      <TopbarPrivate />
      <main className="private-main">
        <Container>
          <Route {...props} />
        </Container>
      </main>
    </>
  );

  return renderRoute();
};

export default PrivateRoute;
