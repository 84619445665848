import { ReactElement, ReactNode } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { setIsUpdatingRecommendationTo } from 'redux/session';
import { useAppDispatch } from 'redux/store';
import { ParagraphList } from 'components';
import { useCurrentUser, useSession, useSessionInfoParams } from 'hooks';

export const Recommendation = (): ReactElement => {
  const dispatch = useAppDispatch();
  const params = useSessionInfoParams();
  const { isPhysicianUser } = useCurrentUser();
  const {
    data: sessionView,
    isPaid,
    recommendations,
  } = useSession(params.session_uuid, params.pat_uuid);

  const renderMain = (): ReactElement => (
    <Card className="mt-3">
      <Card.Body>
        <Card.Title className="card-controls">
          Recommendation for Professionals
          {isPhysicianUser && isPaid && (
            <Button
              variant="link"
              className="btn-link-secondary"
              onClick={edit}
            >
              Edit
            </Button>
          )}
        </Card.Title>
        {renderNoRecommendation()}
        <ParagraphList text={recommendations} />
      </Card.Body>
    </Card>
  );

  const renderNoRecommendation = (): ReactNode => {
    if (!sessionView?.therapy_recommendation)
      return <Alert variant="info">No recommendation.</Alert>;
  };

  const edit = (): void => {
    dispatch(setIsUpdatingRecommendationTo(true));
  };

  return renderMain();
};

export default Recommendation;
