import { ReactElement } from 'react';
import { TopbarHome } from 'components';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';

export const Header = (): ReactElement => {
  const history = useHistory();

  const renderMain = (): ReactElement => (
    <header>
      <TopbarHome />
      <div className="hero-light home">
        <Container className="content">
          <div className="book-now">
            <Button
              className="book"
              variant="primary"
              block
              size="lg"
              onClick={redirectToRegistrationPage}
            >
              Book Now
            </Button>
            <p className="title">
              Quality Teletherapy Program in the Philippines
            </p>
            <p className="description">
              We provide assistance on scheduling, assessment, intervention and
              documentation during your therapy sessions.
            </p>
          </div>
        </Container>
      </div>
    </header>
  );

  const redirectToRegistrationPage = (): void => {
    history.push('/register/patient');
  };

  return renderMain();
};

export default Header;
