import { FC, ReactElement, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollToTop: FC = (): ReactElement => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <></>;
};

export default ScrollToTop;
