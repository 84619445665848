import { FC, ReactElement, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { AlertMessage } from 'components';
import { UserUpdate } from 'api';
import { ErrorMessage } from 'core/enums';
import { useCurrentUser, useUpdateUser } from 'hooks';

export type PhysicianInfoFormInputs = {
  online_rate: string;
  zoom: string;
  zoom_meeting_id: string;
  zoom_password: string;
  bank: string;
  bank_name: string;
  bank_account: string;
};

const schema: yup.SchemaOf<PhysicianInfoFormInputs> = yup
  .object()
  .shape({
    online_rate: yup
      .string()
      .required(ErrorMessage.REQUIRED)
      .test('range', ErrorMessage.ONLINE_RATE, (value) => {
        if (!value) return false;
        const i = parseInt(value);
        return i >= 100 && i <= 2000;
      }),
    zoom: yup.string().required(ErrorMessage.REQUIRED).url(),
    zoom_meeting_id: yup.string().required(ErrorMessage.REQUIRED),
    zoom_password: yup.string().required(ErrorMessage.REQUIRED),
    bank: yup.string().required(ErrorMessage.REQUIRED),
    bank_name: yup.string().required(ErrorMessage.REQUIRED),
    bank_account: yup.string().required(ErrorMessage.REQUIRED),
  })
  .defined();

export const PhysicianInfo: FC = (): ReactElement => {
  const { data: user, isPhysicianInfoStep } = useCurrentUser();
  const { isLoading: isSubmitting, isError, mutateAsync } = useUpdateUser();
  const { register, handleSubmit, unregister, errors } =
    useForm<PhysicianInfoFormInputs>({
      resolver: yupResolver(schema),
    });

  useEffect(() => {
    return () => {
      unregister([
        'online_rate',
        'zoom',
        'zoom_meeting_id',
        'zoom_password',
        'bank',
        'bank_name',
        'bank_account',
      ]);
    };
  }, [unregister]);

  const renderInfo = (): ReactElement => (
    <Card body>
      <h5 className="info-title">Fill in your therapist information.</h5>
      <AlertMessage visible={isError} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group controlId="online_rate">
          <Form.Label className="required">Online Hourly Rate</Form.Label>
          <Form.Control
            type="number"
            min="1"
            placeholder="Enter your online hourly rate"
            name="online_rate"
            ref={register}
            isInvalid={!!errors.online_rate}
          />
          <Form.Text className="text-muted">In Philippine peso</Form.Text>
          <Form.Control.Feedback type="invalid">
            {errors.online_rate?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="zoom">
          <Form.Label className="required">Zoom Account URL</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your Zoom account URL"
            name="zoom"
            ref={register}
            isInvalid={!!errors.zoom}
          />
          <Form.Text className="text-muted">
            Ex. https://zoom.us/xxxxxxxxxxxxxxxxx
          </Form.Text>
          <Form.Control.Feedback type="invalid">
            {errors.zoom?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="zoom_meeting_id">
          <Form.Label className="required">Zoom Meeting ID</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your Zoom meeting ID"
            name="zoom_meeting_id"
            ref={register}
            isInvalid={!!errors.zoom_meeting_id}
          />
          <Form.Control.Feedback type="invalid">
            {errors.zoom_meeting_id?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="zoom_password">
          <Form.Label className="required">Zoom Password</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your Zoom password"
            name="zoom_password"
            ref={register}
            isInvalid={!!errors.zoom_password}
          />
          <Form.Control.Feedback type="invalid">
            {errors.zoom_password?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="bank">
          <Form.Label className="required">Bank Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your bank name"
            name="bank"
            ref={register}
            isInvalid={!!errors.bank}
          />
          <Form.Control.Feedback type="invalid">
            {errors.bank?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="bank_name">
          <Form.Label className="required">Bank Account Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your bank account name"
            name="bank_name"
            ref={register}
            isInvalid={!!errors.bank_name}
          />
          <Form.Control.Feedback type="invalid">
            {errors.bank_name?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="bank_account">
          <Form.Label className="required">Bank Account Number</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your bank account number"
            name="bank_account"
            ref={register}
            isInvalid={!!errors.bank_account}
          />
          <Form.Control.Feedback type="invalid">
            {errors.bank_account?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Button variant="primary" type="submit" disabled={isSubmitting}>
          Next
        </Button>
      </Form>
    </Card>
  );

  const onSubmit = async (data: PhysicianInfoFormInputs): Promise<void> => {
    if (!user) {
      return;
    }
    let uu: UserUpdate = {
      ...user,
      ...data,
    };
    await mutateAsync(uu);
  };

  if (!isPhysicianInfoStep) return <></>;

  return renderInfo();
};

export default PhysicianInfo;
