import { FC, ReactElement } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { AlertMessage } from 'components';
import { useSelector } from 'react-redux';
import {
  DayNumbers,
  selectDay,
  selectIsDeleting,
  selectWeekSlot,
  setIsDeletingTo,
  setWeekSlot,
} from 'redux/schedule';
import { useAppDispatch } from 'redux/store';
import moment from 'moment';
import { useCurrentUser, useDeleteSchedule, useScheduleSlots } from 'hooks';
import { User } from 'redux/auth';

export const ScheduleDelete: FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const { data: user } = useCurrentUser();
  const isDeleting = useSelector(selectIsDeleting);
  const weekSlot = useSelector(selectWeekSlot);
  const day = useSelector(selectDay);
  const { data: slots = [] } = useScheduleSlots({
    isEnabled: isDeleting,
    phy_uuid: (user as User).uuid,
  });
  const { isLoading: isSubmitting, isError, mutateAsync } = useDeleteSchedule(
    (user as User).uuid,
  );

  if (!weekSlot || !day) return <></>;

  const renderModal = (): ReactElement => (
    <Modal show={isDeleting} onHide={stopDeleting}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Slot</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AlertMessage visible={isError} />
        <ul>
          <li>{moment(`6/${day}/2020`, 'M/D/YYYY').format('dddd')}</li>
          <li>{weekSlot.time_period}</li>
          <li>
            {!!weekSlot[(day === 7 ? 0 : day) as DayNumbers]
              ? 'APPROVED'
              : undefined}
          </li>
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" disabled={isSubmitting} onClick={stopDeleting}>
          Cancel
        </Button>
        <Button
          variant="danger"
          type="submit"
          disabled={isSubmitting}
          onClick={submit}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const stopDeleting = (): void => {
    if (isSubmitting) return;
    dispatch(setIsDeletingTo(false));
  };

  const submit = async (): Promise<void> => {
    const cd = moment(
      `6/${day}/2020 ${weekSlot.time_id}`,
      'M/D/YYYY Hmm',
    ).format('M/D/YYYY h:mm:ss A');
    const s = slots.find((_s): boolean => _s.consultation_date === cd);
    if (!s) {
      return;
    }
    await mutateAsync(s.id);
    stopDeleting();
    dispatch(setWeekSlot({}));
  };

  return renderModal();
};

export default ScheduleDelete;
