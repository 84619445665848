import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'redux/store';
import { ScheduleState } from '.';
import { WeekSlot } from './schedule.types';

const initialState: ScheduleState = {
  isCreating: false,
  isDeleting: false,
  weekSlot: undefined,
  day: undefined,
};

export const scheduleSlice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    setIsCreatingTo: (state, action: PayloadAction<boolean>): void => {
      state.isCreating = action.payload;
    },
    setIsDeletingTo: (state, action: PayloadAction<boolean>): void => {
      state.isDeleting = action.payload;
    },
    setWeekSlot: (
      state,
      action: PayloadAction<{ weekSlot?: WeekSlot; day?: number }>,
    ): void => {
      const { weekSlot, day } = action.payload;
      state.weekSlot = weekSlot;
      state.day = day;
    },
    reset: (_state, _action: PayloadAction): ScheduleState => ({
      ...initialState,
    }),
  },
});
const { reset } = scheduleSlice.actions;
export const {
  setIsCreatingTo,
  setIsDeletingTo,
  setWeekSlot,
} = scheduleSlice.actions;

export const resetSchedule = (): AppThunk => async (
  dispatch,
  _getState,
): Promise<void> => {
  dispatch(reset());
};
