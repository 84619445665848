import { ReactElement, ReactNode } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { setIsUpdatingFeedbackImproveTo } from 'redux/session';
import { useAppDispatch } from 'redux/store';
import { useCurrentUser, useSession, useSessionInfoParams } from 'hooks';

export const FeedbackImprove = (): ReactElement => {
  const dispatch = useAppDispatch();
  const params = useSessionInfoParams();
  const { isPhysicianUser } = useCurrentUser();
  const { data: sessionView, isPaid } = useSession(
    params.session_uuid,
    params.pat_uuid,
  );

  const renderMain = (): ReactElement => (
    <Card className="mt-3">
      <Card.Body>
        <Card.Title className="card-controls">
          Check In: What do you want to improve?
          {isPhysicianUser && isPaid && (
            <Button
              variant="link"
              className="btn-link-secondary"
              onClick={edit}
            >
              Edit
            </Button>
          )}
        </Card.Title>
        {renderNoFeedbackImprove()}
        {sessionView?.feedback_improve}
      </Card.Body>
    </Card>
  );

  const edit = (): void => {
    dispatch(setIsUpdatingFeedbackImproveTo(true));
  };

  const renderNoFeedbackImprove = (): ReactNode => {
    if (!sessionView?.feedback_improve)
      return <Alert variant="info">No answer.</Alert>;
  };

  return renderMain();
};

export default FeedbackImprove;
