import { CoreService } from '.';
import { Slot } from 'redux/schedule';

export { ScheduleService };

export type ScheduleCreate = {
  date_time: string;
};

const w = window as any;
w.getSlots = null;
w.createSchedule = null;
w.deleteSchedule = null;

class ScheduleService {
  public static async getSlots(user_uuid: string): Promise<Slot[]> {
    return await CoreService.get(
      `/api/cc-service/physician/get-schedule/${user_uuid}`,
      'getSlots',
    );
  }

  public static cancelGetSlots(): void {
    CoreService.cancelRequest('getSlots');
  }

  public static createSchedule(schedule: ScheduleCreate): Promise<void> {
    return CoreService.post(
      '/api/cc-service/physician/upsert-schedule',
      [
        {
          params0: '00000000-0000-0000-0000-000000000000',
          params1: schedule.date_time,
        },
      ],
      'createSchedule',
    );
  }

  public static cancelCreateSchedule(): void {
    CoreService.cancelRequest('createSchedule');
  }

  public static deleteSchedule(schedule_id: number): Promise<void> {
    return CoreService.delete(
      `/api/cc-service/physician/delete-schedule/${schedule_id}`,
      'deleteSchedule',
    );
  }

  public static cancelDeleteSchedule(): void {
    CoreService.cancelRequest('deleteSchedule');
  }
}
