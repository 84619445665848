import { CoreService } from '.';
import { Patient } from 'redux/patient';

export { PatientService };

export type PatientCreate = {
  first_name: string;
  middle_name: string;
  last_name: string;
  diagnosis: string;
  gender: string;
  birth_date: string;
  address: string;
};

export type PatientUpdate = PatientCreate & {
  uuid: string;
};

const w = window as any;
w.getPatients = null;
w.getPatient = null;
w.createPatient = null;
w.updatePatient = null;

class PatientService {
  public static getPatients(user_uuid: string): Promise<Patient[]> {
    return CoreService.get(
      `/api/cc-service/patient/get/${user_uuid}`,
      'getPatients',
    );
  }

  public static cancelGetPatients(): void {
    CoreService.cancelRequest('getPatients');
  }

  public static async getPatient(patient_uuid: string): Promise<Patient> {
    const r = await CoreService.get<Patient[]>(
      `/api/cc-service/patient/get/${patient_uuid}`,
      'getPatient',
    );
    if (r.length !== 1) throw new Error(`Response has ${r.length} item(s).`);
    return r[0];
  }

  public static cancelGetPatient(): void {
    CoreService.cancelRequest('getPatient');
  }

  public static createPatient(patient: PatientCreate): Promise<void> {
    return CoreService.put(
      '/api/cc-service/patient/insert',
      {
        params0: patient.first_name,
        params1: patient.middle_name,
        params2: patient.last_name,
        params3: patient.birth_date,
        params4: patient.address,
        params5: '',
        params6: '',
        params7: patient.gender,
        params8: '',
        params10: patient.diagnosis,
      },
      'createPatient',
    );
  }

  public static cancelCreatePatient(): void {
    CoreService.cancelRequest('createPatient');
  }

  public static updatePatient(patient: PatientUpdate): Promise<void> {
    return CoreService.post(
      '/api/cc-service/patient/update',
      {
        params0: patient.first_name,
        params1: patient.middle_name,
        params2: patient.last_name,
        params3: patient.birth_date,
        params4: patient.address,
        params5: '',
        params6: '',
        params7: patient.gender,
        params8: '',
        params9: patient.uuid,
        params10: patient.diagnosis,
      },
      'updatePatient',
    );
  }

  public static cancelUpdatePatient(): void {
    CoreService.cancelRequest('updatePatient');
  }
}
