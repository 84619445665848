import { FC, ReactElement, ReactNodeArray, useEffect, useState } from 'react';
import { PageHeader } from 'components';
import {
  SessionInfo,
  MeetingInfo,
  LicenseInfo,
  TaskList,
  TaskCreate,
  TaskUpdate,
  TaskDelete,
  FeedbackLike,
  FeedbackLikeUpdate,
  FeedbackImprove,
  FeedbackImproveUpdate,
  Homework,
  HomeworkUpdate,
  Recommendation,
  RecommendationUpdate,
  SessionComplete,
  SessionCancel,
  VoucherPay,
  // Payment,
  FeedbackAlert,
  Reschedule,
} from './components';
import { useAppDispatch } from 'redux/store';
import {
  resetSession,
  setIsCancellingTo,
  setIsCompletingTo,
  // setIsGcashPayingTo,
  setIsReschedulingTo,
  setIsVoucherPayingTo,
} from 'redux/session';
import Button from 'react-bootstrap/Button';
import { setIsCreatingTo } from 'redux/task';
import { useCurrentUser, useSession, useSessionInfoParams } from 'hooks';

export const SessionInfoPage: FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const params = useSessionInfoParams();
  const { isPatientUser, isPhysicianUser } = useCurrentUser();
  const sessionControls: ReactNodeArray = [];
  const taskControls: ReactNodeArray = [];
  const {
    data: sessionView,
    isSubmitted,
    isPaid,
    isCompleted,
    isCancelled,
    canReschedule,
  } = useSession(params.session_uuid, params.pat_uuid);
  const [isLicenseVisible, setIsLicenseVisible] = useState(false);

  useEffect(() => {
    window.onafterprint = (): void => {
      setIsLicenseVisible(false);
    };
    return () => {
      window.onafterprint = null;
      dispatch(resetSession());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isLicenseVisible) {
      window.print();
    }
  }, [isLicenseVisible]);

  if (isSubmitted) {
    if (isPatientUser) {
      const cancelSession = (): void => {
        dispatch(setIsCancellingTo(true));
      };

      const voucherPaySession = (): void => {
        dispatch(setIsVoucherPayingTo(true));
      };

      // const gcashPaySession = (): void => {
      //   dispatch(setIsGcashPayingTo(true));
      // };

      sessionControls.push(
        <Button key="cancel" variant="link" size="sm" onClick={cancelSession}>
          Cancel Session
        </Button>,
        <Button
          key="voucher"
          size="sm"
          className="ml-1"
          onClick={voucherPaySession}
        >
          Use Voucher
        </Button>,
        // <Button
        //   key="pay"
        //   variant="success"
        //   size="sm"
        //   className="ml-1"
        //   onClick={gcashPaySession}
        // >
        //   Pay
        // </Button>,
      );
    }
  }

  if (isPaid) {
    if (isPhysicianUser) {
      const completeSession = (): void => {
        dispatch(setIsCompletingTo(true));
      };

      sessionControls.push(
        <Button key="complete" size="sm" onClick={completeSession}>
          Complete
        </Button>,
      );

      const addTask = (): void => {
        dispatch(setIsCreatingTo(true));
      };

      taskControls.push(
        <Button key="add" size="sm" onClick={addTask}>
          Add Task
        </Button>,
      );
    } else if (isPatientUser && canReschedule) {
      const rescheduleSession = (): void => {
        dispatch(setIsReschedulingTo(true));
      };

      sessionControls.push(
        <Button key="reschedule" size="sm" onClick={rescheduleSession}>
          Reschedule
        </Button>,
      );
    }
  }

  if (isCompleted) {
    const printSession = (): void => {
      setIsLicenseVisible(true);
    };

    sessionControls.push(
      <Button key="print" size="sm" onClick={printSession}>
        Print
      </Button>,
    );
  }

  if (!sessionView) return <span>Loading... Please Wait</span>;

  return (
    <>
      <PageHeader title="Session Information" controls={sessionControls} />
      <FeedbackAlert />
      <VoucherPay />
      {/* <Payment /> */}
      <SessionCancel />
      <Reschedule />
      <SessionInfo />
      {!isSubmitted && !isCancelled && (
        <>
          <MeetingInfo />
          {isLicenseVisible && <LicenseInfo />}
          <PageHeader title="Tasks" controls={taskControls} />
          <TaskList />
          <TaskCreate />
          <TaskUpdate />
          <TaskDelete />
          <hr />
          <FeedbackLike />
          <FeedbackLikeUpdate />
          <FeedbackImprove />
          <FeedbackImproveUpdate />
          <Homework />
          <HomeworkUpdate />
          <Recommendation />
          <RecommendationUpdate />
          <SessionComplete />
        </>
      )}
    </>
  );
};

export default SessionInfoPage;
