import { ReactElement } from 'react';
import Container from 'react-bootstrap/Container';
import advent from 'images/advent.png';
import footsteps from 'images/footsteps.png';
import projectSmile from 'images/partners/project-smile.png';
import enableOpportunities from 'images/partners/enable-opportunities.png';
import familyStudiesCircle from 'images/partners/family-studies-circle.jpg';
import littlePalm from 'images/partners/little-palm.png';
import ncda from 'images/partners/ncda.png';
import pin from 'images/partners/pin-landscape-transparent.png';
import playPlus from 'images/partners/play-plus.png';
import playPals from 'images/partners/play-pals.png';

export const Partners = (): ReactElement => {
  return (
    <div className="home__partners">
      <Container className="content">
        <h1 className="title">Partners</h1>
        <div className="partners">
          <a
            href="https://www.ncda.gov.ph"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={ncda} alt="National Council on Disability Affairs" />
          </a>
          <a
            href="https://www.facebook.com/ProjectInclusionPH"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={enableOpportunities} alt="Enable Opportunities" />
          </a>
          <a
            href="https://projectinclusion.ph"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={pin} alt="Project Inclusion Network" />
          </a>
        </div>
        <div className="partners">
          <a
            href="https://advent-itsolutions.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={advent} alt="Advent IT Solutions" />
          </a>
          <a
            href="https://www.facebook.com/FOOTSTEPSLEARNINGCENTER"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={footsteps} alt="Footsteps Learning Center" />
          </a>
          <a
            href="https://projectsmileph.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={projectSmile} alt="Project Smile" />
          </a>
          <a
            href="https://www.facebook.com/littlebigstepspediatrictherapy"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={playPlus} alt="Little Big Steps Pediatric Therapy" />
          </a>
        </div>
        <div className="partners">
          <a
            href="https://www.facebook.com/playplscdc"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={playPals} alt="Play Pals Child Development Center" />
          </a>
          <a
            href="https://www.facebook.com/LittlePalmOrmoc"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={littlePalm}
              alt="Little Palm Occupational Therapy Center"
            />
          </a>
          <a
            href="https://www.facebook.com/CPEMiriamCollege"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={familyStudiesCircle} alt="Family Studies Circle" />
          </a>
        </div>
      </Container>
    </div>
  );
};

export default Partners;
