import { ReactElement } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { AlertMessage } from 'components';
import { useSelector } from 'react-redux';
import {
  selectIsDeleting,
  selectTask,
  setIsDeletingTo,
  unsetTask,
} from 'redux/task';
import { useAppDispatch } from 'redux/store';
import { useDeleteTask, useSession, useSessionInfoParams } from 'hooks';

export const TaskDelete = (): ReactElement => {
  const dispatch = useAppDispatch();
  const params = useSessionInfoParams();
  const isDeleting = useSelector(selectIsDeleting);
  const task = useSelector(selectTask);
  const { data: sessionView } = useSession(
    params.session_uuid,
    params.pat_uuid,
  );
  const {
    isLoading: isSubmitting,
    isError: isSubmitError,
    mutateAsync,
  } = useDeleteTask(params.session_uuid);

  const renderModal = (): ReactElement => (
    <Modal show={isDeleting} onHide={stopDeleting}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Task</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AlertMessage visible={isSubmitError} />
        <ul>
          <li>{task?.goal}</li>
          <li>{task?.assessment}</li>
          <li>{task?.activity}</li>
          <li>{task?.recommendation}</li>
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" disabled={isSubmitting} onClick={stopDeleting}>
          Cancel
        </Button>
        <Button
          variant="danger"
          type="submit"
          disabled={isSubmitting}
          onClick={submit}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const stopDeleting = (): void => {
    if (isSubmitting) return;
    dispatch(setIsDeletingTo(false));
    dispatch(unsetTask());
  };

  const submit = async (): Promise<void> => {
    if (!sessionView || !task) {
      return;
    }
    await mutateAsync(task.id);
    stopDeleting();
  };

  return renderModal();
};

export default TaskDelete;
