import { FC, ReactElement } from 'react';
import { PageHeader } from 'components';
import {
  AccountProfile,
  AccountUpdate,
  PasswordChange,
  PhysicianInfo,
  PhysicianUpdate,
  PhysicianLicense,
  LicenseUpdate,
} from './components';

export const AccountPage: FC = (): ReactElement => (
  <>
    <PageHeader title="My Account" />
    <AccountProfile />
    <AccountUpdate />
    <PasswordChange />
    <PhysicianInfo />
    <PhysicianUpdate />
    <PhysicianLicense />
    <LicenseUpdate />
  </>
);

export default AccountPage;
