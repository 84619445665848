import { CoreService } from '.';
import { Physician } from 'redux/physician';

export { PhysicianService };

export type GetPhysicians = {
  name: string;
  specialization: string;
  consultation_date: string;
};

const w = window as any;
w.getPhysicians = null;

class PhysicianService {
  public static getPhysicians(filters: GetPhysicians): Promise<Physician[]> {
    const { name, specialization, consultation_date } = filters;
    return CoreService.get('/api/cc-service/users/physician', 'getPhysicians', {
      params0: name,
      params1: specialization,
      params2: consultation_date,
    });
  }

  public static cancelGetPhysicians(): void {
    CoreService.cancelRequest('getPhysicians');
  }
}
