import { ReactElement, ReactNode, ReactNodeArray, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import { useAppDispatch } from 'redux/store';
import {
  resetTask,
  setIsDeletingTo,
  setIsUpdatingTo,
  setTask,
  Task,
} from 'redux/task';
import { ParagraphList } from 'components';
import {
  useCurrentUser,
  useSession,
  useTasks,
  useSessionInfoParams,
} from 'hooks';

export const TaskList = (): ReactElement => {
  const dispatch = useAppDispatch();
  const params = useSessionInfoParams();
  const { isPhysicianUser } = useCurrentUser();
  const { isPaid, isCompleted } = useSession(
    params.session_uuid,
    params.pat_uuid,
  );
  const { data: tasks = [] } = useTasks(params.session_uuid);

  useEffect(() => {
    return () => {
      dispatch(resetTask());
    };
  }, [dispatch]);

  const renderMain = (): ReactElement => (
    <>
      {renderEmptyTasks()}
      {renderTasks()}
    </>
  );

  const renderEmptyTasks = (): ReactNode => {
    if (tasks.length === 0) return <Alert variant="info">No tasks yet.</Alert>;
  };

  const renderTasks = (): ReactNodeArray =>
    tasks.map(
      (t, k): ReactNode => (
        <Card key={t.id} className="mt-3">
          <Card.Body>
            <Card.Title className="card-controls">
              Task #{k + 1}
              {isPhysicianUser && isPaid && (
                <div>
                  <Button
                    variant="link"
                    className="btn-link-secondary"
                    onClick={() => deleteTask(t)}
                  >
                    Delete
                  </Button>
                  <Button
                    variant="link"
                    className="btn-link-secondary"
                    onClick={() => editTask(t)}
                  >
                    Edit
                  </Button>
                </div>
              )}
            </Card.Title>
            <Row>
              <Col md={6}>
                <div className="description-item">
                  <span>Goal</span>
                  <ParagraphList>{t.goal}</ParagraphList>
                </div>
              </Col>
              <Col md={6}>
                <div className="description-item">
                  <span>Materials</span>
                  <ParagraphList>{t.assessment}</ParagraphList>
                </div>
              </Col>
            </Row>
            {renderCompleteDetails(t)}
          </Card.Body>
        </Card>
      ),
    );

  const deleteTask = (task: Task): void => {
    dispatch(setTask(task));
    dispatch(setIsDeletingTo(true));
  };

  const editTask = (task: Task): void => {
    dispatch(setTask(task));
    dispatch(setIsUpdatingTo(true));
  };

  const renderCompleteDetails = (task: Task): ReactNode => {
    if (isPhysicianUser || isCompleted)
      return (
        <Row>
          <Col md={6}>
            <div className="description-item">
              <span>Activity/Assessment</span>
              <ParagraphList>{task.activity}</ParagraphList>
            </div>
          </Col>
          <Col md={6}>
            <div className="description-item">
              <span>Remarks</span>
              <ParagraphList>{task.recommendation}</ParagraphList>
            </div>
          </Col>
        </Row>
      );
  };

  return renderMain();
};

export default TaskList;
