import { FC, ReactElement, useEffect, useState } from 'react';
import { AlertMessage, TopbarPublic } from 'components';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import { useHistory } from 'react-router-dom';
import { CoreService } from 'api';
import { useForm } from 'react-hook-form';
import { setToken } from 'utils/auth';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ErrorMessage } from 'core/enums';
import { useLogIn } from 'hooks';

export type LoginInputs = {
  username: string;
  password: string;
};

const schema: yup.SchemaOf<LoginInputs> = yup
  .object()
  .shape({
    username: yup.string().required(ErrorMessage.REQUIRED),
    password: yup.string().required(ErrorMessage.REQUIRED),
  })
  .defined();

export const LoginPage: FC = (): ReactElement => {
  const passwordForgotUrl = '/passwords/forgot';
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState('');
  const { register, handleSubmit, unregister, errors } = useForm<LoginInputs>({
    resolver: yupResolver(schema),
  });
  const { isLoading: isSubmitting, isError, mutateAsync } = useLogIn();

  useEffect(() => {
    return () => {
      unregister(['username', 'password']);
    };
  }, [unregister]);

  const renderPage = (): ReactElement => (
    <>
      <header>
        <TopbarPublic />
        <div className="hero login">
          <Container className="content">
            <h1>Login</h1>
          </Container>
        </div>
      </header>
      <main className="hero-main">
        <Container className="hero-form">
          <Card body>
            <AlertMessage visible={isError && !errorMessage} />
            <AlertMessage visible={!!errorMessage}>{errorMessage}</AlertMessage>
            <Form onSubmit={handleSubmit(login)}>
              <Form.Group controlId="username">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your username"
                  name="username"
                  ref={register}
                  isInvalid={!!errors.username}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.username?.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter your password"
                  name="password"
                  ref={register}
                  isInvalid={!!errors.password}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password?.message}
                </Form.Control.Feedback>
              </Form.Group>
              <div className="form__actions">
                <Button variant="primary" type="submit" disabled={isSubmitting}>
                  Login
                </Button>
                <div>
                  <a href={passwordForgotUrl} onClick={handleForgotClick}>
                    Forgot Password?
                  </a>
                </div>
              </div>
            </Form>
          </Card>
        </Container>
      </main>
    </>
  );

  const login = async (inputs: LoginInputs): Promise<void> => {
    setErrorMessage('');
    try {
      const auth = await mutateAsync(inputs);
      setToken(auth.access_token);
      CoreService.setAuthorization();
      history.push('/dashboard');
    } catch (err) {
      if (CoreService.isAbort(err)) return;
      const { error, error_description } = err.response.data;
      if (error === 'invalid_grant') setErrorMessage(error_description);
    }
  };

  const handleForgotClick = (
    event: React.MouseEvent<HTMLAnchorElement>,
  ): void => {
    event.preventDefault();
    history.push(passwordForgotUrl);
  };

  return renderPage();
};

export default LoginPage;
