import { Payment, PaymentSource } from 'redux/payment';
import { CoreService } from '.';

export { PaymentService };

export type CreateSource = {
  booking_uuid: string;
  amount: number;
  appointment_id: number;
};

export type CreatePayment = {
  amount: number;
  source_id: string;
  booking_uuid: string;
};

const w = window as any;
w.getPayment = null;
w.createPayment = null;
w.createGcashSource = null;
w.createGrabPaySource = null;

class PaymentService {
  public static async getPayment(id: number): Promise<Payment> {
    const payments = await CoreService.get<Payment[]>(
      `/api/cc-service/payment/get-payment/${id}`,
      'getPayment',
    );
    return payments[0];
  }

  public static cancelGetPayment(): void {
    CoreService.cancelRequest('getPayment');
  }

  public static createPayment(payment: CreatePayment): Promise<PaymentSource> {
    return CoreService.post<PaymentSource, any>(
      '/api/cc-service/payment/create-payment',
      {
        params0: payment.amount,
        params1: payment.source_id,
        params2: payment.booking_uuid,
      },
      'createPayment',
    );
  }

  public static cancelCreatePayment(): void {
    CoreService.cancelRequest('createPayment');
  }

  public static createGcashSource(
    payment: CreateSource,
  ): Promise<PaymentSource> {
    return CoreService.post<PaymentSource, any>(
      '/api/cc-service/payment/create-gcash',
      {
        params0: '',
        params1: payment.booking_uuid,
        params2: payment.amount * 100,
        params3: payment.appointment_id,
      },
      'createGcashSource',
    );
  }

  public static cancelCreateGcashSource(): void {
    CoreService.cancelRequest('createGcashSource');
  }

  public static createGrabPaySource(
    payment: CreateSource,
  ): Promise<PaymentSource> {
    return CoreService.post<PaymentSource, any>(
      '/api/cc-service/payment/create-grab',
      {
        params0: '',
        params1: payment.booking_uuid,
        params2: payment.amount * 100,
        params3: payment.appointment_id,
      },
      'createGrabPaySource',
    );
  }

  public static cancelCreateGrabPaySource(): void {
    CoreService.cancelRequest('createGrabPaySource');
  }
}
