import { FC, ReactElement } from 'react';
import {
  Header,
  EmailVerification,
  PersonalInfo,
  PhysicianInfo,
  PhysicianLicense,
} from './components';

export const UserSetupPage: FC = (): ReactElement => (
  <section className="user-setup">
    <Header />
    <EmailVerification />
    <PersonalInfo />
    <PhysicianInfo />
    <PhysicianLicense />
  </section>
);

export default UserSetupPage;
