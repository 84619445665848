import { CoreService } from '.';
import { FullSpecialization } from 'redux/specialization';
import { Specialization } from 'core/enums';
import { getSpecializationLabel } from 'utils/specialization';

export { SpecializationService };

export type SpecializationCreate = {
  therapy: Specialization;
  specialization: Specialization;
  other_specialization: string;
  level: string;
  accredited_by: string;
  completed_on: string;
};

const w = window as any;
w.getSpecializations = null;
w.createSpecialization = null;
w.deleteSpecialization = null;

class SpecializationService {
  public static async getSpecializations(
    physician_uuid: string,
  ): Promise<FullSpecialization[]> {
    return await CoreService.get(
      `/api/cc-service/physician/get-specialization/${physician_uuid}`,
      'getSpecializations',
    );
  }

  public static cancelGetSpecializations(): void {
    CoreService.cancelRequest('getSpecializations');
  }

  public static createSpecialization(
    specialization: SpecializationCreate,
  ): Promise<void> {
    return CoreService.put(
      '/api/cc-service/physician/insert-specialization',
      {
        params0: `${getSpecializationLabel(
          specialization.therapy,
        )} - ${getSpecializationLabel(specialization.specialization)}`,
        params1: specialization.other_specialization || '',
        params2: specialization.level,
        params3: specialization.accredited_by,
        params4: specialization.completed_on,
      },
      'createSpecialization',
    );
  }

  public static cancelCreateSpecialization(): void {
    CoreService.cancelRequest('createSpecialization');
  }

  public static deleteSpecialization(specialization_id: number): Promise<void> {
    return CoreService.delete(
      `/api/cc-service/physician/delete-specialization/${specialization_id}`,
      'deleteSpecialization',
    );
  }

  public static cancelDeleteSpecialization(): void {
    CoreService.cancelRequest('deleteSpecialization');
  }
}
