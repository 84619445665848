import { Gender } from 'core/enums';

export const BOY =
  'https://www.flaticon.com/svg/static/icons/svg/145/145867.svg';
export const GIRL =
  'https://www.flaticon.com/svg/static/icons/svg/145/145862.svg';

export const ANONYMOUS_USER =
  'https://www.flaticon.com/svg/static/icons/svg/892/892749.svg';

export const getPatientImage = (gender: Gender): string => {
  switch (gender) {
    case Gender.MALE:
      return BOY;
    case Gender.FEMALE:
      return GIRL;
    default:
      return ANONYMOUS_USER;
  }
};

export const MAN =
  'https://www.flaticon.com/svg/static/icons/svg/892/892781.svg';

export const WOMAN =
  'https://www.flaticon.com/svg/static/icons/svg/892/892770.svg';

export const getAccountImage = (gender: Gender): string => {
  switch (gender) {
    case Gender.MALE:
      return MAN;
    case Gender.FEMALE:
      return WOMAN;
    default:
      return ANONYMOUS_USER;
  }
};
