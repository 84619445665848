import { FC, ReactElement } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logoDark from 'images/therawee-logo-dark-compressed.png';
import { removeToken } from 'utils/auth';
import { useCurrentUser } from 'hooks';
import { useQueryClient } from 'react-query';

export const TopbarSetup: FC = (): ReactElement => {
  const homeUrl = '/';
  const loginUrl = '/login';
  const history = useHistory();
  const { data: user } = useCurrentUser();
  const queryClient = useQueryClient();

  const renderTopbar = (): ReactElement => (
    <nav className="topbar topbar-setup">
      <Container className="content">
        <NavLink to={homeUrl}>
          <img src={logoDark} alt="TheraWee Logo" className="logo" />
        </NavLink>
        <Nav>
          <NavDropdown title={user?.username} id="user-dropdown">
            <NavDropdown.Item href="#" onClick={logOut}>
              Log Out
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Container>
    </nav>
  );

  const logOut = (): void => {
    removeToken();
    queryClient.clear();
    redirectToLoginPage();
  };

  const redirectToLoginPage = (): void => history.push(loginUrl);

  return renderTopbar();
};

export default TopbarSetup;
