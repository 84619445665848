import { FC, ReactElement } from 'react';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useHistory, useParams } from 'react-router-dom';
import { usePayment } from 'hooks';

export const PaymentFailedPage: FC = (): ReactElement => {
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const { data: payment } = usePayment(+params.id);

  const renderMain = (): ReactElement => (
    <>
      <Alert variant="danger" className="mt-3">
        Payment Failed!
      </Alert>
      <Row>
        <Col>
          <Button variant="primary" onClick={redirectToPatientPage}>
            View Session
          </Button>
        </Col>
      </Row>
    </>
  );

  const redirectToPatientPage = (): void => {
    history.push(
      `/patients/${payment?.patient_uuid}/sessions/${payment?.booking}`,
    );
  };

  return renderMain();
};

export default PaymentFailedPage;
