import { FC, ReactElement } from 'react';

export interface ProgramProps {
  number: number;
  title: string;
  description: string;
  imgSrc: string;
}

export const Program: FC<ProgramProps> = (props): ReactElement => {
  const { number, title, description, imgSrc } = props;

  return (
    <div className="program">
      <div className="number">{number}</div>
      <img src={imgSrc} alt={title} />
      <h5 className="title">{title}</h5>
      <p>{description}</p>
    </div>
  );
};

export default Program;
