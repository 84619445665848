import { RootState } from 'redux/store';
import { BookingState, Session } from '.';
import { createSelector } from '@reduxjs/toolkit';
import { RecurOption } from 'core/enums';

export {
  selectBookingState,
  selectPhysicianName,
  selectConsultationDate,
  selectRecurOption,
  selectHasRecurOption,
  selectSessions,
  selectNewSession,
  selectIsAddMore,
  selectSuccessPatientUuid,
};

const selectBookingState = (state: RootState): BookingState => state.booking;

const selectPhysicianName = createSelector(
  selectBookingState,
  (booking): string => booking.physicianName,
);

const selectConsultationDate = createSelector(
  selectBookingState,
  (booking): string => booking.consultationDate,
);

const selectRecurOption = createSelector(
  selectBookingState,
  (booking): RecurOption => booking.recur_option,
);

const selectHasRecurOption = createSelector(
  selectRecurOption,
  (recurOption): boolean => recurOption !== RecurOption.NONE,
);

const selectSessions = createSelector(
  selectBookingState,
  (booking): Session[] => booking.sessions,
);

const selectNewSession = createSelector(selectBookingState, (booking):
  | Session
  | undefined => booking.sessions.find((s): boolean => !s.isComplete));

const selectIsAddMore = createSelector(
  selectSessions,
  (sessions): boolean => !sessions.some((s): boolean => !s.isComplete),
);

const selectSuccessPatientUuid = createSelector(
  selectBookingState,
  (booking): string | undefined => booking.success_patient_uuid,
);
