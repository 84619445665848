import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { SessionState } from '.';
import { PatientsSession } from './session.types';

const initialState: SessionState = {
  isViewing: false,
  patientsSession: undefined,
  current_date: moment().format(),
  isUpdatingFeedbackLike: false,
  isUpdatingFeedbackImprove: false,
  isUpdatingHomework: false,
  isUpdatingRecommendation: false,
  isRescheduling: false,
  isCompleting: false,
  isCancelling: false,
  isVoucherPaying: false,
  isGcashPaying: false,
};

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setIsViewingTo: (state, action: PayloadAction<boolean>): void => {
      state.isViewing = action.payload;
    },
    setPatientsSession: (
      state,
      action: PayloadAction<PatientsSession>,
    ): void => {
      state.patientsSession = action.payload;
    },
    previousWeek: (state, _action: PayloadAction): void => {
      state.patientsSession = undefined;
      state.current_date = moment(state.current_date).add(-7, 'days').format();
    },
    nextWeek: (state, _action: PayloadAction): void => {
      state.patientsSession = undefined;
      state.current_date = moment(state.current_date).add(7, 'days').format();
    },
    setIsUpdatingFeedbackLikeTo: (
      state,
      action: PayloadAction<boolean>,
    ): void => {
      state.isUpdatingFeedbackLike = action.payload;
    },
    setIsUpdatingFeedbackImproveTo: (
      state,
      action: PayloadAction<boolean>,
    ): void => {
      state.isUpdatingFeedbackImprove = action.payload;
    },
    setIsUpdatingHomeworkTo: (state, action: PayloadAction<boolean>): void => {
      state.isUpdatingHomework = action.payload;
    },
    setIsUpdatingRecommendationTo: (
      state,
      action: PayloadAction<boolean>,
    ): void => {
      state.isUpdatingRecommendation = action.payload;
    },
    setIsReschedulingTo: (state, action: PayloadAction<boolean>): void => {
      state.isRescheduling = action.payload;
    },
    setIsCompletingTo: (state, action: PayloadAction<boolean>): void => {
      state.isCompleting = action.payload;
    },
    setIsCancellingTo: (state, action: PayloadAction<boolean>): void => {
      state.isCancelling = action.payload;
    },
    setIsVoucherPayingTo: (state, action: PayloadAction<boolean>): void => {
      state.isVoucherPaying = action.payload;
    },
    setIsGcashPayingTo: (state, action: PayloadAction<boolean>): void => {
      state.isGcashPaying = action.payload;
    },
    resetSession: (_state, _action: PayloadAction): SessionState =>
      initialState,
  },
});

export const {
  setIsViewingTo,
  setPatientsSession,
  previousWeek,
  nextWeek,
  setIsUpdatingFeedbackLikeTo,
  setIsUpdatingFeedbackImproveTo,
  setIsUpdatingHomeworkTo,
  setIsUpdatingRecommendationTo,
  setIsReschedulingTo,
  setIsCompletingTo,
  setIsCancellingTo,
  setIsVoucherPayingTo,
  setIsGcashPayingTo,
  resetSession,
} = sessionSlice.actions;
