import { FC, ReactElement, ReactNode } from 'react';
import Card, { CardProps } from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Session } from 'redux/booking';
import { SessionHeader } from '..';
import moment from 'moment';
import { getSpecializationLabel } from 'utils/specialization';
import { getRecurOptionLabel } from 'utils/recurring';
import { toFullName } from 'utils/user';

export interface SessionSectionProps extends CardProps {
  session: Session;
}

export const SessionSection: FC<SessionSectionProps> = (
  props,
): ReactElement => {
  const { session, ...rest } = props;

  const renderSection = (): ReactElement => (
    <Card className="mt-3" {...rest}>
      <Card.Body>
        <SessionHeader session={session} />
        <Row>
          <Col md={4}>
            <div className="description-item">
              <span>Therapist</span>
              {!!session.physician ? toFullName(session.physician) : ''}
            </div>
          </Col>
          <Col md={4}>
            <div className="description-item">
              <span>Service</span>
              {getSpecializationLabel(session.topSpecialization)}
            </div>
          </Col>
          <Col md={4}>
            <div className="description-item">
              <span>Specialization</span>
              {renderSpecializationLabel()}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <div className="description-item">
              <span>Repeat</span>
              {getRecurOptionLabel(session.recur_option)}
            </div>
          </Col>
          <Col md={4}>
            <div className="description-item">
              <span>Frequency</span>
              {session.repetition}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="description-item">
              <span>Date &amp; Time</span>
              {session.consultation_dates.map(
                (cd, key): ReactNode => (
                  <p key={key} className="mb-0">
                    {moment(cd, 'YYYY-MM-DD HH:mm:00').format(
                      'ddd. MMM. D, YYYY h:mm A',
                    )}
                  </p>
                ),
              )}
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );

  const renderSpecializationLabel = (): ReactNode => {
    if (session.specializations)
      return session.specializations
        .map((s) => getSpecializationLabel(s))
        .join(',');
  };

  return renderSection();
};

export default SessionSection;
