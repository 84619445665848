import { FC, ReactElement } from 'react';
import { ReturnHomeButton } from 'components';

export const NotFoundPage: FC = (): ReactElement => (
  <>
    <h2>Page Not Found :(</h2>
    <ReturnHomeButton />
  </>
);

export default NotFoundPage;
