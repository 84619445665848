import { FC, ReactElement } from 'react';
import { ReturnHomeButton } from 'components';

export const UnauthenticatedPage: FC = (): ReactElement => (
  <>
    <h2>You are not logged in.</h2>
    <ReturnHomeButton />
  </>
);

export default UnauthenticatedPage;
