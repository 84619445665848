import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'redux/store';
import { PatientState } from '.';

const initialState: PatientState = {
  isCreating: false,
  isUpdating: false,
};

export const patientSlice = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    setIsCreatingTo: (state, action: PayloadAction<boolean>): void => {
      state.isCreating = action.payload;
    },
    setIsUpdatingTo: (state, action: PayloadAction<boolean>): void => {
      state.isUpdating = action.payload;
    },
    reset: (_state, _action: PayloadAction): PatientState => ({
      ...initialState,
    }),
  },
});
const { reset } = patientSlice.actions;
export const { setIsCreatingTo, setIsUpdatingTo } = patientSlice.actions;

export const resetPatient = (): AppThunk => async (
  dispatch,
  _getState,
): Promise<void> => {
  dispatch(reset());
};
