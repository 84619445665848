import { RootState } from 'redux/store';
import { PatientState } from '.';
import { createSelector } from '@reduxjs/toolkit';

export { selectPatientState, selectIsCreating, selectIsUpdating };

const selectPatientState = (state: RootState): PatientState => state.patient;

const selectIsCreating = createSelector(
  selectPatientState,
  (patient): boolean => patient.isCreating,
);

const selectIsUpdating = createSelector(
  selectPatientState,
  (patient): boolean => patient.isUpdating,
);
