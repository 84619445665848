import { CoreService } from '.';
import { Task } from 'redux/task';

export { TaskService };

export type TaskCreate = {
  booking_uuid: string;
  goal: string;
  activity: string;
  assessment?: string;
  recommendation?: string;
};

export type TaskUpdate = {
  task_id: number;
  goal: string;
  activity: string;
  assessment?: string;
  recommendation?: string;
};

const w = window as any;
w.getTasks = null;
w.createTask = null;
w.updateTask = null;
w.deleteTask = null;

class TaskService {
  public static getTasks(booking_uuid: string): Promise<Task[]> {
    return CoreService.get(
      `/api/cc-service/bookings/bookingtasks/${booking_uuid}`,
      'getTasks',
    );
  }

  public static cancelGetTasks(): void {
    CoreService.cancelRequest('getTasks');
  }

  public static createTask(task: TaskCreate): Promise<void> {
    return CoreService.put(
      '/api/cc-service/bookings/insert-bookingtasks',
      {
        params0: task.booking_uuid,
        params1: task.goal,
        params2: task.activity,
        params3: task.assessment,
        params4: task.recommendation,
      },
      'createTask',
    );
  }

  public static cancelCreateTask(): void {
    CoreService.cancelRequest('createTask');
  }

  public static updateTask(task: TaskUpdate): Promise<void> {
    return CoreService.post(
      '/api/cc-service/bookings/update-bookingtasks',
      {
        params0: task.task_id,
        params1: task.goal,
        params2: task.activity,
        params3: task.assessment,
        params4: task.recommendation,
      },
      'updateTask',
    );
  }

  public static cancelUpdateTask(): void {
    CoreService.cancelRequest('updateTask');
  }

  public static deleteTask(task_id: number): Promise<void> {
    return CoreService.delete(
      `/api/cc-service/bookings/delete-bookingtasks/${task_id}`,
      'deleteTask',
    );
  }

  public static cancelDeleteTask(): void {
    CoreService.cancelRequest('deleteTask');
  }
}
