import { ReactElement } from 'react';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';

export const LearnMore = (): ReactElement => {
  const history = useHistory();

  const renderMain = (): ReactElement => (
    <div className="home__learn-more">
      <Button variant="primary" size="lg" onClick={redirectToContactPage}>
        Learn More
      </Button>
    </div>
  );

  const redirectToContactPage = (): void => {
    history.push('/contact');
  };

  return renderMain();
};

export default LearnMore;
