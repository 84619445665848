import { FC, ReactElement, useEffect } from 'react';
import { PageHeader, AlertMessage } from 'components';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { useCreatePayment, usePayment } from 'hooks';

export type PaymentSuccessForm = {
  amount: string;
};

export const PaymentSuccessPage: FC = (): ReactElement => {
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const { data: payment } = usePayment(+params.id);
  const { isLoading: isSubmitting, isError, mutateAsync } = useCreatePayment();
  const {
    handleSubmit,
    register,
    unregister,
    setValue,
  } = useForm<PaymentSuccessForm>();

  useEffect(() => {
    return () => {
      unregister(['amount']);
    };
  }, [unregister]);

  useEffect(() => {
    if (!!payment) setValue('amount', payment.amount / 100);
  }, [payment, setValue]);

  const renderMain = (): ReactElement => (
    <>
      <PageHeader title="Payment Confirmation" />
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <Card body>
            <AlertMessage visible={isError} />
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group controlId="amount">
                <Form.Label>Amount</Form.Label>
                <Form.Control readOnly name="amount" ref={register} />
              </Form.Group>
              <Button
                variant="secondary"
                disabled={isSubmitting}
                onClick={redirectToSessionInfo}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                className="ml-2"
                disabled={isSubmitting}
              >
                Confirm Payment
              </Button>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );

  const onSubmit = async (_data: PaymentSuccessForm): Promise<void> => {
    if (!payment) {
      return;
    }
    await mutateAsync({
      booking_uuid: payment.booking,
      amount: payment.amount,
      source_id: payment.source_id,
    });
    redirectToSessionInfo();
  };

  const redirectToSessionInfo = (): void => {
    history.push(
      `/patients/${payment?.patient_uuid}/sessions/${payment?.booking}`,
    );
  };

  return renderMain();
};

export default PaymentSuccessPage;
