import React, { ReactElement, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { AlertMessage } from 'components';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { setIsVoucherPayingTo, selectIsVoucherPaying } from 'redux/session';
import { useAppDispatch } from 'redux/store';
import { useSession, usePayVoucher, useSessionInfoParams } from 'hooks';

export type VoucherForm = {
  voucher: string;
};

export const VoucherPay = (): ReactElement => {
  const dispatch = useAppDispatch();
  const {
    register,
    unregister,
    handleSubmit,
    reset: resetForm,
  } = useForm<VoucherForm>();
  const isUpdatingVoucher = useSelector(selectIsVoucherPaying);
  const params = useSessionInfoParams();
  const { data: sessionView } = useSession(
    params.session_uuid,
    params.pat_uuid,
  );
  const {
    isLoading: isPaying,
    isError: isPaymentError,
    mutateAsync,
    reset: resetPayVoucher,
  } = usePayVoucher(params.session_uuid);

  useEffect(() => {
    return () => {
      unregister(['voucher']);
    };
  }, [unregister]);

  const renderModal = (): ReactElement => (
    <Modal show={isUpdatingVoucher} onHide={stopUpdating}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>Use Voucher</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertMessage visible={isPaymentError}>
            {'Invalid voucher.'}
          </AlertMessage>
          <Form.Group controlId="voucher">
            <Form.Label>Voucher</Form.Label>
            <Form.Control
              placeholder="Enter voucher"
              name="voucher"
              ref={register}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" disabled={isPaying} onClick={stopUpdating}>
            Cancel
          </Button>
          <Button variant="primary" type="submit" disabled={isPaying}>
            Submit
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );

  const stopUpdating = (): void => {
    if (isPaying) return;
    dispatch(setIsVoucherPayingTo(false));
    resetPayVoucher();
    resetForm();
  };

  const onSubmit = async (data: VoucherForm): Promise<void> => {
    if (!sessionView) {
      return;
    }
    await mutateAsync({
      booking_uuid: sessionView.uuid,
      code: data.voucher,
    });
    stopUpdating();
  };

  return renderModal();
};

export default VoucherPay;
