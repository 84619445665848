import { ReactElement } from 'react';
import Container from 'react-bootstrap/Container';
import girlAbout from 'images/girl-about.jpg';
import kidsAbout from 'images/kids-about.jpg';

export const About = (): ReactElement => {
  return (
    <div id="about" className="home__about">
      <Container className="content">
        <h1 className="title">What is TheraWee</h1>
        <div className="left">
          <div className="top">
            <img src={girlAbout} alt="Girl" />
          </div>
          <div className="bottom">
            <img src={kidsAbout} alt="Kids" />
          </div>
        </div>
        <div className="right">
          <h1 className="title">What is TheraWee</h1>
          <p>
            TheraWee is a community teletherapy platform that helps children
            with disabilities find, connect and receive quality therapy services
            remotely.
          </p>
          <p>
            We empower parents to be more proactive to learn about child
            development and basic red flags for developmental disorders (such as
            Autism, ADHD, global developmental delay, learning disability,
            cerebral palsy, down syndrome and etc.) which will enable them to
            seek medical services for early intervention.
          </p>
        </div>
      </Container>
    </div>
  );
};

export default About;
