import { CoreService } from '.';
import { Gender, OrderDir, RecurOption } from 'core/enums';
import { BookedSession } from 'redux/session';

export { SessionService };

export type GetPatientSessions = {
  uuid: string;
  date_from?: string;
  date_to?: string;
  order_dir?: OrderDir;
};

export type SessionCreate = {
  patient_uuid: string;
  health_query: string;
  specialization: string;
  physician_uuid: string;
  consultation_dates: string[];
  recur_option: RecurOption;
  repetition?: number;
};

export type SessionCreateBody = {
  patient_uuid: string;
  health_query: string;
  specialization: string;
  physician_uuid: string;
  consultation_date: string;
};

export type SessionUpdate = {
  booking_uuid: string;
  health_query: string;
  specialization: string;
  physician_uuid: string;
  consultation_date?: string;
  feedback_like: string;
  feedback_improve: string;
  therapy_homework: string;
  therapy_recommendation: string;
};

export type SessionMessage = {
  name: string;
  contact_details: string;
  subject: string;
  message: string;
};

export type VoucherPaySession = {
  booking_uuid: string;
  code: string;
};

const w = window as any;
w.getPatientSessions = null;
w.createSessions = null;
w.updateSession = null;
w.cancelSession = null;
w.completeSession = null;
w.messageSession = null;
w.voucherPaySession = null;

class SessionService {
  public static getPatientSessions(
    params: GetPatientSessions,
  ): Promise<BookedSession[]> {
    const { uuid, date_from, date_to, order_dir } = params;
    return CoreService.get(
      '/api/cc-service/bookings/patient-bookings',
      'getPatientSessions',
      {
        params0: uuid,
        params1: date_from,
        params2: date_to,
        params3: order_dir,
      },
    );
  }

  public static cancelGetPatientSessions(): void {
    CoreService.cancelRequest('getPatientSessions');
  }

  public static createSessions(sessions: SessionCreate[]): Promise<void> {
    const ss: SessionCreateBody[] = [];
    sessions.forEach((s) => {
      s.consultation_dates.forEach((cd) => {
        ss.push({
          patient_uuid: s.patient_uuid,
          health_query: s.health_query,
          specialization: s.specialization,
          physician_uuid: s.physician_uuid,
          consultation_date: cd,
        });
      });
    });
    const data = ss.map((s): any => ({
      params0: '',
      params1: 0,
      params2: Gender.MALE,
      params3: s.health_query,
      params4: s.specialization,
      params5: s.physician_uuid,
      params6: '',
      params7: s.patient_uuid,
      params8: s.consultation_date,
    }));
    return CoreService.put(
      '/api/cc-service/bookings/insert',
      data,
      'createSessions',
    );
  }

  public static cancelCreateSession(): void {
    CoreService.cancelRequest('createSessions');
  }

  public static updateSession(session: SessionUpdate): Promise<void> {
    return CoreService.post(
      '/api/cc-service/bookings/update',
      {
        params0: '',
        params1: 0,
        params2: Gender.MALE,
        params3: session.health_query,
        params4: session.specialization,
        params5: '',
        params6: session.physician_uuid,
        params7: session.feedback_like,
        params8: session.feedback_improve,
        params9: session.therapy_homework,
        params10: session.therapy_recommendation,
        params11: session.booking_uuid,
        params12: session.consultation_date,
      },
      'updateSession',
    );
  }

  public static cancelUpdateSession(): void {
    CoreService.cancelRequest('updateSession');
  }

  public static cancelSession(booking_uuid: string): Promise<void> {
    return CoreService.post(
      `/api/cc-service/bookings/cancel/${booking_uuid}`,
      null,
      'cancelSession',
    );
  }

  public static cancelCancelSession(): void {
    CoreService.cancelRequest('cancelSession');
  }

  public static completeSession(booking_uuid: string): Promise<void> {
    return CoreService.post(
      `/api/cc-service/bookings/complete/${booking_uuid}`,
      null,
      'completeSession',
    );
  }

  public static cancelCompleteSession(): void {
    CoreService.cancelRequest('completeSession');
  }

  public static messageSession(data: SessionMessage): Promise<void> {
    return CoreService.put(
      '/api/cc-service/bookings/messages',
      {
        params0: data.name,
        params1: data.contact_details,
        params2: data.subject,
        params3: data.message,
      },
      'messageSession',
    );
  }

  public static cancelMessageSession(): void {
    CoreService.cancelRequest('messageSession');
  }

  public static voucherPaySession(data: VoucherPaySession): Promise<void> {
    return CoreService.post(
      '/api/cc-service/bookings/voucher',
      {
        params0: data.booking_uuid,
        params1: data.code,
      },
      'voucherPaySession',
    );
  }

  public static cancelCoucherPaySession(): void {
    CoreService.cancelRequest('voucherPaySession');
  }
}
