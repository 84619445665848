import {
  SessionCreate,
  SessionMessage,
  SessionService,
  SessionUpdate,
  VoucherPaySession,
} from 'api';
import { AxiosError } from 'axios';
import { BookStatus } from 'core/enums';
import moment from 'moment';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { Session } from 'redux/booking';
import { BookedSession, MergedSession, PatientsSession } from 'redux/session';
import { getSpecializationLabel } from 'utils/specialization';
import { UseSessionResult, UseSessions, UseSessionsResult } from '.';

export const useSessions = (
  data: UseSessions,
): UseSessionsResult & UseQueryResult<BookedSession[]> => {
  const result = useQuery(
    [
      'sessions',
      data.user_uuid,
      {
        date_from: data.date_from,
        date_to: data.date_to,
        order_dir: data.order_dir,
      },
    ],
    () =>
      SessionService.getPatientSessions({
        uuid: data.user_uuid,
        date_from: data.date_from,
        date_to: data.date_to,
        order_dir: data.order_dir,
      }),
  );
  const mss: MergedSession[] = [];
  if (!!result.data) {
    const is: number[] = [];
    const m = moment(data.date_from);
    for (let i = 0; i < 7; i++) {
      is.push(m.day());
      m.add(1, 'day');
    }
    result.data.forEach((s): void => {
      const m = moment(s.consultation_date);
      const t = parseInt(m.format('Hmm'));
      const d = m.day();
      const ms = mss.find((ems): boolean => ems.time === t);
      if (ms) {
        const sp = ms.sessions.find((ps) => ps.day === d);
        if (sp) {
          sp.consultation_date = s.consultation_date;
          sp.patients.push(s);
        }
      } else
        mss.push({
          key: mss.length,
          time: t,
          sessions: is.map((di): PatientsSession => {
            const ps: BookedSession[] = [];
            if (d === di) ps.push(s);
            return {
              cellId: `${di}${mss.length}`,
              day: di,
              consultation_date: s.consultation_date,
              patients: ps,
            };
          }),
        });
    });
    mss.sort((a, b) => a.time - b.time);
  }
  return {
    mergedSessions: mss,
    ...result,
  };
};

export const useSession = (
  session_uuid: string,
  pat_uuid: string,
): UseSessionResult & UseQueryResult<BookedSession> => {
  const queryClient = useQueryClient();
  const now = moment();
  const result = useQuery(
    ['session', session_uuid],
    async () => {
      const sessions = await SessionService.getPatientSessions({
        uuid: session_uuid,
      });
      if (!sessions.length) throw new Error('Session not found.');
      return sessions[0];
    },
    {
      placeholderData: () => {
        const foundSession = queryClient
          .getQueryData<BookedSession[]>(['sessions', pat_uuid])
          ?.find((session) => session.uuid === session_uuid);
        return foundSession;
      },
    },
  );
  return {
    isSubmitted: result.data?.status === BookStatus.SUBMITTED,
    isPaid: result.data?.status === BookStatus.PAID,
    isCompleted: result.data?.status === BookStatus.COMPLETED,
    isCancelled: result.data?.status === BookStatus.CANCELLED,
    canReschedule: !result.data
      ? false
      : moment(result.data.consultation_date).add(-1, 'day').isAfter(now),
    homeworks: !result.data?.therapy_homework
      ? []
      : result.data.therapy_homework.split('\n'),
    recommendations: !result.data?.therapy_recommendation
      ? []
      : result.data.therapy_recommendation.split('\n'),
    ...result,
  };
};

export const useCreateSession = (): UseMutationResult<
  void,
  AxiosError,
  { patient_uuid: string; health_query: string; sessions: Session[] }
> => {
  return useMutation((params) => {
    const ss = params.sessions.map((s): SessionCreate => {
      const {
        topSpecialization,
        specializations,
        physician,
        recur_option,
        repetition,
      } = s;
      const sl = specializations
        .map(
          (s) =>
            `${getSpecializationLabel(
              topSpecialization,
            )} - ${getSpecializationLabel(s)}`,
        )
        .join(',');
      const p = physician ? physician.uuid : '';
      return {
        patient_uuid: params.patient_uuid,
        health_query: params.health_query,
        specialization: sl,
        physician_uuid: p,
        consultation_dates: s.consultation_dates,
        recur_option,
        repetition: !!repetition ? +repetition : undefined,
      };
    });
    return SessionService.createSessions(ss);
  });
};

export const useUpdateSession = (
  session_uuid: string,
): UseMutationResult<void, AxiosError, SessionUpdate> => {
  const queryClient = useQueryClient();
  return useMutation(SessionService.updateSession, {
    onSuccess: (_data, variables) => {
      const session = queryClient.getQueryData<BookedSession>([
        'session',
        session_uuid,
      ]);
      queryClient.setQueryData(['session', session_uuid], {
        ...session,
        health_query: variables.health_query,
        specialization: variables.specialization,
        physician_uuid: variables.physician_uuid,
        feedback_like: variables.feedback_like,
        feedback_improve: variables.feedback_improve,
        therapy_homework: variables.therapy_homework,
        therapy_recommendation: variables.therapy_recommendation,
      });
      queryClient.invalidateQueries(['session', session_uuid]);
    },
  });
};

export const usePayVoucher = (
  session_uuid: string,
): UseMutationResult<void, AxiosError, VoucherPaySession> => {
  const queryClient = useQueryClient();
  return useMutation(SessionService.voucherPaySession, {
    onSuccess: () => {
      queryClient.invalidateQueries(['session', session_uuid]);
    },
  });
};

export const useCompleteSession = (
  session_uuid: string,
): UseMutationResult<void, AxiosError, string> => {
  const queryClient = useQueryClient();
  return useMutation(SessionService.completeSession, {
    onSuccess: () => {
      queryClient.invalidateQueries(['session', session_uuid]);
    },
  });
};

export const useCancelSession = (
  session_uuid: string,
): UseMutationResult<void, AxiosError, string> => {
  const queryClient = useQueryClient();
  return useMutation(SessionService.cancelSession, {
    onSuccess: () => {
      queryClient.invalidateQueries(['session', session_uuid]);
    },
  });
};

export const useMessageSession = (): UseMutationResult<
  void,
  AxiosError,
  SessionMessage
> => {
  return useMutation(SessionService.messageSession);
};
