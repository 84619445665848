import { useCurrentUser, usePatients } from 'hooks';
import { FC, ReactElement, ReactNode } from 'react';
import Alert from 'react-bootstrap/Alert';
import { setIsCreatingTo } from 'redux/patient';
import { useAppDispatch } from 'redux/store';

export const PatientWarning: FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const { data: user } = useCurrentUser();
  const { isLoading, data: patients = [] } = usePatients(user?.uuid);

  const renderMain = (): ReactElement => (
    <Alert variant="warning">
      You don't have a patient! Click {renderStartButton()} to add.
    </Alert>
  );

  const renderStartButton = (): ReactNode => (
    <a href="/" onClick={startCreating}>
      here
    </a>
  );

  const startCreating = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ): void => {
    event.preventDefault();
    dispatch(setIsCreatingTo(true));
  };

  if (isLoading || !!patients.length) return <></>;

  return renderMain();
};

export default PatientWarning;
