import { SpecializationCreate, SpecializationService } from 'api';
import { AxiosError } from 'axios';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { FullSpecialization } from 'redux/specialization';
import { UseFullSpecializations } from '.';

export const useFullSpecializations = (
  data: UseFullSpecializations,
): UseQueryResult<FullSpecialization[]> => {
  return useQuery(
    ['specializations', data.phy_uuid],
    () => SpecializationService.getSpecializations(data.phy_uuid || ''),
    {
      enabled: data.isEnabled,
    },
  );
};

export const useCreateSpecialization = (
  phy_uuid: string,
): UseMutationResult<void, AxiosError, SpecializationCreate> => {
  const queryClient = useQueryClient();
  return useMutation(SpecializationService.createSpecialization, {
    onSuccess: () => {
      queryClient.invalidateQueries(['specializations', phy_uuid]);
    },
  });
};

export const useDeleteSpecialization = (
  phy_uuid: string,
): UseMutationResult<void, AxiosError, number> => {
  const queryClient = useQueryClient();
  return useMutation(SpecializationService.deleteSpecialization, {
    onSuccess: () => {
      queryClient.invalidateQueries(['specializations', phy_uuid]);
    },
  });
};
