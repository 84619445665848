import { FC, ReactElement } from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { UnauthenticatedPage } from 'pages';
import { TopbarSetup } from '..';
import Container from 'react-bootstrap/Container';
import { useCurrentUser } from 'hooks';

export const UserSetupRoute: FC<RouteProps> = (props): ReactElement => {
  const {
    isLoading,
    isAuthenticated,
    isRegistrationStepsCompleted,
  } = useCurrentUser();

  const renderRoute = (): ReactElement => {
    if (isLoading) return <>Loading... Please Wait</>;
    if (!isAuthenticated) return renderUnauthenticatedPage();
    if (isRegistrationStepsCompleted) return redirectToDashboardPage();
    return renderPage();
  };

  const renderUnauthenticatedPage = (): ReactElement => <UnauthenticatedPage />;

  const redirectToDashboardPage = (): ReactElement => (
    <Redirect to="/dashboard" />
  );

  const renderPage = (): ReactElement => (
    <>
      <TopbarSetup />
      <main className="private-main">
        <Container>
          <Route {...props} />
        </Container>
      </main>
    </>
  );

  return renderRoute();
};

export default UserSetupRoute;
