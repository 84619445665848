import { FC, ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import Button, { ButtonProps } from 'react-bootstrap/Button';

export interface ReturnHomeButtonProps extends ButtonProps {}

export const ReturnHomeButton: FC<ReturnHomeButtonProps> = (
  props,
): ReactElement => {
  const { onClick, children = 'Go back home', ...rest } = props;
  const history = useHistory();

  const renderButton = (): ReactElement => (
    <Button onClick={redirectToHomePage} children={children} {...rest} />
  );

  const redirectToHomePage = (): void => history.push('/');

  return renderButton();
};

export default ReturnHomeButton;
