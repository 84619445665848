import { FC, ReactElement } from 'react';
import { TopbarPublic } from 'components';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';

export const PolicyPage: FC = (): ReactElement => (
  <>
    <TopbarPublic variant="dark" />
    <Container>
      <Card body className="policy-card">
        <h4>
          ADVENT IT SOLUTIONS INC. TERMS AND CONDITIONS AND PRIVACY POLICY
        </h4>
        <p>For Therawee.ph</p>
        <h5>Statement of Policy</h5>
        <p>
          Advent IT Solutions Inc. (“we”, “our”, “us”) respect and value your
          rights to data privacy, and ensure that personal data collected on{' '}
          <a
            href="https://therawee.ph"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.therawee.ph
          </a>{' '}
          (the “Site”) and the services, features, content or applications we
          offer (collectively with the Site, the “Services”) are processed in
          adherence to the general principles of transparency, legitimate
          purpose, and proportionality. This policy describes the information we
          collect, how it is used, and your choices regarding this information.
          By proceeding, you are accepting the practices described in this
          Privacy Policy.
        </p>
        <h5>Information We Collect</h5>
        <p>
          The personal information collected by Advent IT Solutions Inc. will be
          used for the purpose of processing your inquiries, setting up
          appointments for online consultations, listing your professional and
          personal profiles for reference purposes, payments processing,
          complaints and feedback and for communicating with you about your
          request. The information requested is needed by Advent IT Solutions
          Inc. for the provisioning of the service.
        </p>
        <p>The personal information collected are:</p>
        <ul>
          <li>Name</li>
          <li>Email Address</li>
          <li>Mobile Number</li>
          <li>Physical Address</li>
          <li>Date of Birth</li>
          <li>Gender</li>
          <li>Bank Account Name and Account Number</li>
        </ul>
        <h5>How We Collect Your Information</h5>
        <h5>
          Information we collect that you personally give us and information we
          collect during our relationship with you
        </h5>
        <p>
          Most of the personal information we have are those that you have given
          us yourself. You provide us personal information when you:
        </p>
        <ul>
          <li>Inquire about our services</li>
          <li>Book for online consultations</li>
          <li>Requesting for payment of fees or refunds</li>
          <li>
            Get in touch with us to ask about something or file a
            complaint/feedback
          </li>
          <li>Take part in our research and surveys; and</li>
          <li>Use our apps, websites and self-service channels</li>
        </ul>
        <h5>Information we collect from other sources</h5>
        <p>
          We also collect information from commercially or publicly available
          sources like published directories and public documents. We may also
          obtain your personal information from third parties (credit agencies)
          and from other sources which you have given consent for the disclosure
          of such information relating to you and where otherwise lawfully
          permitted.
        </p>
        <p>
          Advent IT Solutions Inc. may combine the information collected from
          these sources with other information in its possession, for purposes
          described in this policy.
        </p>
        <h5>How We Use Your Information</h5>
        <p>
          To improve our products and services, it is important that we collect,
          use, process and analyze your personal information when it is
          reasonable and necessary. Your personal information will not be
          disclosed to any other person or organization outside Advent IT
          Solutions Inc., except with your express consent or where required or
          allowed by law. Your application may be used for statistical and
          similar reporting purposes but the personal information will be
          de-identified and the data presented in aggregation such that you are
          never identified as an individual. Aggregating simply means presenting
          information in segments or categories like age groups; anonymizing
          means removing personally identifiable information from the data. In
          particular, we are using your information to:
        </p>
        <ol>
          <li>
            Manage your account, provide customer care activities, and provide
            services timely and efficiently;
          </li>
          <li>
            Send notifications for account activity, confirmation emails or SMS,
            commercial and promotional advertisements, loyalty and rewards
            offers, surveys, customer care and aftersales communications, and
            other broadcast push messages;
          </li>
          <li>
            Perform such other processing or disclosure that may be required
            under law or regulations.
          </li>
        </ol>
        <h5>Cookies and Third Party Technologies</h5>
        <p>
          Advent IT Solutions Inc. uses cookies and other identification
          technologies on our websites, emails, and online applications for
          purposes described in this policy. Cookies are small text files that
          are stored on your browser or device by websites, apps, online media,
          and advertisements. Advent IT Solutions Inc. uses cookies and similar
          technologies for purposes such as:
        </p>
        <ol>
          <li>Authenticating users</li>
          <li>Remembering user preferences and settings</li>
          <li>Determining the popularity of content</li>
          <li>Delivering and measuring the effectiveness of campaigns</li>
          <li>
            Analyzing site traffic and trends, and generally understanding the
            online behaviors and interests of people who interact with our
            services
          </li>
        </ol>
        <p>
          Advent IT Solutions Inc. may use such tools to analyze web traffic
          data, collect standard internet log information, and details of
          visitor behavior patterns. Data generated is not shared with any other
          party. Web traffic data collected and analyzed includes:
        </p>
        <ul>
          <li>User Visits</li>
          <li>Behavior Flow</li>
          <li>Geographic Locations</li>
          <li>Sessions by Device</li>
          <li>Sessions by Browser & OS</li>
        </ul>
        <h5>Information Sharing and Disclosure</h5>
        <p>
          It may be necessary to correspond about your application with other
          offices of the Advent IT Solutions Inc. in the process of determining
          your eligibility for the service. Your personal information will be
          provided to those other offices on a need-to-know basis. Information
          given to this office in confidence will be kept securely and will not
          be provided to any other person or office without proper
          authorization.
        </p>
        <h5>Information Retention and Deletion</h5>
        <p>
          Advent IT Solutions Inc. retains your information while your online
          visit remains active, unless you request termination of the service
          contract or deletion of your information. In some cases, Advent IT
          Solutions Inc. may retain certain information about you as required by
          law. Subject to the exceptions described below, Advent IT Solutions
          Inc. deletes or anonymizes your information upon request.
        </p>
        <ol>
          <li>
            If there is an unresolved issue relating to your account, such as an
            outstanding credit on your account or an unresolved claim or
            dispute;
          </li>
          <li>
            If we are required to by applicable law; and/or in aggregated and/or
            anonymized form.
          </li>
        </ol>
        <p>
          Your information will be stored in a database for{' '}
          <strong>5 years</strong>. After which, the personal information shall
          be deleted or anonymized for de-identification.
        </p>
        <h5>Protection Measures</h5>
        <p>
          The integrity, confidentiality and security of your information are
          particularly important to us. That’s why we strictly enforce our
          privacy policy within Advent IT Solutions Inc. and we have implemented
          technological, organizational and physical security measures that are
          designed to protect your information from unauthorized access, use,
          alteration and disclosure. We also put in effect safeguards such as:
        </p>
        <ol>
          <li>
            We keep and protect your information using a secured server behind a
            firewall, encryption and security controls;
          </li>
          <li>
            We restrict access to your information only to qualified and
            authorized personnel who hold your information with strict
            confidentiality;
          </li>
          <li>
            We undergo regular audit and rigorous testing of our
            infrastructure’s security protocols to ensure your data is always
            protected; and
          </li>
          <li>
            We coordinate with you to update your information securely to keep
            our records accurate
          </li>
        </ol>
        <h5>Access and Correction</h5>
        <p>
          You may apply at any time to see what personal information about you
          is held by Advent IT Solutions Inc. and to check its accuracy. If any
          of the information is inaccurate, out-of-date, or incorrect, you may
          apply to have the information corrected.
        </p>
        <h5>Complaints and Inquiries</h5>
        <p>
          If you consider the Advent IT Solutions Inc. has not managed or used
          your personal information in accordance with this statement, you may
          send an email to the Advent IT Solutions Inc. Data Protection Officer
          at{' '}
          <a href="mailto:support@advent-itsolutions.com">
            support@advent-itsolutions.com
          </a>{' '}
          and briefly discuss the concern, together with your contact details
          for reference. Please contact us with any queries you may have about
          the management of your personal information, by sending an email to
          the Advent IT Solutions Inc. Data Protection Officer at{' '}
          <a href="mailto:support@advent-itsolutions.com">
            support@advent-itsolutions.com
          </a>
          .
        </p>
        <h5>Updates To This Policy</h5>
        <p>
          We may occasionally update this policy. If we make significant
          changes, we will notify you of the changes through varying means if
          you have submitted your contact information to us. To the extent
          permitted under applicable law, by using our services after such
          notice, you consent to our updates to this policy. We encourage you to
          periodically review this policy for the latest information on our
          privacy practices.
        </p>
      </Card>
    </Container>
  </>
);

export default PolicyPage;
