import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthState } from '.';

const initialState: AuthState = {
  isUpdating: false,
  isPasswordChanging: false,
  isPhysicianUpdating: false,
  isLicenseUpdating: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsUpdatingTo: (state, action: PayloadAction<boolean>): void => {
      state.isUpdating = action.payload;
    },
    resetUpdate: (state, _action: PayloadAction): void => {
      state.isUpdating = false;
    },
    setIsPasswordChangingTo: (state, action: PayloadAction<boolean>): void => {
      state.isPasswordChanging = action.payload;
    },
    resetPasswordChange: (state, _action: PayloadAction): void => {
      state.isPasswordChanging = false;
    },
    setIsPhysicianUpdatingTo: (state, action: PayloadAction<boolean>): void => {
      state.isPhysicianUpdating = action.payload;
    },
    setIsLicenseUpdatingTo: (state, action: PayloadAction<boolean>): void => {
      state.isLicenseUpdating = action.payload;
    },
    resetPhysicianUpdate: (state, _action: PayloadAction): void => {
      state.isPhysicianUpdating = false;
    },
  },
});
export const {
  setIsUpdatingTo,
  resetUpdate,
  setIsPasswordChangingTo,
  resetPasswordChange,
  setIsPhysicianUpdatingTo,
  setIsLicenseUpdatingTo,
  resetPhysicianUpdate,
} = authSlice.actions;
