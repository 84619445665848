import React from 'react';
import ReactDOM from 'react-dom';
import 'styles/app.scss';
import { App } from 'components';
import { reportWebVitals } from 'utils/web-vitals';
import { Provider } from 'react-redux';
import { store } from 'redux/store';
import { CoreService } from 'api';
import { QueryClient, QueryClientProvider } from 'react-query';

(async (): Promise<void> => {
  reportWebVitals();
  CoreService.initialize();
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
      },
    },
  });
  ReactDOM.render(
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </Provider>,
    document.getElementById('root'),
  );
})();
