import { useCurrentUser, useFullSpecializations } from 'hooks';
import { FC, ReactElement, ReactNode } from 'react';
import Alert from 'react-bootstrap/Alert';
import { useHistory } from 'react-router-dom';

export const SpecializationWarning: FC = (): ReactElement => {
  const history = useHistory();
  const { data: user, isPhysicianUser } = useCurrentUser();
  const { isLoading, data: fullSpecializations = [] } = useFullSpecializations({
    isEnabled: !!user && isPhysicianUser,
    phy_uuid: user?.uuid,
  });

  const renderMain = (): ReactElement => (
    <Alert variant="warning">
      You don't have a specialization! Click {renderRedirectButton()} to add.
    </Alert>
  );

  const renderRedirectButton = (): ReactNode => (
    <a href="/specializations" onClick={redirectToSpecializationPage}>
      here
    </a>
  );

  const redirectToSpecializationPage = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ): void => {
    event.preventDefault();
    history.push('/specializations');
  };

  if (!isPhysicianUser || isLoading || !!fullSpecializations.length)
    return <></>;

  return renderMain();
};

export default SpecializationWarning;
