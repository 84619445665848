import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Role } from 'core/enums';
import { UiState } from '.';

const initialState: UiState = {
  registrationTitle: '',
  registrationClass: '',
  registrationRole: Role.PATIENT,
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setRegistrationTitle: (state, action: PayloadAction<Role>): void => {
      const { payload } = action;
      const t = payload === Role.PATIENT ? 'Patient' : 'Therapist';
      state.registrationTitle = `Register as ${t}`;
      state.registrationClass = payload.toLowerCase();
    },
    resetRegistrationUi: (state, _action: PayloadAction): void => {
      state.registrationTitle = initialState.registrationTitle;
      state.registrationClass = initialState.registrationClass;
      state.registrationRole = initialState.registrationRole;
    },
  },
});
export const { setRegistrationTitle, resetRegistrationUi } = uiSlice.actions;
