import { FC, ReactElement, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { AlertMessage, TopbarPublic } from 'components';
import { useHistory, useParams } from 'react-router-dom';
import {
  resetRegistrationUi,
  selectRegistrationClass,
  selectRegistrationTitle,
  setRegistrationTitle,
} from 'redux/ui';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { getRoleFromText } from 'utils/role';
import { NotFoundPage } from 'pages';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { CoreService } from 'api';
import { ErrorMessage, Role } from 'core/enums';
import { setToken } from 'utils/auth';
import { useCreateUser, useLogIn } from 'hooks';

export type RegistrationFormInputs = {
  username: string;
  email: string;
  password: string;
  password_confirm: string;
  agreement: boolean;
};

const passwordMinChar = 8;
const schema: yup.SchemaOf<RegistrationFormInputs> = yup
  .object()
  .shape({
    username: yup.string().required(ErrorMessage.REQUIRED),
    email: yup
      .string()
      .required(ErrorMessage.REQUIRED)
      .email(ErrorMessage.EMAIL),
    password: yup
      .string()
      .required(ErrorMessage.REQUIRED)
      .min(passwordMinChar, ErrorMessage.MINIMUM_8),
    password_confirm: yup
      .string()
      .required(ErrorMessage.REQUIRED)
      .oneOf([yup.ref('password')], ErrorMessage.PASSWORD_UNMATCH),
    agreement: yup.bool().isTrue(ErrorMessage.AGREEMENT),
  })
  .defined();

export interface RegistrationPageParams {
  type: string;
}

export const RegistrationPage: FC = (): ReactElement => {
  const loginUrl = '/login';
  const history = useHistory();
  const params = useParams<RegistrationPageParams>();
  const dispatch = useAppDispatch();
  const registrationTitle = useSelector(selectRegistrationTitle);
  const registrationClass = useSelector(selectRegistrationClass);
  const [isCheckingRole, setIsCheckingRole] = useState(true);
  const [role, setRole] = useState<Role | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const {
    isLoading: isSubmitting,
    isError,
    mutateAsync: createUser,
  } = useCreateUser();
  const { mutateAsync: logIn } = useLogIn();
  const {
    register,
    handleSubmit,
    unregister,
    errors,
  } = useForm<RegistrationFormInputs>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setIsCheckingRole(true);
    const role = getRoleFromText(params.type);
    if (role) {
      setRole(role);
      dispatch(setRegistrationTitle(role));
      setIsCheckingRole(false);
    }
    return () => {
      unregister([
        'username',
        'email',
        'password',
        'password_confirm',
        'agreement',
      ]);
      dispatch(resetRegistrationUi());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isCheckingRole) return <></>;
  if (!registrationTitle) return <NotFoundPage />;

  const renderPage = (): ReactElement => (
    <>
      <header>
        <TopbarPublic />
        <div className={`hero ${registrationClass}`}>
          <Container className="content">
            <h1>{registrationTitle}</h1>
          </Container>
        </div>
      </header>
      <main className="hero-main">
        <Container className="hero-form">
          <Card body>
            <AlertMessage visible={isError} />
            <AlertMessage visible={!!errorMessage}>{errorMessage}</AlertMessage>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group controlId="username">
                <Form.Label className="required">Username</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your username"
                  name="username"
                  ref={register}
                  isInvalid={!!errors.username}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.username?.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="email">
                <Form.Label className="required">Email</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your email"
                  name="email"
                  ref={register}
                  isInvalid={!!errors.email}
                />
                <Form.Text className="text-muted">Ex. name@email.com</Form.Text>
                <Form.Control.Feedback type="invalid">
                  {errors.email?.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="password">
                <Form.Label className="required">Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter your password"
                  name="password"
                  ref={register}
                  isInvalid={!!errors.password}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password?.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="password_confirm">
                <Form.Label className="required">Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter your password again"
                  name="password_confirm"
                  ref={register}
                  isInvalid={!!errors.password_confirm}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password_confirm?.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="agreement">
                <Form.Check
                  type="checkbox"
                  label={
                    <>
                      I have read, understood, and agree to the{' '}
                      <a
                        href="/tncandprivacypolicy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms and Conditions
                      </a>{' '}
                      of TheraWee.
                    </>
                  }
                  name="agreement"
                  ref={register}
                  isInvalid={!!errors.agreement}
                  feedback={errors.agreement?.message}
                />
              </Form.Group>
              <div className="form__actions">
                <Button variant="primary" type="submit" disabled={isSubmitting}>
                  Register
                </Button>
                <div>
                  Already registered?{' '}
                  <a href={loginUrl} onClick={handleLoginClick}>
                    Log in
                  </a>
                </div>
              </div>
            </Form>
          </Card>
        </Container>
      </main>
    </>
  );

  const onSubmit = async (data: RegistrationFormInputs): Promise<void> => {
    if (!role) return;
    setErrorMessage('');
    try {
      await createUser({
        ...data,
        role,
      });
      const auth = await logIn(data);
      setToken(auth.access_token);
      CoreService.setAuthorization();
      history.push('/users/setup');
    } catch (err) {
      if (!CoreService.isAbort(err)) {
        if (!!err.message) {
          const errMessage = err.message as string;
          if (errMessage.includes('UNIQUE KEY')) {
            if (errMessage.includes('u_username'))
              setErrorMessage('Username is already taken');
            else if (errMessage.includes('u_email'))
              setErrorMessage('Email is already taken');
          }
        }
      }
    }
  };

  const handleLoginClick = (
    event: React.MouseEvent<HTMLAnchorElement>,
  ): void => {
    event.preventDefault();
    redirectToLoginPage();
  };

  const redirectToLoginPage = (): void => {
    history.push(loginUrl);
  };

  return renderPage();
};

export default RegistrationPage;
