export { COOKIE_EXPIRATION_IN_DAYS, setCookie, getCookie, removeCookie };

const COOKIE_EXPIRATION_IN_DAYS = 365;

const setCookie = (
  name: string,
  value: string,
  expDays: number = COOKIE_EXPIRATION_IN_DAYS,
): void => {
  const currentDate = new Date();
  const hours = 24;
  const minutes = 60;
  const seconds = 60;
  const milliSecods = 1000;
  currentDate.setTime(
    currentDate.getTime() + expDays * hours * minutes * seconds * milliSecods,
  );
  const expires = `expires=${currentDate.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
};

const getCookie = (name: string): string | null => {
  const np = getNamePart(name);
  const cs = getCookies();
  return getCookieValue(np, cs);
};

const getNamePart = (name: string): string => `${name}=`;

const getCookies = (): string[] => document.cookie.split(';');

const getCookieValue = (namePart: string, cookies: string[]): string | null => {
  let i: number;
  for (i = 0; i < cookies.length; i++) {
    let c = cookies[i];
    while (c.charAt(0) === ' ') c = c.substring(1);
    if (c.indexOf(namePart) === 0)
      return c.substring(namePart.length, c.length);
  }
  return null;
};

const removeCookie = (name: string): void => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};
