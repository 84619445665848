import moment from 'moment';
import { FC, ReactElement, ReactNodeArray, ReactNode, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';
import { BookedSession, resetSession } from 'redux/session';
import { useAppDispatch } from 'redux/store';
import { getAccountImage } from 'utils/profile';
import { StatusBadge } from 'components';
import { useSessions } from 'hooks';

export const SessionList: FC<{ uuid: string }> = (props): ReactElement => {
  const { uuid } = props;
  const bookingUrl = '/sessions/new';
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { data: sessions = [] } = useSessions({
    user_uuid: uuid,
  });

  useEffect(() => {
    return () => {
      dispatch(resetSession());
    };
  }, [dispatch]);

  const renderMain = (): ReactElement => (
    <Card className="mt-3">
      <Card.Body>
        <Card.Title>Sessions</Card.Title>
        {renderEmptyMessage()}
        {renderTable()}
      </Card.Body>
    </Card>
  );

  const renderEmptyMessage = (): ReactNode => {
    if (sessions.length === 0)
      return (
        <Alert variant="warning">
          No booked sessions. Click{' '}
          <a href={bookingUrl} onClick={redirectToBookingPage}>
            here
          </a>{' '}
          to book.
        </Alert>
      );
  };

  const redirectToBookingPage = (
    event: React.MouseEvent<HTMLAnchorElement>,
  ): void => {
    event.preventDefault();
    history.push(`/sessions/new?patient_uuid=${uuid}`);
  };

  const renderTable = (): ReactNode => {
    if (sessions.length > 0)
      return (
        <>
          <div className="session-list">{renderSessions()}</div>
          {sessions.length > 0 && (
            <Button
              variant="secondary"
              block
              className="mt-3"
              onClick={redirectToBookingPage}
            >
              Book Another Session
            </Button>
          )}
        </>
      );
  };

  const renderSessions = (): ReactNodeArray =>
    sessions.map(
      (s, k): ReactNode => (
        <div key={k} className="session-item" onClick={() => viewSession(s)}>
          <img
            src={getAccountImage(s.physician_gender)}
            alt={s.physician_name}
            className="user-picture"
          />
          <div className="detail">
            <div>{s.physician_name}</div>
            <div className="consultation-date">
              {moment(s.consultation_date).format('ddd. MMM. DD, YYYY h:mm A')}
            </div>
            <div className="consultation-status">
              <StatusBadge status={s.status} />
            </div>
          </div>
        </div>
      ),
    );

  const viewSession = (bookedSession: BookedSession): void => {
    history.push(
      `/patients/${bookedSession.patient}/sessions/${bookedSession.uuid}`,
    );
  };

  return renderMain();
};

export default SessionList;
