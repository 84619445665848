import { FC, ReactElement, ReactNode, ReactNodeArray, useEffect } from 'react';
import { PageHeader } from 'components';
import {
  PatientSection,
  SessionSection,
  NewSession,
  SessionSuccess,
  SessionLearn,
} from './components';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  addMoreSession,
  resetBooking,
  selectIsAddMore,
  selectNewSession,
  selectSessions,
  selectSuccessPatientUuid,
  setToSuccess,
} from 'redux/booking';
import { useAppDispatch } from 'redux/store';
import { useSelector } from 'react-redux';
import {
  selectSubSpecializations,
  selectTopSpecialization,
} from 'redux/specialization';
import { ErrorMessage, RecurOption } from 'core/enums';
import { useCreateSession } from 'hooks';

export type BookingInfoFormInputs = {
  patient: string;
  health_query: string;
  top_specialization: string;
  specialization: string;
  date: string;
  hour: string;
  minute: string;
  meridiem: string;
  recur_option: RecurOption;
  repetition: string;
  physician: string;
};

export const BookingPage: FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const topSpecialization = useSelector(selectTopSpecialization);
  const subSpecializations = useSelector(selectSubSpecializations);
  const sessions = useSelector(selectSessions);
  const newSession = useSelector(selectNewSession);
  const isAddMore = useSelector(selectIsAddMore);
  const successPatientUuid = useSelector(selectSuccessPatientUuid);
  const { isLoading: isSubmitting, mutateAsync } = useCreateSession();
  let validation: any = {
    patient: yup.string().required(ErrorMessage.REQUIRED),
    health_query: yup.string().required(ErrorMessage.REQUIRED),
  };
  if (newSession) {
    validation = {
      ...validation,
      date: yup.string().required(ErrorMessage.REQUIRED),
      hour: yup
        .string()
        .required(ErrorMessage.REQUIRED)
        .test('range', ErrorMessage.HOUR, (value) => {
          if (!value) return false;
          const i = parseInt(value);
          return i >= 1 && i <= 12;
        }),
    };
  }
  const schema: yup.SchemaOf<BookingInfoFormInputs> = yup
    .object()
    .shape(validation)
    .defined();
  const form = useForm<BookingInfoFormInputs>({
    resolver: yupResolver(schema),
  });
  const { setError } = form;

  useEffect(() => {
    return () => {
      dispatch(resetBooking());
    };
  }, [dispatch]);

  const renderPage = (): ReactElement => {
    if (!!successPatientUuid) return <SessionSuccess />;
    return (
      <>
        <PageHeader title="Book Session" />
        <Form onSubmit={form.handleSubmit(onSubmit)}>
          <PatientSection form={form} />
          {renderSessionSections()}
          <Button
            variant="secondary"
            block
            disabled={!isAddMore || isSubmitting}
            className="mt-3"
            onClick={addSession}
          >
            ADD MORE SCHEDULE?
          </Button>
          <Button variant="primary" type="submit" block disabled={isSubmitting}>
            SUBMIT
          </Button>
        </Form>
        <SessionLearn />
      </>
    );
  };

  const onSubmit = async (data: BookingInfoFormInputs): Promise<void> => {
    let he = false;
    if (!!newSession && !topSpecialization) {
      setError('top_specialization', {
        type: 'manual',
        message: ErrorMessage.REQUIRED,
      });
      he = true;
    }
    if (!!newSession && !subSpecializations.length) {
      setError('specialization', {
        type: 'manual',
        message: ErrorMessage.REQUIRED,
      });
      he = true;
    }
    if (!!newSession && !newSession.physician) {
      setError('physician', {
        type: 'manual',
        message: 'Please select a therapist below.',
      });
      he = true;
    }
    if (he) return;
    const { patient, health_query } = data;
    await mutateAsync({
      patient_uuid: patient,
      health_query,
      sessions,
    });
    dispatch(setToSuccess(patient));
  };

  const renderSessionSections = (): ReactNodeArray =>
    sessions.map(
      (s, k): ReactNode => {
        if (s.isComplete) return <SessionSection key={k} session={s} />;
        return <NewSession key={k} form={form} session={s} />;
      },
    );

  const addSession = (): void => {
    dispatch(addMoreSession());
  };

  return renderPage();
};

export default BookingPage;
