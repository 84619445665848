import { PhysicianService } from 'api';
import { useQuery, UseQueryResult } from 'react-query';
import { Physician } from 'redux/physician';
import { UsePhysicians } from '.';

export const usePhysicians = (
  data: UsePhysicians,
): UseQueryResult<Physician[]> => {
  return useQuery(
    [
      'physicians',
      {
        physicianName: data.name,
        specialization: data.specialization,
        consultationDate: data.consultation_date,
      },
    ],
    () =>
      PhysicianService.getPhysicians({
        name: data.name,
        specialization: data.specialization,
        consultation_date: data.consultation_date,
      }),
    {
      enabled: data.isEnabled,
    },
  );
};
