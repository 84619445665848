import { configureStore, Action } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import { useDispatch } from 'react-redux';
import { uiSlice } from 'redux/ui';
import { authSlice } from 'redux/auth';
import { patientSlice } from 'redux/patient';
import { scheduleSlice } from 'redux/schedule';
import { bookingSlice } from 'redux/booking';
import { sessionSlice } from 'redux/session';
import { specializationSlice } from 'redux/specialization';
import { taskSlice } from 'redux/task';

export const store = configureStore({
  reducer: {
    ui: uiSlice.reducer,
    auth: authSlice.reducer,
    patient: patientSlice.reducer,
    schedule: scheduleSlice.reducer,
    booking: bookingSlice.reducer,
    session: sessionSlice.reducer,
    specialization: specializationSlice.reducer,
    task: taskSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
