import { FC, ReactElement, ReactNode, ReactNodeArray } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { UseFormMethods } from 'react-hook-form';
import { BookingInfoFormInputs } from 'pages/booking-page/BookingPage';
import { useAppDispatch } from 'redux/store';
import {
  selectTopSpecialization,
  selectSpecializations,
  selectTopSpecializations,
  setTopSpecialization,
  selectSubSpecializations,
  setSubSpecializations,
  setLearnSpecialization,
} from 'redux/specialization';
import { Specialization } from 'core/enums';
import { useSelector } from 'react-redux';

export const SpecializationSection: FC<{
  form: UseFormMethods<BookingInfoFormInputs>;
}> = (props): ReactElement => {
  const { form } = props;
  const { register, errors } = form;
  const dispatch = useAppDispatch();
  const topSpecialization = useSelector(selectTopSpecialization);
  const topSpecializations = useSelector(selectTopSpecializations);
  const subSpecializations = useSelector(selectSubSpecializations);
  const specializations = useSelector(selectSpecializations);

  const renderSection = (): ReactElement => (
    <>
      <Form.Group controlId="specialization_need">
        <Form.Label className="required">What therapy do you need?</Form.Label>
        <p className="error-message">{errors.top_specialization?.message}</p>
        <div className="booking__specialization services">
          {renderTopSpecializations()}
        </div>
      </Form.Group>
      <Form.Group controlId="specialization">
        <Form.Label className="required">Specialization</Form.Label>
        <p className="error-message">{errors.specialization?.message}</p>
        <div className="booking__specialization">{renderSpecializations()}</div>
      </Form.Group>
    </>
  );

  const renderTopSpecializations = (): ReactNodeArray =>
    topSpecializations.map(
      (s): ReactNode => {
        return (
          <label
            key={s.id}
            className={`booking__specialization-item ${
              topSpecialization === s.type ? 'active' : ''
            }`}
          >
            <input
              type="radio"
              className="booking__specialization-input"
              id={s.id}
              name="top_specialization"
              value={s.id}
              ref={register}
              onClick={() => selectTopSpecializationCard(s.type)}
            />
            <img src={`../services/${s.id}.svg`} alt={s.label} />
            {s.label}
            <Button
              variant="link"
              onClick={(event) => viewSpecializationInfo(event, s.type)}
            >
              Learn More
            </Button>
          </label>
        );
      },
    );

  const selectTopSpecializationCard = (type: Specialization): void => {
    dispatch(setTopSpecialization(type));
  };

  const viewSpecializationInfo = (
    event: React.MouseEvent<HTMLElement>,
    type: Specialization,
  ): void => {
    event.stopPropagation();
    dispatch(setLearnSpecialization(type));
  };

  const renderSpecializations = (): ReactNodeArray =>
    specializations.map(
      (s): ReactNode => {
        return (
          <label
            key={s.id}
            className={`booking__specialization-item ${
              subSpecializations.includes(s.type) ? 'active' : ''
            }`}
          >
            <input
              type="radio"
              className="booking__specialization-input"
              id={s.id}
              name="specialization"
              value={s.id}
              ref={register}
              onClick={() => selectSpecializationCard(s.type)}
            />
            <img src={`../specializations/${s.id}.svg`} alt={s.label} />
            {s.label}
            <Button
              variant="link"
              onClick={(event) => viewSpecializationInfo(event, s.type)}
            >
              Learn More
            </Button>
          </label>
        );
      },
    );

  const selectSpecializationCard = (type: Specialization): void => {
    dispatch(setSubSpecializations(type));
  };

  return renderSection();
};

export default SpecializationSection;
