import { RootState } from 'redux/store';
import { PatientsSession, SessionState } from '.';
import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';

export {
  selectSession,
  selectIsViewing,
  selectCurrentDate,
  selectBeginDate,
  selectPatientsSession,
  selectHasMergedSession,
  selectEndDate,
  selectIsUpdatingFeedbackLike,
  selectIsUpdatingFeedbackImprove,
  selectIsUpdatingHomework,
  selectIsUpdatingRecommendation,
  selectIsRescheduling,
  selectIsCompleting,
  selectIsCancelling,
  selectIsVoucherPaying,
  selectIsGcashPaying,
};

const selectSession = (state: RootState): SessionState => state.session;

const selectIsViewing = createSelector(
  selectSession,
  (session): boolean => session.isViewing,
);

const selectCurrentDate = createSelector(
  selectSession,
  (session): string => session.current_date,
);

const selectBeginDate = createSelector(
  selectCurrentDate,
  (currentDate): string => moment(currentDate).add(-3, 'days').format(),
);

const selectPatientsSession = createSelector(
  selectSession,
  (session): PatientsSession | undefined => session.patientsSession,
);

const selectHasMergedSession = createSelector(
  selectSession,
  (session): boolean => !!session.patientsSession,
);

const selectEndDate = createSelector(selectCurrentDate, (currentDate): string =>
  moment(currentDate).add(3, 'days').format(),
);

const selectIsUpdatingFeedbackLike = createSelector(
  selectSession,
  (session): boolean => session.isUpdatingFeedbackLike,
);

const selectIsUpdatingFeedbackImprove = createSelector(
  selectSession,
  (session): boolean => session.isUpdatingFeedbackImprove,
);

const selectIsUpdatingHomework = createSelector(
  selectSession,
  (session): boolean => session.isUpdatingHomework,
);

const selectIsUpdatingRecommendation = createSelector(
  selectSession,
  (session): boolean => session.isUpdatingRecommendation,
);

const selectIsRescheduling = createSelector(
  selectSession,
  (session): boolean => session.isRescheduling,
);

const selectIsCompleting = createSelector(
  selectSession,
  (session): boolean => session.isCompleting,
);

const selectIsCancelling = createSelector(
  selectSession,
  (session): boolean => session.isCancelling,
);

const selectIsVoucherPaying = createSelector(
  selectSession,
  (session): boolean => session.isVoucherPaying,
);

const selectIsGcashPaying = createSelector(
  selectSession,
  (session): boolean => session.isGcashPaying,
);
