import { TOKEN_NAME } from './config';
import { getCookie, removeCookie, setCookie } from './cookie';

export { getToken, hasToken, setToken, removeToken };

const getToken = (): string | null => getCookie(TOKEN_NAME);

const hasToken = (): boolean => !!getToken();

const setToken = (token: string): void => setCookie(TOKEN_NAME, token);

const removeToken = (): void => removeCookie(TOKEN_NAME);
