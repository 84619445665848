import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { AlertMessage } from 'components';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { setIsReschedulingTo, selectIsRescheduling } from 'redux/session';
import { useAppDispatch } from 'redux/store';
import {
  useDebounce,
  usePhysicians,
  useSession,
  useSessionInfoParams,
  useUpdateSession,
} from 'hooks';
import moment from 'moment';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from 'core/enums';
import { getAccountImage } from 'utils/profile';
import { toFullName } from 'utils/user';
import { Physician } from 'redux/physician';

export type RescheduleForm = {
  date: string;
  hour: string;
  minute: string;
  meridiem: string;
  physician: string;
};

const schema: yup.SchemaOf<RescheduleForm> = yup
  .object()
  .shape({
    date: yup.string().required(ErrorMessage.REQUIRED),
    hour: yup
      .string()
      .required(ErrorMessage.REQUIRED)
      .test('range', ErrorMessage.HOUR, (value) => {
        if (!value) return false;
        const i = parseInt(value);
        return i >= 1 && i <= 12;
      }),
  })
  .defined();

export const Reschedule = (): ReactElement => {
  const dispatch = useAppDispatch();
  const params = useSessionInfoParams();
  const { errors, setError, register, handleSubmit, watch } =
    useForm<RescheduleForm>({
      resolver: yupResolver(schema),
    });
  const watchDate = watch('date');
  const watchHour = watch('hour');
  const watchMinute = watch('minute');
  const watchMeridiem = watch('meridiem');
  const watchPhysician = watch('physician');
  const debouncedPhysician = useDebounce(watchPhysician);
  const [physicianName, setPhysicianName] = useState('');
  const [consultationDate, setConsultationDate] = useState('');
  const [currentPhysician, setCurrentPhysician] = useState<string>();
  const isReschedulingReschedule = useSelector(selectIsRescheduling);
  const { data: sessionView } = useSession(
    params.session_uuid,
    params.pat_uuid,
  );
  const { data: physicians = [] } = usePhysicians({
    isEnabled: !!sessionView?.specialization && !!consultationDate,
    name: physicianName,
    specialization: sessionView?.specialization as string,
    consultation_date: consultationDate,
  });
  const {
    isLoading: isSubmitting,
    isError: isSubmitError,
    mutateAsync,
  } = useUpdateSession(params.session_uuid);

  useEffect((): void => {
    let cd = '';
    if (!!watchDate && !!watchHour && !!watchMinute && !!watchMeridiem) {
      const m = moment(
        `${watchDate} ${parseInt(watchHour)}:${watchMinute} ${watchMeridiem}`,
        'YYYY-MM-DD h:mm A',
      );
      let d = m.day();
      if (d === 0) d = 7;
      cd = m.format(`2020-06-${d} HH:mm:00`);
    }
    setPhysicianName(debouncedPhysician);
    setConsultationDate(cd);
    setCurrentPhysician(undefined);
  }, [debouncedPhysician, watchDate, watchHour, watchMinute, watchMeridiem]);

  const renderModal = (): ReactElement => (
    <Modal show={isReschedulingReschedule} onHide={stopRescheduling}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>Reschedule</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertMessage visible={isSubmitError} />
          <Row>
            <Col md={5}>
              <Form.Group controlId="slot-date">
                <Form.Label className="required">Date</Form.Label>
                <Form.Control
                  type="date"
                  name="date"
                  min={moment().format('YYYY-MM-DD')}
                  ref={register}
                  isInvalid={!!errors.date}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.date?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={7}>
              <Form.Row>
                <Form.Group as={Col} controlId="slot-hour">
                  <Form.Label className="required">Time</Form.Label>
                  <Form.Control
                    min="1"
                    max="12"
                    type="number"
                    placeholder="H"
                    name="hour"
                    ref={register}
                    isInvalid={!!errors.hour}
                  />
                  <Form.Text className="text-muted">Hour (1 - 12)</Form.Text>
                  <Form.Control.Feedback type="invalid">
                    {errors.hour?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} controlId="slot-minute">
                  <Form.Label className="text-white">Minute</Form.Label>
                  <Form.Control as="select" name="minute" ref={register}>
                    <option value="00">00</option>
                    <option value="30">30</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} controlId="slot-meridiem">
                  <Form.Label className="text-white">Meridiem</Form.Label>
                  <Form.Control as="select" name="meridiem" ref={register}>
                    <option value="AM">AM</option>
                    <option value="PM">PM</option>
                  </Form.Control>
                </Form.Group>
              </Form.Row>
            </Col>
          </Row>
          <Form.Group controlId="physician">
            <Form.Label>Therapist</Form.Label>
            <Form.Control
              type="text"
              placeholder="Search by name"
              name="physician"
              ref={register}
              isInvalid={!!errors.physician}
            />
            <Form.Control.Feedback type="invalid">
              {errors.physician?.message}
            </Form.Control.Feedback>
          </Form.Group>
          {renderEmptyPhysicians()}
          {renderPhysicians()}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            disabled={isSubmitting}
            onClick={stopRescheduling}
          >
            Cancel
          </Button>
          <Button variant="primary" type="submit" disabled={isSubmitting}>
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );

  const stopRescheduling = (): void => {
    if (isSubmitting) return;
    dispatch(setIsReschedulingTo(false));
  };

  const onSubmit = async (data: RescheduleForm): Promise<void> => {
    if (!sessionView) {
      return;
    }
    const today = moment('0:00', 'H:mm');
    const schedule = moment(data.date, 'YYYY-MM-DD');
    if (schedule.diff(today, 'days') < 0) {
      setError('date', {
        type: 'min',
        message: `Date must be ${today.format('DD MMM YYYY')} or later`,
        shouldFocus: true,
      });
      return;
    }
    if (!currentPhysician) {
      setError('physician', {
        type: 'manual',
        message: 'Please select a therapist below.',
      });
      return;
    }
    const consultation_date = moment(
      `${data.date} ${parseInt(data.hour)}:${data.minute} ${data.meridiem}`,
      'YYYY-MM-DD h:mm A',
    ).format('YYYY-MM-DD HH:mm:00');
    await mutateAsync({
      booking_uuid: sessionView.uuid,
      health_query: sessionView.health_query,
      specialization: sessionView.specialization,
      physician_uuid: currentPhysician,
      feedback_like: sessionView.feedback_like,
      feedback_improve: sessionView.feedback_improve,
      therapy_homework: sessionView.therapy_homework,
      therapy_recommendation: sessionView.recommendation,
      consultation_date,
    });
    stopRescheduling();
  };

  const renderEmptyPhysicians = (): ReactNode => {
    if (physicians.length === 0)
      return (
        <Alert variant="warning">
          No available therapist based on your selected specialization, date,
          and time.
        </Alert>
      );
  };

  const renderPhysicians = (): ReactNode => {
    if (physicians.length > 0)
      return (
        <div className="booking__physicians">
          {physicians.map(
            (p): ReactNode => (
              <div
                key={p.uuid}
                className={`booking__physician-item ${
                  p.uuid === currentPhysician ? 'active' : ''
                }`}
                onClick={() => selectPhysician(p)}
              >
                <div className="physician-user">
                  <img src={getAccountImage(p.gender)} alt={toFullName(p)} />
                  {toFullName(p)}
                </div>
              </div>
            ),
          )}
        </div>
      );
  };

  const selectPhysician = (physician: Physician): void => {
    setCurrentPhysician(physician.uuid);
  };

  return renderModal();
};

export default Reschedule;
