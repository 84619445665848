import React, { FC, ReactElement } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { AlertMessage } from 'components';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { setIsPasswordChangingTo, selectIsPasswordChanging } from 'redux/auth';
import { useAppDispatch } from 'redux/store';
import { ErrorMessage } from 'core/enums';
import { useUpdatePassword } from 'hooks';

export type PasswordInfoFormInputs = {
  password: string;
  password_confirm: string;
};

const passwordMinChar = 8;
const schema: yup.SchemaOf<PasswordInfoFormInputs> = yup
  .object()
  .shape({
    password: yup
      .string()
      .required(ErrorMessage.REQUIRED)
      .min(passwordMinChar, ErrorMessage.MINIMUM_8),
    password_confirm: yup
      .string()
      .required(ErrorMessage.REQUIRED)
      .oneOf([yup.ref('password')], ErrorMessage.PASSWORD_UNMATCH),
  })
  .defined();

export const PasswordChange: FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const { isLoading: isSubmitting, isError, mutateAsync } = useUpdatePassword();
  const { register, handleSubmit, errors } = useForm<PasswordInfoFormInputs>({
    resolver: yupResolver(schema),
  });
  const isUpdating = useSelector(selectIsPasswordChanging);

  const renderModal = (): ReactElement => (
    <Modal show={isUpdating} onHide={stopUpdating}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertMessage visible={isError} />
          <Form.Group controlId="password">
            <Form.Label className="required">New Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter your new password"
              name="password"
              ref={register}
              isInvalid={!!errors.password}
            />
            <Form.Control.Feedback type="invalid">
              {errors.password?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="password_confirm">
            <Form.Label className="required">Confirm New Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter your new password again"
              name="password_confirm"
              ref={register}
              isInvalid={!!errors.password_confirm}
            />
            <Form.Control.Feedback type="invalid">
              {errors.password_confirm?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" disabled={isSubmitting} onClick={stopUpdating}>
            Cancel
          </Button>
          <Button variant="primary" type="submit" disabled={isSubmitting}>
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );

  const stopUpdating = (): void => {
    if (isSubmitting) return;
    dispatch(setIsPasswordChangingTo(false));
  };

  const onSubmit = async (data: PasswordInfoFormInputs): Promise<void> => {
    await mutateAsync(data.password);
    stopUpdating();
  };

  return renderModal();
};

export default PasswordChange;
