import { BookStatus } from 'core/enums';
import { FC, ReactElement } from 'react';
import { displayStatus } from 'utils/status';

export interface StatusBadgeProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  status: BookStatus;
}

export const StatusBadge: FC<StatusBadgeProps> = (props): ReactElement => {
  const { status, className, style, ...rest } = props;

  const getVariant = (): { backgroundColor: string; color: string } => {
    switch (status) {
      case BookStatus.SUBMITTED:
        return {
          backgroundColor: '#FABC2B',
          color: '#212529',
        };
      case BookStatus.PAID:
        return {
          backgroundColor: '#27AE60',
          color: '#fff',
        };
      case BookStatus.COMPLETED:
        return {
          backgroundColor: '#2F80ED',
          color: '#fff',
        };
      case BookStatus.CANCELLED:
      default:
        return {
          backgroundColor: '#EB5757',
          color: '#fff',
        };
    }
  };

  return (
    <div
      className={`status-badge${!!className ? ` ${className}` : ''}`}
      style={{
        ...style,
        ...getVariant(),
      }}
      {...rest}
    >
      {displayStatus(status)}
    </div>
  );
};

export default StatusBadge;
