import axios, { CancelToken, AxiosInstance } from 'axios';
import { getToken } from 'utils/auth';
import { getBaseUrl } from 'utils/config';
import { BaseResponse } from '..';

export { CoreService };

class CoreService {
  private static instance: AxiosInstance;
  private static readonly successCode = 200;

  public static initialize(): void {
    this.createInstance();
    this.setAuthorization();
  }

  private static createInstance(): void {
    this.instance = axios.create({
      baseURL: getBaseUrl(),
    });
  }

  public static setAuthorization(): void {
    const token = getToken();
    this.instance.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  public static cancelRequest(cancelerName: string): void {
    const w = window as any;
    const c = w[cancelerName];
    if (c) c();
    w[cancelerName] = null;
  }

  public static isAbort(err: any): boolean {
    return axios.isCancel(err);
  }

  public static async get<T>(
    url: string,
    cancelerName: string,
    params?: any,
  ): Promise<T> {
    const r = await this.instance.get<BaseResponse<T>>(url, {
      params,
      cancelToken: this.initializeCanceler(cancelerName),
    });
    const { response_code, result } = r.data;
    if (this.isErrorCode(response_code)) return this.rejectCode(r.data);
    return result;
  }

  private static initializeCanceler(cancelerName: string): CancelToken {
    return new axios.CancelToken((cancel) => {
      (window as any)[cancelerName] = cancel;
    });
  }

  private static isErrorCode(code: number): boolean {
    return code !== this.successCode;
  }

  private static rejectCode<T>(response: BaseResponse<T>): Promise<T> {
    return Promise.reject(response);
  }

  public static async postCore<T, K>(
    url: string,
    data: K,
    cancelerName: string,
  ): Promise<T> {
    const r = await this.instance.post<T>(url, data, {
      cancelToken: this.initializeCanceler(cancelerName),
    });
    return r.data;
  }

  public static async post<T, K>(
    url: string,
    data: K,
    cancelername: string,
  ): Promise<T> {
    const r = await this.instance.post<BaseResponse<T>>(url, data, {
      cancelToken: this.initializeCanceler(cancelername),
    });
    const { response_code, result } = r.data;
    if (this.isErrorCode(response_code)) return this.rejectCode(r.data);
    return result;
  }

  public static async put<T, K>(
    url: string,
    data: K,
    cancelername: string,
  ): Promise<T> {
    const r = await this.instance.put<BaseResponse<T>>(url, data, {
      cancelToken: this.initializeCanceler(cancelername),
    });
    const { response_code, result } = r.data;
    if (this.isErrorCode(response_code)) return this.rejectCode(r.data);
    return result;
  }

  public static async delete<T>(url: string, cancelername: string): Promise<T> {
    const r = await this.instance.delete<BaseResponse<T>>(url, {
      cancelToken: this.initializeCanceler(cancelername),
    });
    const { response_code, result } = r.data;
    if (this.isErrorCode(response_code)) return this.rejectCode(r.data);
    return result;
  }
}
