import { FC, ReactElement } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { AlertMessage } from 'components';
import { useSelector } from 'react-redux';
import {
  selectIsDeleting,
  selectFullSpecialization,
  setIsDeletingTo,
  unsetFullSpecialization,
} from 'redux/specialization';
import { useAppDispatch } from 'redux/store';
import { User } from 'redux/auth';
import { useCurrentUser, useDeleteSpecialization } from 'hooks';

export const SpecializationDelete: FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const isDeleting = useSelector(selectIsDeleting);
  const fullSpecialization = useSelector(selectFullSpecialization);
  const { data: user } = useCurrentUser();
  const { isLoading, isError, mutateAsync } = useDeleteSpecialization(
    (user as User).uuid,
  );

  if (!fullSpecialization) return <></>;

  const renderModal = (): ReactElement => (
    <Modal show={isDeleting} onHide={stopDeleting}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Specialization</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AlertMessage visible={isError} />
        <ul>
          <li>{fullSpecialization.specialization}</li>
          <li>{fullSpecialization.level}</li>
          <li>{fullSpecialization.accredited_by}</li>
          <li>{fullSpecialization.completed_on}</li>
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" disabled={isLoading} onClick={stopDeleting}>
          Cancel
        </Button>
        <Button
          variant="danger"
          type="submit"
          disabled={isLoading}
          onClick={submit}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const stopDeleting = (): void => {
    if (isLoading) return;
    dispatch(setIsDeletingTo(false));
  };

  const submit = async (): Promise<void> => {
    await mutateAsync(fullSpecialization.id);
    stopDeleting();
    dispatch(unsetFullSpecialization());
  };

  return renderModal();
};

export default SpecializationDelete;
