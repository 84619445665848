import { FC, ReactElement, ReactNode, ReactNodeArray, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { useAppDispatch } from 'redux/store';
import { resetPatient, setIsCreatingTo } from 'redux/patient';
import { useHistory } from 'react-router-dom';
import { getPatientImage } from 'utils/profile';
import { PatientWarning } from '..';
import { toFullName } from 'utils/user';
import { useCurrentUser, usePatients } from 'hooks';

export const PatientList: FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { data: user, isPatientUser } = useCurrentUser();
  const { data: patients = [] } = usePatients(
    isPatientUser ? user?.uuid : undefined,
  );

  useEffect(() => {
    return () => {
      dispatch(resetPatient());
    };
  }, [dispatch]);

  const renderSection = (): ReactElement => (
    <Card className="mt-3">
      <Card.Body>
        <Card.Title className="card-controls">
          Patients
          <Button
            variant="link"
            className="btn-link-secondary"
            onClick={startCreating}
          >
            Add New
          </Button>
        </Card.Title>
        {patients.length === 0 && <PatientWarning />}
        {renderList()}
      </Card.Body>
    </Card>
  );

  const startCreating = (): void => {
    dispatch(setIsCreatingTo(true));
  };

  const renderList = (): ReactNodeArray =>
    patients.map(
      (p): ReactNode => (
        <div
          key={p.uuid}
          className="patient-item"
          onClick={() => redirectToPatientInfoPage(p.uuid)}
        >
          <img
            src={getPatientImage(p.gender)}
            alt={`${toFullName(p)} Profile`}
            className="user-picture"
          />
          <div className="detail">
            <div>{toFullName(p)}</div>
            <div>{p.diagnosis}</div>
          </div>
        </div>
      ),
    );

  const redirectToPatientInfoPage = (patient_uuid: string): void => {
    history.push(`/patients/${patient_uuid}`);
  };

  if (!isPatientUser) return <></>;

  return renderSection();
};

export default PatientList;
