import { CreatePayment, CreateSource, PaymentService } from 'api';
import { AxiosError } from 'axios';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from 'react-query';
import { Payment, PaymentSource } from 'redux/payment';

export const usePayment = (id: number): UseQueryResult<Payment> => {
  return useQuery(['payments', id], () => PaymentService.getPayment(id));
};

export const useCreatePayment = (): UseMutationResult<
  PaymentSource,
  AxiosError,
  CreatePayment
> => {
  return useMutation(PaymentService.createPayment);
};

export const useCreateGcashSource = (): UseMutationResult<
  PaymentSource,
  AxiosError,
  CreateSource
> => {
  return useMutation(PaymentService.createGcashSource);
};

export const useCreateGrabPaySource = (): UseMutationResult<
  PaymentSource,
  AxiosError,
  CreateSource
> => {
  return useMutation(PaymentService.createGrabPaySource);
};
