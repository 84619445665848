import React, { ReactElement, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { AlertMessage } from 'components';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import {
  selectTask,
  setIsUpdatingTo,
  selectIsUpdating,
  unsetTask,
} from 'redux/task';
import { useAppDispatch } from 'redux/store';
import { ErrorMessage } from 'core/enums';
import { useSession, useUpdateTask, useSessionInfoParams } from 'hooks';

export type TaskForm = {
  goal: string;
  materials: string;
  activity: string;
  remarks: string;
};

const schema: yup.SchemaOf<TaskForm> = yup
  .object()
  .shape({
    goal: yup.string().required(ErrorMessage.REQUIRED),
    materials: yup.string().required(ErrorMessage.REQUIRED),
  })
  .defined();

export const TaskUpdate = (): ReactElement => {
  const dispatch = useAppDispatch();
  const params = useSessionInfoParams();
  const task = useSelector(selectTask);
  const { register, handleSubmit, errors, setValue } = useForm<TaskForm>({
    resolver: yupResolver(schema),
  });
  const isUpdating = useSelector(selectIsUpdating);
  const { data: sessionView } = useSession(
    params.session_uuid,
    params.pat_uuid,
  );
  const {
    isLoading: isSubmitting,
    isError: isSubmitError,
    mutateAsync,
  } = useUpdateTask(params.session_uuid);

  useEffect(() => {
    if (!isUpdating || !task) return;
    setValue('goal', task.goal);
    setValue('materials', task.assessment);
    setValue('activity', task.activity);
    setValue('remarks', task.recommendation);
  }, [isUpdating, task, setValue]);

  const renderModal = (): ReactElement => (
    <Modal show={isUpdating} onHide={stopUpdating}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Task Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertMessage visible={isSubmitError} />
          <Form.Group controlId="goal">
            <Form.Label>Goal</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              placeholder="Enter goal"
              name="goal"
              ref={register}
              isInvalid={!!errors.goal}
            />
            <Form.Control.Feedback type="invalid">
              {errors.goal?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="materials">
            <Form.Label>Materials</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              placeholder="Enter materials"
              name="materials"
              ref={register}
              isInvalid={!!errors.materials}
            />
            <Form.Control.Feedback type="invalid">
              {errors.materials?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="activity">
            <Form.Label>Activity/Assessment</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              placeholder="Enter activity/assessment"
              name="activity"
              ref={register}
            />
          </Form.Group>
          <Form.Group controlId="remarks">
            <Form.Label>Remarks</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              placeholder="Enter remarks"
              name="remarks"
              ref={register}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" disabled={isSubmitting} onClick={stopUpdating}>
            Cancel
          </Button>
          <Button variant="primary" type="submit" disabled={isSubmitting}>
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );

  const stopUpdating = (): void => {
    if (isSubmitting) return;
    dispatch(setIsUpdatingTo(false));
    dispatch(unsetTask());
  };

  const onSubmit = async (data: TaskForm): Promise<void> => {
    if (!sessionView || !task) {
      return;
    }
    await mutateAsync({
      task_id: task.id,
      goal: data.goal,
      assessment: data.materials,
      activity: data.activity,
      recommendation: data.remarks,
    });
    stopUpdating();
  };

  return renderModal();
};

export default TaskUpdate;
