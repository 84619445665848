import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'redux/store';
import { TaskState, Task } from '.';

const initialState: TaskState = {
  task: undefined,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
};

export const taskSlice = createSlice({
  name: 'task',
  initialState,
  reducers: {
    setTask: (state, action: PayloadAction<Task>): void => {
      state.task = action.payload;
    },
    unsetTask: (state, _action: PayloadAction): void => {
      state.task = undefined;
    },
    setIsCreatingTo: (state, action: PayloadAction<boolean>): void => {
      state.isCreating = action.payload;
    },
    setIsUpdatingTo: (state, action: PayloadAction<boolean>): void => {
      state.isUpdating = action.payload;
    },
    setIsDeletingTo: (state, action: PayloadAction<boolean>): void => {
      state.isDeleting = action.payload;
    },
    reset: (_state, _action: PayloadAction): TaskState => initialState,
  },
});

const { reset } = taskSlice.actions;
export const {
  setTask,
  unsetTask,
  setIsCreatingTo,
  setIsUpdatingTo,
  setIsDeletingTo,
} = taskSlice.actions;

export const resetTask = (): AppThunk => async (
  dispatch,
  _getState,
): Promise<void> => {
  dispatch(reset());
};
