import { FC, ReactElement } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { AlertMessage } from 'components';
import { ErrorMessage, WeekDay } from 'core/enums';
import { useSelector } from 'react-redux';
import { selectIsCreating, setIsCreatingTo } from 'redux/schedule';
import { useAppDispatch } from 'redux/store';
import moment from 'moment';
import { User } from 'redux/auth';
import { useCreateSchedule, useCurrentUser } from 'hooks';

export type SlotFormInputs = {
  week_day: WeekDay;
  hour: string;
  minute: string;
  meridiem: string;
};

const noWeekDay = 'NONE';
const schema: yup.SchemaOf<SlotFormInputs> = yup
  .object()
  .shape({
    week_day: yup.string().test('week_day', ErrorMessage.REQUIRED, (value) => {
      if (!value || value === noWeekDay) return false;
      return true;
    }),
    hour: yup
      .string()
      .required(ErrorMessage.REQUIRED)
      .test('range', ErrorMessage.HOUR, (value) => {
        if (!value) return false;
        const i = parseInt(value);
        return i >= 1 && i <= 12;
      }),
  })
  .defined();

export const ScheduleCreate: FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const { register, handleSubmit, errors } = useForm<SlotFormInputs>({
    resolver: yupResolver(schema),
  });
  const isCreating = useSelector(selectIsCreating);
  const { data: user } = useCurrentUser();
  const { isLoading: isSubmitting, isError, mutateAsync } = useCreateSchedule(
    (user as User).uuid,
  );

  const renderModal = (): ReactElement => (
    <Modal show={isCreating} onHide={stopCreating}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Slot</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertMessage visible={isError} />
          <Form.Group controlId="week_day">
            <Form.Label className="required">Day of the Week</Form.Label>
            <Form.Control
              as="select"
              name="week_day"
              ref={register}
              isInvalid={!!errors.week_day}
            >
              <option value={noWeekDay}>Select the day of the week</option>
              <option value={WeekDay.MONDAY}>Monday</option>
              <option value={WeekDay.TUESDAY}>Tuesday</option>
              <option value={WeekDay.WEDNESDAY}>Wednesday</option>
              <option value={WeekDay.THURSDAY}>Thursday</option>
              <option value={WeekDay.FRIDAY}>Friday</option>
              <option value={WeekDay.SATURDAY}>Saturday</option>
              <option value={WeekDay.SUNDAY}>Sunday</option>
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.week_day?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Row>
            <Form.Group as={Col} controlId="slot-hour">
              <Form.Label className="required">Time</Form.Label>
              <Form.Control
                min="1"
                max="12"
                type="number"
                placeholder="H"
                name="hour"
                ref={register}
                isInvalid={!!errors.hour}
              />
              <Form.Text className="text-muted">Hour (1 - 12)</Form.Text>
              <Form.Control.Feedback type="invalid">
                {errors.hour?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId="slot-minute">
              <Form.Label className="text-white">Minute</Form.Label>
              <Form.Control as="select" name="minute" ref={register}>
                <option value="00">00</option>
                <option value="30">30</option>
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} controlId="slot-meridiem">
              <Form.Label className="text-white">Meridiem</Form.Label>
              <Form.Control as="select" name="meridiem" ref={register}>
                <option value="AM">AM</option>
                <option value="PM">PM</option>
              </Form.Control>
            </Form.Group>
          </Form.Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" disabled={isSubmitting} onClick={stopCreating}>
            Cancel
          </Button>
          <Button variant="primary" type="submit" disabled={isSubmitting}>
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );

  const stopCreating = (): void => {
    if (isSubmitting) return;
    dispatch(setIsCreatingTo(false));
  };

  const onSubmit = async (data: SlotFormInputs): Promise<void> => {
    const day = +data.week_day === 0 ? 7 : +data.week_day;
    await mutateAsync({
      date_time: moment(
        `6/${day}/2020 ${data.hour}:${data.minute} ${data.meridiem}`,
        'M/D/YYYY h:mm A',
      ).format('YYYY-M-D HH:mm:00'),
    });
    stopCreating();
  };

  return renderModal();
};

export default ScheduleCreate;
