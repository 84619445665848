import { FC, ReactElement, useEffect } from 'react';
import { PageHeader } from 'components';
import Button from 'react-bootstrap/Button';
import { useAppDispatch } from 'redux/store';
import {
  resetSpecialization,
  selectFullSpecialization,
  setIsCreatingTo,
  setIsDeletingTo,
} from 'redux/specialization';
import {
  SpecializationList,
  SpecializationCreate,
  SpecializationDelete,
} from './components';
import { useSelector } from 'react-redux';

export const SpecializationPage: FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const fullSpecialization = useSelector(selectFullSpecialization);

  useEffect(() => {
    return () => {
      dispatch(resetSpecialization());
    };
  }, [dispatch]);

  const renderMain = (): ReactElement => (
    <>
      <PageHeader
        title="Specializations"
        controls={[
          <Button
            key="delete"
            variant="link"
            size="sm"
            disabled={!fullSpecialization}
            onClick={startDeleting}
          >
            Delete
          </Button>,
          <Button key="add" size="sm" className="ml-2" onClick={startCreating}>
            Add New
          </Button>,
        ]}
      />
      <SpecializationList />
      <SpecializationCreate />
      <SpecializationDelete />
    </>
  );

  const startDeleting = (): void => {
    dispatch(setIsDeletingTo(true));
  };

  const startCreating = (): void => {
    dispatch(setIsCreatingTo(true));
  };

  return renderMain();
};

export default SpecializationPage;
