import React, { FC, ReactElement, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useSelector } from 'react-redux';
import {
  selectIsLearning,
  selectLearningDescription,
  selectLearningTitle,
  unsetLearnSpecialization,
} from 'redux/specialization';
import { useAppDispatch } from 'redux/store';

export const SessionLearn: FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const isLearning = useSelector(selectIsLearning);
  const learningTitle = useSelector(selectLearningTitle);
  const learnDescription = useSelector(selectLearningDescription);

  useEffect(() => {
    return () => {
      dispatch(unsetLearnSpecialization());
    };
  }, [dispatch]);

  const renderMain = (): ReactElement => (
    <Modal show={isLearning} onHide={stopLearning}>
      <Modal.Header closeButton>
        <Modal.Title>{learningTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{learnDescription}</Modal.Body>
      <Modal.Footer>
        <Button variant="link" onClick={stopLearning}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const stopLearning = (): void => {
    dispatch(unsetLearnSpecialization());
  };

  return renderMain();
};

export default SessionLearn;
