import { ReactElement } from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import kidsDonate from 'images/kids-donate.jpg';
import smilingKidsDonate from 'images/smiling-kids-donate.jpg';

export const Donation = (): ReactElement => {
  const renderMain = (): ReactElement => (
    <div className="home__donate">
      <Container className="content">
        <div className="left">
          <h1 className="title">Donate and Volunteer</h1>
          <div className="images">
            <div className="top">
              <img src={kidsDonate} alt="Girl" />
            </div>
            <div className="bottom">
              <img src={smilingKidsDonate} alt="Kids" />
            </div>
          </div>
          <p>
            Help TheraWee to continue its advocacy by offering any service or
            amount of cash.
          </p>
          <p>
            You can also join our team as a volunteer and help us in pushing
            forth our cause.
          </p>
          <div className="actions">
            <Button variant="primary" size="lg" onClick={openFacebookPage}>
              Donate Now
            </Button>
            <Button
              className="ml-3"
              variant="primary"
              size="lg"
              onClick={openVolunteerFormPage}
            >
              Volunteer
            </Button>
          </div>
        </div>
        <div className="right images">
          <div className="top">
            <img src={kidsDonate} alt="Girl" />
          </div>
          <div className="bottom">
            <img src={smilingKidsDonate} alt="Kids" />
          </div>
        </div>
      </Container>
    </div>
  );

  const openFacebookPage = (): void => {
    window.open('https://www.facebook.com/TheraWeeAPP');
  };

  const openVolunteerFormPage = (): void => {
    window.open('https://bit.ly/TheraWeeApp');
  };

  return renderMain();
};

export default Donation;
