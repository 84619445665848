import { FC, ReactElement } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { AlertMessage } from 'components';
import { UserUpdate } from 'api';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { ErrorMessage, Gender, Role } from 'core/enums';
import { useCurrentUser, useUpdateUser } from 'hooks';

export type PersonalInfoFormInputs = {
  first_name: string;
  middle_name: string;
  last_name: string;
  gender: Gender;
  month: string;
  day: string;
  year: string;
  mobile_number: string;
  landline_number: string;
  address: string;
};

const noGender = 'NONE';
const yearMinimum = 1900;
const yearToday = moment().year();
const schema: yup.SchemaOf<PersonalInfoFormInputs> = yup
  .object()
  .shape({
    first_name: yup.string().required(ErrorMessage.REQUIRED),
    last_name: yup.string().required(ErrorMessage.REQUIRED),
    gender: yup.string().test('gender', ErrorMessage.REQUIRED, (value) => {
      if (!value || value === noGender) return false;
      return true;
    }),
    month: yup
      .string()
      .required(ErrorMessage.REQUIRED)
      .test('range', ErrorMessage.MONTH, (value) => {
        if (!value) return false;
        const i = parseInt(value);
        return i >= 1 && i <= 12;
      })
      .test('date', ErrorMessage.DATE, (month, ctx) => {
        const { day, year } = ctx.parent;
        if (!month || !day || !year) return true;
        return moment(`${month}/${day}/${year}`, 'M/D/YYYY').isValid();
      }),
    day: yup
      .string()
      .required(ErrorMessage.REQUIRED)
      .test('range', ErrorMessage.DAY, (value) => {
        if (!value) return false;
        const i = parseInt(value);
        return i >= 1 && i <= 31;
      }),
    year: yup
      .string()
      .required(ErrorMessage.REQUIRED)
      .test(
        'range',
        `Between ${yearMinimum} and ${yearToday} only`,
        (value) => {
          if (!value) return false;
          const i = parseInt(value);
          return i >= yearMinimum && i <= yearToday;
        },
      ),
    mobile_number: yup
      .string()
      .test('mobile_number', ErrorMessage.REQUIRED, (value) => {
        if (!value || value.indexOf('_') !== -1 || value.length < 13)
          return false;
        return true;
      }),
    address: yup.string().required(ErrorMessage.REQUIRED),
  })
  .defined();

export const PersonalInfo: FC = (): ReactElement => {
  const { data: user, isPatientUser, isPersonalInfoStep } = useCurrentUser();
  const { isLoading: isSubmitting, isError, mutateAsync } = useUpdateUser();
  const {
    register,
    handleSubmit,
    control,
    errors,
  } = useForm<PersonalInfoFormInputs>({
    resolver: yupResolver(schema),
  });

  const renderInfo = (): ReactElement => (
    <Card body>
      <h5 className="info-title">Fill in your personal information.</h5>
      <AlertMessage visible={isError} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group controlId="first_name">
          <Form.Label className="required">First Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your first name"
            name="first_name"
            ref={register}
            isInvalid={!!errors.first_name}
          />
          <Form.Control.Feedback type="invalid">
            {errors.first_name?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="middle_name">
          <Form.Label>Middle Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your middle name"
            name="middle_name"
            ref={register}
          />
        </Form.Group>
        <Form.Group controlId="last_name">
          <Form.Label className="required">Last Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your last name"
            name="last_name"
            ref={register}
            isInvalid={!!errors.last_name}
          />
          <Form.Control.Feedback type="invalid">
            {errors.last_name?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="gender">
          <Form.Label className="required">Gender</Form.Label>
          <Form.Control
            as="select"
            placeholder="Select your gender"
            name="gender"
            ref={register}
            isInvalid={!!errors.gender}
          >
            <option value={noGender}>Select your gender</option>
            <option value={Gender.MALE}>Male</option>
            <option value={Gender.FEMALE}>Female</option>
            <option value={Gender.OTHER}>Other</option>
            <option value={Gender.SECRET}>Prefer Not to Say</option>
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.gender?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Row>
          <Form.Group as={Col} controlId="birth-month">
            <Form.Label className="required">Birth Date</Form.Label>
            <Form.Control
              min="1"
              max="12"
              type="number"
              placeholder="MM"
              name="month"
              ref={register}
              isInvalid={!!errors.month}
            />
            <Form.Text className="text-muted">Month (Ex. 12)</Form.Text>
            <Form.Control.Feedback type="invalid">
              {errors.month?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} controlId="birth-day">
            <Form.Label className="text-white">Day</Form.Label>
            <Form.Control
              min="1"
              max="31"
              type="number"
              placeholder="DD"
              name="day"
              ref={register}
              isInvalid={!!errors.day}
            />
            <Form.Text className="text-muted">Day (Ex. 31)</Form.Text>
            <Form.Control.Feedback type="invalid">
              {errors.day?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} controlId="birth-year">
            <Form.Label className="text-white">Year</Form.Label>
            <Form.Control
              min={yearMinimum}
              max={yearToday}
              type="number"
              placeholder="YYYY"
              name="year"
              ref={register}
              isInvalid={!!errors.year}
            />
            <Form.Text className="text-muted">Year (Ex. 1980)</Form.Text>
            <Form.Control.Feedback type="invalid">
              {errors.year?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
        <Form.Group controlId="mobile_number">
          <Form.Label className="required">Mobile Number</Form.Label>
          <Controller
            as={<NumberFormat customInput={Form.Control} />}
            defaultValue=""
            control={control}
            format="09## ### ####"
            mask="_"
            allowEmptyFormatting
            name="mobile_number"
            isInvalid={!!errors.mobile_number}
          />
          <Form.Text className="text-muted">Ex. 0912 345 6789</Form.Text>
          <Form.Control.Feedback type="invalid">
            {errors.mobile_number?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="landline_number">
          <Form.Label>Landline Number</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your landline number"
            name="landline_number"
            ref={register}
          />
        </Form.Group>
        <Form.Group controlId="address">
          <Form.Label className="required">Address</Form.Label>
          <Form.Control
            as="textarea"
            rows={4}
            placeholder="Enter your address"
            name="address"
            ref={register}
            isInvalid={!!errors.address}
          />
          <Form.Control.Feedback type="invalid">
            {errors.address?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Button variant="primary" type="submit" disabled={isSubmitting}>
          {renderSubmitText()}
        </Button>
      </Form>
    </Card>
  );

  const onSubmit = async (data: PersonalInfoFormInputs): Promise<void> => {
    if (!user) {
      return;
    }
    let uu: UserUpdate = {
      ...user,
      ...data,
      birth_date: `${parseInt(data.year)}-${parseInt(data.month)}-${parseInt(
        data.day,
      )}`,
    };
    if (user.role === Role.PHYSICIAN)
      uu = {
        ...uu,
        online_rate: '0',
        reading_rate: '0',
      };
    await mutateAsync(uu);
  };

  const renderSubmitText = (): string => (isPatientUser ? 'Complete' : 'Next');

  if (!isPersonalInfoStep) return <></>;

  return renderInfo();
};

export default PersonalInfo;
