import { Specialization } from 'core/enums';

export {
  getTopSpecializations,
  getSpecializations,
  getSpecializationId,
  getSpecializationLabel,
};

const getTopSpecializations = (): Specialization[] => [
  Specialization.OCCUPATIONAL_THERAPY,
  Specialization.SPEECH_THERAPY,
  Specialization.PHYSICAL_THERAPY,
  Specialization.SPECIAL_EDUCATION,
];

const getSpecializations = (
  topSpecialization?: Specialization,
): Specialization[] => {
  switch (topSpecialization) {
    case Specialization.OCCUPATIONAL_THERAPY:
      return [
        Specialization.SENSORY_INTEGRATION,
        Specialization.BEHAVIOR_MODIFICATION,
        Specialization.COGNITIVE_BEHAVIOR_THERAPY,
        Specialization.FEEDING_THERAPY,
        Specialization.DEVELOPMENTAL_PLAY,
        Specialization.HANDWRITING_WITHOUT_TEARS,
        Specialization.EXECUTIVE_FUNCTION,
        Specialization.BOBATH_THERAPY,
        Specialization.OTHER,
      ];
    case Specialization.SPEECH_THERAPY:
      return [Specialization.FEEDING_THERAPY, Specialization.OTHER];
    case Specialization.PHYSICAL_THERAPY:
      return [Specialization.BOBATH_THERAPY, Specialization.OTHER];
    case Specialization.SPECIAL_EDUCATION:
      return [Specialization.READING_INTERVENTION, Specialization.OTHER];
    default:
      return [];
  }
};

const getSpecializationId = (specialization: Specialization): string =>
  specialization.toLowerCase();

const getSpecializationLabel = (specialization: Specialization): string =>
  specialization
    .toLowerCase()
    .replaceAll('_', ' ')
    .split(' ')
    .map((st): string => st[0].toUpperCase() + st.slice(1))
    .join(' ');

export const specializationWiki: {
  [key in keyof typeof Specialization]: string;
} = {
  [Specialization.OCCUPATIONAL_THERAPY]:
    'Teaching children how to be kids by having fun, participating in everyday activities in home, school, and community.',
  [Specialization.SPEECH_THERAPY]:
    'Helping your child to express themselves appropriately, and understand people, things and events happening around them.',
  [Specialization.PHYSICAL_THERAPY]:
    'Leading your child to explore the environment through functional movement.',
  [Specialization.SPECIAL_EDUCATION]:
    'Developing academic skills needed for school and adaptive skills necessary for them to survive.',
  [Specialization.SENSORY_INTEGRATION]:
    '"My child has sensitivity which affects his activities. At times, he is passive and seem not interested in anything. He also has full of energy days."',
  [Specialization.BEHAVIOR_MODIFICATION]:
    '"I don’t know what to do with my child. He doesn’t comply with me. He usually gets what he wants and has tantrums when he doesn’t."',
  [Specialization.COGNITIVE_BEHAVIOR_THERAPY]:
    '"My child has non-compliance and defiant behavior. Most of the time he has anger management issues."',
  [Specialization.FEEDING_THERAPY]:
    '"He doesn’t know how to eat solid food, picky eater and has occasional choking and difficulty chewing food."',
  [Specialization.DEVELOPMENTAL_PLAY]:
    '"My child looks not interested in toys. I want my child to enjoy his childhood and play with other children."',
  [Specialization.HANDWRITING_WITHOUT_TEARS]:
    '"My baby girl has difficulty writing within the lines and maintaining spaces. At times she has reversals and escape during writing task."',
  [Specialization.EXECUTIVE_FUNCTION]:
    '"We are having difficulty motivating and maintaining his focus during tasks requiring mental effort. He has difficulty organizing and understanding information which often results to limitations in problem solving."',
  [Specialization.BOBATH_THERAPY]:
    '"My baby has difficulty moving her body. Her arms and legs are too tight and uncoordinated."',
  [Specialization.READING_INTERVENTION]:
    '"He is already 5 years old and I’ve been teaching him letters and its sound. He still doesn’t get it. His classmates can read simple words already while he is getting bullied because he can’t read"',
  [Specialization.OTHER]: 'If you are unsure, you may select this option.',
};
