import { ReactElement, ReactNode } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { setIsUpdatingFeedbackLikeTo } from 'redux/session';
import { useAppDispatch } from 'redux/store';
import { useCurrentUser, useSession, useSessionInfoParams } from 'hooks';

export const FeedbackLike = (): ReactElement => {
  const dispatch = useAppDispatch();
  const params = useSessionInfoParams();
  const { isPhysicianUser } = useCurrentUser();
  const { data: sessionView, isPaid } = useSession(
    params.session_uuid,
    params.pat_uuid,
  );

  const renderMain = (): ReactElement => (
    <Card className="mt-3">
      <Card.Body>
        <Card.Title className="card-controls">
          Check In: What did you like the best for today's session?
          {isPhysicianUser && isPaid && (
            <Button
              variant="link"
              className="btn-link-secondary"
              onClick={edit}
            >
              Edit
            </Button>
          )}
        </Card.Title>
        {renderNoFeedbackLike()}
        {sessionView?.feedback_like}
      </Card.Body>
    </Card>
  );

  const edit = (): void => {
    dispatch(setIsUpdatingFeedbackLikeTo(true));
  };

  const renderNoFeedbackLike = (): ReactNode => {
    if (!sessionView?.feedback_like)
      return <Alert variant="info">No answer.</Alert>;
  };

  return renderMain();
};

export default FeedbackLike;
