import { User } from 'redux/auth';
import { CoreService } from '.';
import { Gender, Role } from 'core/enums';

export { UserService };

export type UserCreate = {
  username: string;
  email: string;
  password: string;
  role: Role;
};

export type UserUpdate = {
  username: string;
  email: string;
  role: Role;
  first_name: string;
  middle_name: string;
  last_name: string;
  birth_date?: string;
  address?: string;
  mobile_number?: string;
  landline_number?: string;
  gender?: Gender;
  picture_file?: string;
  online_rate?: string;
  reading_rate?: string;
  zoom?: string;
  zoom_meeting_id?: string;
  zoom_password?: string;
  bank?: string;
  bank_name?: string;
  bank_account?: string;
  prc?: string;
  prc_reg_date?: string;
  prc_val_date?: string;
};

const w = window as any;
w.createUser = null;
w.updateUser = null;
w.changePassword = null;
w.sendEmailVerification = null;

class UserService {
  public static createUser(user: UserCreate): Promise<User> {
    const data: any = {
      params0: user.username,
      params1: user.password,
      params2: user.email,
      params3: '',
      params4: '',
      params5: '',
      params6: '',
      params7: '',
      params8: '',
      params9: Gender.MALE,
      params10: user.role,
      params11: '',
    };
    if (user.role === Role.PHYSICIAN) {
      data.params12 = '0';
      data.params13 = '0';
      data.params14 = '';
      data.params15 = '';
      data.params16 = '';
      data.params17 = '';
    }
    return CoreService.put('/api/cc-service/users/insert', data, 'createUser');
  }

  public static cancelCreateUser(): void {
    CoreService.cancelRequest('createUser');
  }

  public static updateUser(user: UserUpdate): Promise<User> {
    const data: any = {
      params0: user.username,
      params1: user.email,
      params2: user.first_name,
      params3: user.middle_name,
      params4: user.last_name,
      params5: user.birth_date,
      params6: user.address,
      params7: user.mobile_number,
      params8: user.landline_number || '',
      params9: user.gender,
      params10: user.picture_file,
    };
    if (user.role === Role.PHYSICIAN) {
      data.params11 = user.online_rate;
      data.params12 = user.reading_rate;
      data.params13 = user.zoom;
      data.params14 = user.bank_name;
      data.params15 = user.bank;
      data.params16 = user.bank_account;
      data.params17 = user.zoom_password;
      data.params18 = user.zoom_meeting_id;
      data.params19 = user.prc;
      data.params20 = user.prc_reg_date;
      data.params21 = user.prc_val_date;
    }
    return CoreService.post('/api/cc-service/users/update', data, 'updateUser');
  }

  public static cancelUpdateUser(): void {
    CoreService.cancelRequest('updateUser');
  }

  public static changePassword(password: string): Promise<void> {
    return CoreService.post(
      `/api/cc-service/users/change-password/${password}`,
      undefined,
      'changePassword',
    );
  }

  public static cancelChangePassword(): void {
    CoreService.cancelRequest('changePassword');
  }

  public static sendEmailVerification(user_uuid: string): Promise<void> {
    return CoreService.post(
      `/api/cc-service/send-email/${user_uuid}/VERIFY`,
      undefined,
      'sendEmailVerification',
    );
  }

  public static cancelSendEmailVerification(): void {
    CoreService.cancelRequest('sendEmailVerification');
  }
}
