import { FC, ReactElement } from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { setIsPhysicianUpdatingTo } from 'redux/auth';
import { useAppDispatch } from 'redux/store';
import { Role } from 'core/enums';
import { useCurrentUser } from 'hooks';

export const PhysicianInfo: FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const { data: user } = useCurrentUser();

  if (!user || user.role !== Role.PHYSICIAN) return <></>;

  const renderProfile = (): ReactElement => (
    <Card className="mt-3">
      <Card.Body>
        <Card.Title className="card-controls">
          Therapist Info
          <Button
            variant="link"
            className="btn-link-secondary"
            onClick={startUpdating}
          >
            Edit
          </Button>
        </Card.Title>
        <Row>
          <Col md={6}>
            <div className="description-item">
              <span>Online Hourly Rate</span>
              {user.online_rate}
            </div>
          </Col>
          <Col md={6}>
            <div className="description-item">
              <span>Zoom Account URL</span>
              {user.zoom}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <div className="description-item">
              <span>Zoom Meeting ID</span>
              {user.zoom_meeting_id}
            </div>
          </Col>
          <Col md={6}>
            <div className="description-item">
              <span>Zoom Password</span>
              {user.zoom_password}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <div className="description-item">
              <span>Bank Name</span>
              {user.bank}
            </div>
          </Col>
          <Col md={6}>
            <div className="description-item">
              <span>Bank Account Name</span>
              {user.bank_name}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="description-item">
              <span>Bank Account Number</span>
              {user.bank_account}
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );

  const startUpdating = (): void => {
    dispatch(setIsPhysicianUpdatingTo(true));
  };

  return renderProfile();
};

export default PhysicianInfo;
