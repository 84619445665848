import { FC, ReactElement } from 'react';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useAppDispatch } from 'redux/store';
import { resetBooking, selectSuccessPatientUuid } from 'redux/booking';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useCreateSession } from 'hooks';

export const SessionSuccess: FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const successPatientUuid = useSelector(selectSuccessPatientUuid);
  const { reset } = useCreateSession();

  const renderMain = (): ReactElement => (
    <>
      <Alert variant="success" className="mt-3">
        Booked successfully!
      </Alert>
      <Row>
        <Col>
          <Button variant="primary" onClick={redirectToPatientPage}>
            View Patient
          </Button>
          <Button variant="secondary" className="ml-3" onClick={bookAgain}>
            Book Again
          </Button>
        </Col>
      </Row>
    </>
  );

  const redirectToPatientPage = (): void => {
    history.push(`/patients/${successPatientUuid}`);
  };

  const bookAgain = (): void => {
    dispatch(resetBooking());
    reset();
  };

  return renderMain();
};

export default SessionSuccess;
