import { ReactElement } from 'react';
import Container from 'react-bootstrap/Container';
import { Program } from './components';

export const Programs = (): ReactElement => {
  return (
    <div className="home__program">
      <Container className="content">
        <div className="header">
          <h1 className="title">TheraWee’s Teletherapy Program</h1>
          <h4 className="subtitle">How does it work?</h4>
        </div>
        <div className="programs">
          <Program
            number={1}
            title="Screening"
            description="Parents and therapists are screened if they are ready for the
              teletherapy program"
            imgSrc="./programs/screening.svg"
          />
          <Program
            number={2}
            title="Teletherapy Training"
            description="Parents and therapists are both trained on device usage and the
              method of conducting teletherapy"
            imgSrc="./programs/teletherapy_training.svg"
          />
          <Program
            number={3}
            title="Implementation Support"
            description="Parents and therapists are given support in technical and
              teletherapy strategies and techniques"
            imgSrc="./programs/implementation_support.svg"
          />
          <Program
            number={4}
            title="Home Therapy Implementation"
            description="Parents are guided and given therapy homeworks to apply the
              learnings from training at home"
            imgSrc="./programs/home_therapy_implementation.svg"
          />
          <Program
            number={5}
            title="Monitoring Progress"
            description="Therapy progress is constantly monitored and assessed for quality
              delivery and outcome measure"
            imgSrc="./programs/monitoring_progress.svg"
          />
        </div>
      </Container>
    </div>
  );
};

export default Programs;
