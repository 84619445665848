import { useCurrentUser, useScheduleSlots } from 'hooks';
import { FC, ReactElement, ReactNode } from 'react';
import Alert from 'react-bootstrap/Alert';
import { useHistory } from 'react-router-dom';
import { User } from 'redux/auth';

export const ScheduleWarning: FC = (): ReactElement => {
  const history = useHistory();
  const { data: user, isPhysicianUser } = useCurrentUser();
  const { isLoading, data = [] } = useScheduleSlots({
    isEnabled: isPhysicianUser,
    phy_uuid: (user as User).uuid,
  });

  const renderMain = (): ReactElement => (
    <Alert variant="warning">
      You don't have a schedule! Click {renderRedirectButton()} to add.
    </Alert>
  );

  const renderRedirectButton = (): ReactNode => (
    <a href="/schedule" onClick={redirectToSchedulePage}>
      here
    </a>
  );

  const redirectToSchedulePage = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ): void => {
    event.preventDefault();
    history.push('/schedule');
  };

  if (!isPhysicianUser || isLoading || !!data.length) return <></>;

  return renderMain();
};

export default ScheduleWarning;
