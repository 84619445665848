import { FC, ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { PatientView, PatientUpdate, SessionList } from './components';
import { PageHeader } from 'components';

export const PatientPage: FC = (): ReactElement => {
  const params = useParams<{ uuid: string }>();

  return (
    <>
      <PageHeader title="Patient Profile" />
      <PatientView uuid={params.uuid} />
      <PatientUpdate uuid={params.uuid} />
      <SessionList uuid={params.uuid} />
    </>
  );
};

export default PatientPage;
