import React, { ReactElement, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { AlertMessage } from 'components';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { setIsGcashPayingTo, selectIsGcashPaying } from 'redux/session';
import { useAppDispatch } from 'redux/store';
import { ErrorMessage, PaymentMethod } from 'core/enums';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { PaymentSource } from 'redux/payment';
import {
  useCreateGcashSource,
  useCreateGrabPaySource,
  useSession,
  useSessionInfoParams,
} from 'hooks';

export type PaymentForm = {
  amount: string;
  payment_method: PaymentMethod;
};

const noMethod = 'NONE';
const schema: yup.SchemaOf<PaymentForm> = yup
  .object()
  .shape({
    payment_method: yup
      .string()
      .test('payment_method', ErrorMessage.REQUIRED, (value) => {
        if (!value || value === noMethod) return false;
        return true;
      }),
  })
  .defined();

export const Payment = (): ReactElement => {
  const dispatch = useAppDispatch();
  const params = useSessionInfoParams();
  const {
    isLoading: isSubmittingGcash,
    isError: isGcashError,
    mutateAsync: createGcashSource,
  } = useCreateGcashSource();
  const {
    isLoading: isSubmittingGrab,
    isError: isGrabError,
    mutateAsync: createGrabSource,
  } = useCreateGrabPaySource();
  const { register, unregister, errors, handleSubmit, setValue } =
    useForm<PaymentForm>({
      resolver: yupResolver(schema),
    });
  const isUpdatingGcash = useSelector(selectIsGcashPaying);
  const { data: sessionView } = useSession(
    params.session_uuid,
    params.pat_uuid,
  );

  useEffect(() => {
    return () => {
      unregister(['amount', 'payment_method']);
    };
  }, [unregister]);

  useEffect(() => {
    if (!isUpdatingGcash || !sessionView) return;
    setValue('amount', sessionView?.online_rate);
  }, [isUpdatingGcash, sessionView, setValue]);

  const renderModal = (): ReactElement => (
    <Modal show={isUpdatingGcash} onHide={stopUpdating}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>Pay</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertMessage visible={isGcashError || isGrabError} />
          <Form.Group controlId="amount">
            <Form.Label>Amount</Form.Label>
            <Form.Control readOnly name="amount" ref={register} />
          </Form.Group>
          <Form.Group controlId="payment_method">
            <Form.Label className="required">Payment Method</Form.Label>
            <Form.Control
              as="select"
              name="payment_method"
              ref={register}
              isInvalid={!!errors.payment_method}
            >
              <option value={noMethod}>Select payment method</option>
              <option value={PaymentMethod.GCASH}>GCash</option>
              <option value={PaymentMethod.GRAB_PAY}>GrabPay</option>
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.payment_method?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            disabled={isSubmittingGcash || isSubmittingGrab}
            onClick={stopUpdating}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            disabled={isSubmittingGcash || isSubmittingGrab}
          >
            Proceed
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );

  const stopUpdating = (): void => {
    if (isSubmittingGcash || isSubmittingGrab) return;
    dispatch(setIsGcashPayingTo(false));
  };

  const onSubmit = async (data: PaymentForm): Promise<void> => {
    if (!sessionView) {
      return;
    }
    let response: PaymentSource | undefined;
    if (data.payment_method === PaymentMethod.GCASH)
      response = await createGcashSource({
        booking_uuid: sessionView.uuid,
        amount: sessionView.online_rate,
        appointment_id: sessionView.appointment_id,
      });
    else if (data.payment_method === PaymentMethod.GRAB_PAY)
      response = await createGrabSource({
        booking_uuid: sessionView.uuid,
        amount: sessionView.online_rate,
        appointment_id: sessionView.appointment_id,
      });
    if (!!response) window.location.href = response.checkout_url;
  };

  return renderModal();
};

export default Payment;
