import { FC, ReactElement } from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { useCurrentUser } from 'hooks';

export const AnonymousRoute: FC<RouteProps> = (props): ReactElement => {
  const {
    isLoading,
    isAuthenticated,
    isRegistrationStepsCompleted,
  } = useCurrentUser();

  const renderRoute = (): ReactElement => {
    if (isLoading) return <>Loading... Please Wait</>;
    if (isAuthenticated) {
      if (isRegistrationStepsCompleted) return redirectToDashboardPage();
      return redirectToUserSetupPage();
    }
    return renderPage();
  };

  const redirectToDashboardPage = (): ReactElement => (
    <Redirect to="/dashboard" />
  );

  const redirectToUserSetupPage = (): ReactElement => (
    <Redirect to="/users/setup" />
  );

  const renderPage = (): ReactElement => <Route {...props} />;

  return renderRoute();
};

export default AnonymousRoute;
