import { PatientCreate, PatientService, PatientUpdate } from 'api';
import { AxiosError } from 'axios';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { Patient } from 'redux/patient';

export const usePatients = (user_uuid?: string): UseQueryResult<Patient[]> => {
  return useQuery(
    ['patients', user_uuid],
    () => PatientService.getPatients(user_uuid || ''),
    {
      enabled: !!user_uuid,
    },
  );
};

export const usePatient = (
  pat_uuid: string,
  user_uuid?: string,
): UseQueryResult<Patient> => {
  const queryClient = useQueryClient();
  return useQuery(
    ['patient', pat_uuid],
    () => PatientService.getPatient(pat_uuid),
    {
      placeholderData: () => {
        const foundPatient = queryClient
          .getQueryData<Patient[]>(['patients', user_uuid])
          ?.find((patient) => patient.uuid === pat_uuid);
        return foundPatient;
      },
    },
  );
};

export const useCreatePatient = (
  user_uuid: string,
): UseMutationResult<void, AxiosError, PatientCreate> => {
  const queryClient = useQueryClient();
  return useMutation(PatientService.createPatient, {
    onSuccess: () => {
      queryClient.invalidateQueries(['patients', user_uuid]);
    },
  });
};

export const useUpdatePatient = (
  user_uuid: string,
): UseMutationResult<void, AxiosError, PatientUpdate> => {
  const queryClient = useQueryClient();
  return useMutation(PatientService.updatePatient, {
    onSuccess: () => {
      queryClient.invalidateQueries(['patient', user_uuid]);
    },
  });
};
