import React, { ReactElement, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { AlertMessage } from 'components';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  setIsUpdatingRecommendationTo,
  selectIsUpdatingRecommendation,
} from 'redux/session';
import { useAppDispatch } from 'redux/store';
import { useSession, useUpdateSession, useSessionInfoParams } from 'hooks';

export type RecommendationForm = {
  recommendation: string;
};

export const RecommendationUpdate = (): ReactElement => {
  const dispatch = useAppDispatch();
  const params = useSessionInfoParams();
  const { register, handleSubmit, setValue } = useForm<RecommendationForm>();
  const isUpdatingRecommendation = useSelector(selectIsUpdatingRecommendation);
  const { data: sessionView } = useSession(
    params.session_uuid,
    params.pat_uuid,
  );
  const {
    isLoading: isSubmitting,
    isError: isSubmitError,
    mutateAsync,
  } = useUpdateSession(params.session_uuid);

  useEffect(() => {
    if (!isUpdatingRecommendation || !sessionView) return;
    setValue('recommendation', sessionView.therapy_recommendation);
  }, [isUpdatingRecommendation, sessionView, setValue]);

  const renderModal = (): ReactElement => (
    <Modal show={isUpdatingRecommendation} onHide={stopUpdating}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Recommendation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertMessage visible={isSubmitError} />
          <Form.Group controlId="recommendation">
            <Form.Label>Recommendation for Professionals</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              placeholder="Enter recommendation"
              name="recommendation"
              ref={register}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" disabled={isSubmitting} onClick={stopUpdating}>
            Cancel
          </Button>
          <Button variant="primary" type="submit" disabled={isSubmitting}>
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );

  const stopUpdating = (): void => {
    if (isSubmitting) return;
    dispatch(setIsUpdatingRecommendationTo(false));
  };

  const onSubmit = async (data: RecommendationForm): Promise<void> => {
    if (!sessionView) {
      return;
    }
    await mutateAsync({
      booking_uuid: sessionView.uuid,
      health_query: sessionView.health_query,
      specialization: sessionView.specialization,
      physician_uuid: sessionView.physician,
      feedback_like: sessionView.feedback_like,
      feedback_improve: sessionView.feedback_improve,
      therapy_homework: sessionView.therapy_homework,
      therapy_recommendation: data.recommendation,
    });
    stopUpdating();
  };

  return renderModal();
};

export default RecommendationUpdate;
