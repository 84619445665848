import { FC, ReactElement, ReactNode, ReactNodeArray } from 'react';

export interface PageHeaderProps {
  title: string;
  controls?: ReactNodeArray;
}

export const PageHeader: FC<PageHeaderProps> = (props): ReactElement => {
  const { title, controls } = props;

  const renderHeader = (): ReactElement => (
    <div className="page-header">
      <div className="page-title">
        <h3>{title}</h3>
      </div>
      <div className="page-controls">{renderControls()}</div>
    </div>
  );

  const renderControls = (): ReactNode => {
    if (!!controls) return controls.map((c): ReactNode => c);
  };

  return renderHeader();
};

export default PageHeader;
