import { FC, ReactElement } from 'react';
import { WeeklySchedule, ScheduleCreate, ScheduleDelete } from './components';
import { PageHeader } from 'components';
import Button from 'react-bootstrap/Button';
import { useAppDispatch } from 'redux/store';
import { selectDay, setIsCreatingTo, setIsDeletingTo } from 'redux/schedule';
import { useSelector } from 'react-redux';

export const SchedulePage: FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const day = useSelector(selectDay);

  const renderPage = (): ReactElement => (
    <>
      <PageHeader
        title="Schedule"
        controls={[
          <Button
            key="delete"
            variant="link"
            size="sm"
            disabled={!day}
            onClick={startDeleting}
          >
            Delete
          </Button>,
          <Button key="add" size="sm" className="ml-2" onClick={startCreating}>
            Add New
          </Button>,
        ]}
      />
      <WeeklySchedule />
      <ScheduleCreate />
      <ScheduleDelete />
    </>
  );

  const startDeleting = (): void => {
    dispatch(setIsDeletingTo(true));
  };

  const startCreating = (): void => {
    dispatch(setIsCreatingTo(true));
  };

  return renderPage();
};

export default SchedulePage;
