import React, { FC, ReactElement } from 'react';
import {
  Header,
  About,
  Services,
  Programs,
  LearnMore,
  Donation,
  Partners,
  Partnership,
  Faq,
  Footer,
} from './components';

export const HomePage: FC = (): ReactElement => {
  return (
    <>
      <Header />
      <About />
      <Services />
      <Programs />
      <LearnMore />
      <Donation />
      <Partners />
      <Partnership />
      <Faq />
      <Footer />
    </>
  );
};

export default HomePage;
