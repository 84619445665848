import { FC, HTMLAttributes, ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import { ReturnHomeButton } from 'components';
import Container from 'react-bootstrap/Container';
import logoLight from 'images/therawee-logo-light-compressed.png';
import logoDark from 'images/therawee-logo-dark-compressed.png';
import { useBackgroundOnScroll } from 'hooks';

export interface TopbarPublicProps extends HTMLAttributes<HTMLDivElement> {
  variant?: 'light' | 'dark';
}

export const TopbarPublic: FC<TopbarPublicProps> = (props): ReactElement => {
  const { id = 'topbar-public', variant = 'light' } = props;
  const homeUrl = '/';
  useBackgroundOnScroll(`#${id}`);

  return (
    <nav id={id} className="topbar topbar-public">
      <Container className="content">
        <NavLink to={homeUrl}>
          <img
            src={variant === 'light' ? logoLight : logoDark}
            alt="TheraWee Logo"
            className="logo"
          />
        </NavLink>
        <ReturnHomeButton variant={`outline-${variant}`} />
      </Container>
    </nav>
  );
};

export default TopbarPublic;
