import { FC, ReactElement, ReactNode } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { removeSession, selectSessions, Session } from 'redux/booking';
import { useAppDispatch } from 'redux/store';
import { useSelector } from 'react-redux';

export interface SessionHeaderProps {
  session: Session;
}

export const SessionHeader: FC<SessionHeaderProps> = (props): ReactElement => {
  const { session } = props;
  const dispatch = useAppDispatch();
  const sessions = useSelector(selectSessions);

  const renderHeader = (): ReactElement => (
    <Card.Title className="card-controls">
      #{session.id} Schedule
      {renderRemoveButton()}
    </Card.Title>
  );

  const renderRemoveButton = (): ReactNode => {
    if (sessions.length > 1)
      return (
        <Button variant="link" className="btn-link-secondary" onClick={remove}>
          Remove
        </Button>
      );
  };

  const remove = (): void => {
    dispatch(removeSession(session.id));
  };

  return renderHeader();
};

export default SessionHeader;
