import { RootState } from 'redux/store';
import { AuthState } from '.';
import { createSelector } from '@reduxjs/toolkit';

export {
  selectAuth,
  selectIsUpdating,
  selectIsPasswordChanging,
  selectIsPhysicianUpdating,
  selectIsLicenseUpdating,
};

const selectAuth = (state: RootState): AuthState => state.auth;

const selectIsUpdating = createSelector(
  selectAuth,
  (a): boolean => a.isUpdating,
);

const selectIsPasswordChanging = createSelector(
  selectAuth,
  (a): boolean => a.isPasswordChanging,
);

const selectIsPhysicianUpdating = createSelector(
  selectAuth,
  (a): boolean => a.isPhysicianUpdating,
);

const selectIsLicenseUpdating = createSelector(
  selectAuth,
  (a): boolean => a.isLicenseUpdating,
);
