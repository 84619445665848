import React, { FC, ReactElement, ReactNodeArray } from 'react';
import { PageHeader } from 'components';
import {
  PatientCreate,
  ScheduleWarning,
  PatientList,
  SessionCalendar,
  SpecializationWarning,
  SessionList,
} from './components';
import Button from 'react-bootstrap/Button';
import { useSelector } from 'react-redux';
import { Role } from 'core/enums';
import { selectHasMergedSession, setIsViewingTo } from 'redux/session';
import { useAppDispatch } from 'redux/store';
import { useCurrentUser } from 'hooks';

export const DashboardPage: FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const { data: user } = useCurrentUser();
  const hasMergedSession = useSelector(selectHasMergedSession);
  const controls: ReactNodeArray = [];

  if (user?.role === Role.PHYSICIAN) {
    const showCell = (): void => {
      dispatch(setIsViewingTo(true));
    };

    controls.push(
      <Button
        key="view"
        size="sm"
        disabled={!hasMergedSession}
        onClick={showCell}
      >
        View
      </Button>,
    );
  }

  return (
    <>
      <PageHeader title="Dashboard" controls={controls} />
      <ScheduleWarning />
      <SpecializationWarning />
      <PatientList />
      <PatientCreate />
      <SessionCalendar />
      <SessionList />
    </>
  );
};

export default DashboardPage;
