import React, { ReactElement } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { AlertMessage } from 'components';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { selectIsCancelling, setIsCancellingTo } from 'redux/session';
import { useAppDispatch } from 'redux/store';
import { useCancelSession, useSession, useSessionInfoParams } from 'hooks';

export const SessionCancel = (): ReactElement => {
  const dispatch = useAppDispatch();
  const params = useSessionInfoParams();
  const { handleSubmit } = useForm();
  const isCancelling = useSelector(selectIsCancelling);
  const { data: sessionView } = useSession(
    params.session_uuid,
    params.pat_uuid,
  );

  const {
    isLoading: isPaying,
    isError: isPaymentError,
    mutateAsync,
  } = useCancelSession(params.session_uuid);

  const renderModal = (): ReactElement => (
    <Modal
      backdrop="static"
      keyboard={false}
      show={isCancelling}
      onHide={stopCancelling}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Session</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertMessage visible={isPaymentError} />
          Are you sure you want to cancel this session?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" disabled={isPaying} onClick={stopCancelling}>
            Cancel
          </Button>
          <Button variant="primary" type="submit" disabled={isPaying}>
            Yes
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );

  const stopCancelling = (): void => {
    if (isPaying) return;
    dispatch(setIsCancellingTo(false));
  };

  const onSubmit = async (): Promise<void> => {
    if (!sessionView) {
      return;
    }
    await mutateAsync(sessionView.uuid);
    stopCancelling();
  };

  return renderModal();
};

export default SessionCancel;
