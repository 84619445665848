import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RecurOption, Specialization } from 'core/enums';
import moment from 'moment';
import { BookingState, CompleteSessionPayload, Session } from '.';

const initialSession: Session = {
  id: 1,
  isComplete: false,
  consultation_dates: [],
  topSpecialization: Specialization.OTHER,
  specializations: [],
  physician: undefined,
  recur_option: RecurOption.NONE,
  repetition: '',
};
const initialState: BookingState = {
  physicianName: '',
  consultationDate: '',
  recur_option: RecurOption.NONE,
  sessions: [initialSession],
  success_patient_uuid: undefined,
};

export const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    setPhysicianName: (state, action: PayloadAction<string>): void => {
      state.physicianName = action.payload;
    },
    setConsultationDate: (state, action: PayloadAction<string>): void => {
      state.consultationDate = action.payload;
    },
    setRecurOption: (state, action: PayloadAction<RecurOption>): void => {
      state.recur_option = action.payload;
    },
    addMoreSession: (state, _action: PayloadAction): void => {
      const { sessions: ss } = state;
      const id = ss.length + 1;
      const s = { ...initialSession, id };
      state.sessions.push(s);
    },
    completeSession: (
      state,
      action: PayloadAction<CompleteSessionPayload>,
    ): void => {
      const {
        session: s,
        consultation_date: cd,
        topSpecialization: tp,
        specializations: sps,
        physician: p,
        repetition,
      } = action.payload;
      const cds: string[] = [];
      cds.push(cd);
      if (state.recur_option !== RecurOption.NONE && !!repetition) {
        for (let i = 2; i <= +repetition; i++) {
          let x: moment.DurationInputArg2;
          let toAdd: number;
          switch (state.recur_option) {
            case RecurOption.WEEKLY:
              x = 'week';
              toAdd = 1;
              break;
            case RecurOption.OTHER_WEEK:
              x = 'week';
              toAdd = 2;
              break;
            default:
              x = 'millisecond';
              toAdd = 0;
              break;
          }
          cds.push(
            moment(cds[cds.length - 1], 'YYYY-MM-DD HH:mm:00')
              .add(toAdd, x)
              .format('YYYY-MM-DD HH:mm:00'),
          );
        }
      }
      const ns: Session = {
        ...s,
        isComplete: true,
        consultation_dates: cds,
        topSpecialization: tp,
        specializations: sps,
        physician: p,
        recur_option: state.recur_option,
        repetition,
      };
      state.sessions[s.id - 1] = ns;
      state.recur_option = RecurOption.NONE;
    },
    removeSession: (state, action: PayloadAction<number>): void => {
      state.sessions.splice(action.payload - 1, 1);
      state.sessions.forEach((s, k): void => {
        s.id = k + 1;
      });
    },
    setToSuccess: (state, action: PayloadAction<string>): void => {
      state.success_patient_uuid = action.payload;
    },
    resetBooking: (_state, _action: PayloadAction): BookingState =>
      initialState,
  },
});

export const {
  setPhysicianName,
  setConsultationDate,
  setRecurOption,
  addMoreSession,
  completeSession,
  removeSession,
  setToSuccess,
  resetBooking,
} = bookingSlice.actions;
