import { FC, ReactElement, ReactNode, ReactNodeArray, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { UseFormMethods } from 'react-hook-form';
import { BookingInfoFormInputs } from 'pages/booking-page/BookingPage';
import { useAppDispatch } from 'redux/store';
import { resetPatient } from 'redux/patient';
import queryParam from 'query-string';
import { useHistory } from 'react-router-dom';
import { toFullName } from 'utils/user';
import { useCurrentUser, usePatients } from 'hooks';

export const PatientSection: FC<{
  form: UseFormMethods<BookingInfoFormInputs>;
}> = (props): ReactElement => {
  const { form } = props;
  const { register, errors, setValue } = form;
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { data: user } = useCurrentUser();
  const { data: patients = [] } = usePatients(user?.uuid);

  useEffect(() => {
    return () => {
      dispatch(resetPatient());
    };
  }, [dispatch]);

  useEffect(() => {
    const q = queryParam.parse(history.location.search) as {
      patient_uuid?: string;
    };
    if (!!q.patient_uuid) setValue('patient', q.patient_uuid);
  }, [dispatch, setValue, history, patients]);

  const renderSection = (): ReactElement => (
    <Card body className="mt-3">
      <Form.Group controlId="patient">
        <Form.Label className="required">Patient</Form.Label>
        <Form.Control
          as="select"
          placeholder="Select your patient"
          name="patient"
          ref={register}
          isInvalid={!!errors.patient}
        >
          <option value="">Select a patient</option>
          {renderOptions()}
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          {errors.patient?.message}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="health_query">
        <Form.Label className="required">
          Chief Complaint (Health Concerns)
        </Form.Label>
        <Form.Control
          as="textarea"
          rows={4}
          placeholder="Write something about your concerns..."
          name="health_query"
          ref={register}
          isInvalid={!!errors.health_query}
        />
        <Form.Control.Feedback type="invalid">
          {errors.health_query?.message}
        </Form.Control.Feedback>
      </Form.Group>
    </Card>
  );

  const renderOptions = (): ReactNodeArray =>
    patients.map(
      (p): ReactNode => (
        <option key={p.uuid} value={p.uuid}>
          {toFullName(p)}
        </option>
      ),
    );

  return renderSection();
};

export default PatientSection;
