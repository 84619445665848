import { FC, ReactElement, ReactNode, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { AlertMessage } from 'components';
import { useSelector } from 'react-redux';
import {
  selectIsCreating,
  setIsCreatingTo,
  selectTopSpecializations,
  setTopSpecialization,
  selectSpecializations,
  setSpecialization,
  resetSelection,
  resetSpecializationCreate,
  selectIsOtherSpecialization,
} from 'redux/specialization';
import { useAppDispatch } from 'redux/store';
import { ErrorMessage, Specialization } from 'core/enums';
import moment from 'moment';
import { User } from 'redux/auth';
import { useCreateSpecialization, useCurrentUser } from 'hooks';

export type SpecializationFormInputs = {
  service: Specialization;
  specialization: Specialization;
  other_specialization: string;
  level: string;
  accredited_by: string;
  month: string;
  year: string;
};

const noSelected = '';
const yearMinimum = 1900;
const today = moment('0:00', 'H:mm');
const yearToday = today.year();

export const SpecializationCreate: FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const { data: user } = useCurrentUser();
  const isCreating = useSelector(selectIsCreating);
  const topSpecializations = useSelector(selectTopSpecializations);
  const specializations = useSelector(selectSpecializations);
  const isOtherSpecialization = useSelector(selectIsOtherSpecialization);
  const schemaShape: any = {
    service: yup.string().required(ErrorMessage.REQUIRED),
    specialization: yup.string().required(ErrorMessage.REQUIRED),
    level: yup.string().required(ErrorMessage.REQUIRED),
    accredited_by: yup.string().required(ErrorMessage.REQUIRED),
    month: yup
      .string()
      .required(ErrorMessage.REQUIRED)
      .test(
        'date',
        `date must be ${today.format('M YYYY')} or earlier`,
        (month, ctx) => {
          const { year } = ctx.parent;
          if (!month || !year) return true;
          const completed = moment(
            `${month}/${today.format('D')}/${year}`,
            'M/D/YYYY',
          );
          return completed.diff(today, 'months') <= 0;
        },
      ),
    year: yup.string().required(ErrorMessage.REQUIRED),
  };
  if (isOtherSpecialization)
    schemaShape.other_specialization = yup
      .string()
      .required(ErrorMessage.REQUIRED);
  const schema: yup.SchemaOf<SpecializationFormInputs> = yup
    .object()
    .shape(schemaShape)
    .defined();
  const {
    register,
    handleSubmit,
    unregister,
    reset,
    errors,
  } = useForm<SpecializationFormInputs>({
    resolver: yupResolver(schema),
  });
  const { isLoading, isError, mutateAsync } = useCreateSpecialization(
    (user as User).uuid,
  );

  useEffect(() => {
    return () => {
      dispatch(resetSpecializationCreate());
      dispatch(resetSelection());
      unregister([
        'service',
        'specialization',
        'other_specialization',
        'level',
        'accredited_by',
        'month',
        'year',
      ]);
    };
  }, [dispatch, unregister]);

  const renderModal = (): ReactElement => (
    <Modal show={isCreating} onHide={stopCreating}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Specialization</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertMessage visible={isError} />
          <Form.Group controlId="service">
            <Form.Label className="required">Service</Form.Label>
            <Form.Control
              as="select"
              name="service"
              ref={register}
              isInvalid={!!errors.service}
              onChange={setTopSpecializationState}
            >
              <option value={noSelected}>Select service</option>
              {topSpecializations.map(
                (ts): ReactNode => (
                  <option key={ts.id} value={ts.type}>
                    {ts.label}
                  </option>
                ),
              )}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.service?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="specialization">
            <Form.Label className="required">Specialization</Form.Label>
            <Form.Control
              as="select"
              name="specialization"
              ref={register}
              isInvalid={!!errors.specialization}
              onChange={setSubSpecializationState}
            >
              <option value={noSelected}>Select specialization</option>
              {specializations.map(
                (s): ReactNode => (
                  <option key={s.id} value={s.type}>
                    {s.label}
                  </option>
                ),
              )}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.specialization?.message}
            </Form.Control.Feedback>
          </Form.Group>
          {isOtherSpecialization && (
            <Form.Group controlId="other_specialization">
              <Form.Label className="required">Other specialization</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter other specialization"
                name="other_specialization"
                ref={register}
                isInvalid={!!errors.other_specialization}
              />
              <Form.Control.Feedback type="invalid">
                {errors.other_specialization?.message}
              </Form.Control.Feedback>
            </Form.Group>
          )}
          <Form.Group controlId="level">
            <Form.Label className="required">Level</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter level"
              name="level"
              ref={register}
              isInvalid={!!errors.level}
            />
            <Form.Control.Feedback type="invalid">
              {errors.level?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="accredited_by">
            <Form.Label className="required">Accredited By</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter accredited by"
              name="accredited_by"
              ref={register}
              isInvalid={!!errors.accredited_by}
            />
            <Form.Control.Feedback type="invalid">
              {errors.accredited_by?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Row>
            <Form.Group as={Col} controlId="completion-month">
              <Form.Label className="required">Completed On</Form.Label>
              <Form.Control
                min="1"
                max="12"
                type="number"
                placeholder="MM"
                name="month"
                ref={register}
                isInvalid={!!errors.month}
              />
              <Form.Text className="text-muted">Month (Ex. 12)</Form.Text>
              <Form.Control.Feedback type="invalid">
                {errors.month?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId="completion-year">
              <Form.Label className="text-white">Year</Form.Label>
              <Form.Control
                min={yearMinimum}
                max={yearToday}
                type="number"
                placeholder="YYYY"
                name="year"
                ref={register}
                isInvalid={!!errors.year}
              />
              <Form.Text className="text-muted">Year (Ex. 1980)</Form.Text>
              <Form.Control.Feedback type="invalid">
                {errors.year?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" disabled={isLoading} onClick={stopCreating}>
            Cancel
          </Button>
          <Button variant="primary" type="submit" disabled={isLoading}>
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );

  const onSubmit = async (data: SpecializationFormInputs): Promise<void> => {
    await mutateAsync({
      ...data,
      therapy: data.service,
      completed_on: `${data.year}-${data.month}-01`,
    });
    stopCreating();
  };

  const setTopSpecializationState = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ): void => {
    dispatch(setTopSpecialization(event.target.value as Specialization));
  };

  const setSubSpecializationState = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ): void => {
    dispatch(setSpecialization(event.target.value as Specialization));
  };

  const stopCreating = (): void => {
    if (isLoading) return;
    dispatch(setIsCreatingTo(false));
    reset();
  };

  return renderModal();
};

export default SpecializationCreate;
