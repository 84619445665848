import { ReactElement } from 'react';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';

export const Partnership = (): ReactElement => {
  const history = useHistory();

  const renderMain = (): ReactElement => (
    <div className="home__partnership">
      <div className="content">
        <Button
          className="mb-2 action"
          variant="primary"
          size="lg"
          onClick={redirectToContactPage}
        >
          Be Our Partner
        </Button>
        <strong>Provide Quality Therapy Service</strong>
        <p>
          Be a qualified remote therapy service provider and access our training
          and teletherapy tools for leveraged service.
        </p>
      </div>
    </div>
  );

  const redirectToContactPage = (): void => {
    history.push('/contact');
  };

  return renderMain();
};

export default Partnership;
