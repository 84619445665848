import { FC, ReactElement } from 'react';

export interface ServiceProps {
  title: string;
  description: string;
  imgSrc: string;
}

export const Service: FC<ServiceProps> = (props): ReactElement => {
  const { title, description, imgSrc } = props;

  return (
    <div className="service">
      <img src={imgSrc} alt={title} />
      <h5>{title}</h5>
      <p>{description}</p>
    </div>
  );
};

export default Service;
