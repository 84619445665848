import { FC, ReactElement, ReactNode } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useAppDispatch } from 'redux/store';
import {
  BookedSession,
  selectIsViewing,
  selectPatientsSession,
  setIsViewingTo,
} from 'redux/session';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { StatusBadge } from 'components';

export const SessionList: FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const isViewing = useSelector(selectIsViewing);
  const patientsSession = useSelector(selectPatientsSession);

  const renderMain = (): ReactElement => (
    <Modal size="lg" show={isViewing} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {moment(patientsSession?.consultation_date).format(
            'MMM. DD, YYYY h:mm A',
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="summary-sessions">{renderSessions()}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const handleClose = (): void => {
    dispatch(setIsViewingTo(false));
  };

  const renderSessions = (): ReactNode =>
    patientsSession?.patients.map(
      (p, k): ReactNode => (
        <div key={k} className="summary-session">
          <Row>
            <Col md={6}>
              <div className="description-item">
                <span>Patient</span>
                {p.patient_name}
              </div>
            </Col>
            <Col md={6}>
              <div className="description-item">
                <span>Date &amp; Time</span>
                {moment(p.consultation_date).format(
                  'ddd. MMM. DD, YYYY h:mm A',
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="description-item">
                <span>Chief Complaint (Health Concerns)</span>
                {p.health_query}
              </div>
            </Col>
            <Col md={6}>
              <div className="description-item">
                <span>Diagnosis</span>
                {p.diagnosis}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="description-item">
                <span>Therapist</span>
                {p.physician_name}
              </div>
            </Col>
            <Col md={6}>
              <div className="description-item">
                <span>Status</span>
                <StatusBadge status={p.status} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="text-center actions">
              <Button
                variant="link"
                onClick={() => redirectToSessionInfoPage(p)}
              >
                View More
              </Button>
            </Col>
          </Row>
        </div>
      ),
    );

  const redirectToSessionInfoPage = (bookedSession: BookedSession): void => {
    history.push(
      `/patients/${bookedSession.patient}/sessions/${bookedSession.uuid}`,
    );
  };

  return renderMain();
};

export default SessionList;
