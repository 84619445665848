import { FC, ReactElement, ReactNode, useEffect } from 'react';
import Card, { CardProps } from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import { UseFormMethods } from 'react-hook-form';
import { BookingInfoFormInputs } from 'pages/booking-page/BookingPage';
import moment from 'moment';
import { getAccountImage } from 'utils/profile';
import {
  completeSession,
  selectConsultationDate,
  selectHasRecurOption,
  selectPhysicianName,
  Session,
  setConsultationDate,
  setPhysicianName,
  setRecurOption,
} from 'redux/booking';
import {
  resetSelection,
  selectMergedSpecialization,
  selectSubSpecializations,
  selectTopSpecialization,
} from 'redux/specialization';
import { SessionHeader, SpecializationSection } from '..';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { Physician } from 'redux/physician';
import { RecurOption } from 'core/enums';
import { getRecurOptionLabel } from 'utils/recurring';
import { toFullName } from 'utils/user';
import { useDebounce, usePhysicians } from 'hooks';

export interface NewSessionProps extends CardProps {
  form: UseFormMethods<BookingInfoFormInputs>;
  session: Session;
}

export const NewSession: FC<NewSessionProps> = (props): ReactElement => {
  const { form, session, ...rest } = props;
  const { register, getValues, setValue, setError, watch, errors } = form;
  const dispatch = useAppDispatch();
  const topSpecialization = useSelector(selectTopSpecialization);
  const subSpecializations = useSelector(selectSubSpecializations);
  const mergedSpecialization = useSelector(selectMergedSpecialization);
  const physicianName = useSelector(selectPhysicianName);
  const consultationDate = useSelector(selectConsultationDate);
  const hasRecurOption = useSelector(selectHasRecurOption);
  const watchPhysician = watch('physician');
  const debouncedPhysician = useDebounce(watchPhysician);
  const watchFields = watch(['date', 'hour', 'minute', 'meridiem']);
  const { data: physicians = [] } = usePhysicians({
    isEnabled: !!mergedSpecialization && !!consultationDate,
    name: physicianName,
    specialization: mergedSpecialization as string,
    consultation_date: consultationDate,
  });

  useEffect(() => {
    return () => {
      dispatch(resetSelection());
    };
  }, [dispatch]);

  useEffect((): void => {
    let cd = '';
    const { date, hour, minute, meridiem } = watchFields;
    if (!!date && !!hour && !!minute && !!meridiem) {
      const m = moment(
        `${date} ${parseInt(hour)}:${minute} ${meridiem}`,
        'YYYY-MM-DD h:mm A',
      );
      let d = m.day();
      if (d === 0) d = 7;
      cd = m.format(`2020-06-${d} HH:mm:00`);
    }
    dispatch(setPhysicianName(debouncedPhysician));
    dispatch(setConsultationDate(cd));
  }, [dispatch, debouncedPhysician, watchFields]);

  const renderSection = (): ReactElement => (
    <Card className="mt-3" {...rest}>
      <Card.Body>
        <SessionHeader session={session} />
        <SpecializationSection form={form} />
        <Row>
          <Col md={6}>
            <Form.Group controlId="slot-date">
              <Form.Label className="required">Date</Form.Label>
              <Form.Control
                type="date"
                name="date"
                min={moment().format('YYYY-MM-DD')}
                ref={register}
                isInvalid={!!errors.date}
              />
              <Form.Control.Feedback type="invalid">
                {errors.date?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Row>
              <Form.Group as={Col} controlId="slot-hour">
                <Form.Label className="required">Time</Form.Label>
                <Form.Control
                  min="1"
                  max="12"
                  type="number"
                  placeholder="H"
                  name="hour"
                  ref={register}
                  isInvalid={!!errors.hour}
                />
                <Form.Text className="text-muted">Hour (1 - 12)</Form.Text>
                <Form.Control.Feedback type="invalid">
                  {errors.hour?.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} controlId="slot-minute">
                <Form.Label className="text-white">Minute</Form.Label>
                <Form.Control as="select" name="minute" ref={register}>
                  <option value="00">00</option>
                  <option value="30">30</option>
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} controlId="slot-meridiem">
                <Form.Label className="text-white">Meridiem</Form.Label>
                <Form.Control as="select" name="meridiem" ref={register}>
                  <option value="AM">AM</option>
                  <option value="PM">PM</option>
                </Form.Control>
              </Form.Group>
            </Form.Row>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Form.Row>
              <Form.Group as={Col} controlId="slot-recur_option">
                <Form.Label>Repeat</Form.Label>
                <Form.Control
                  as="select"
                  name="recur_option"
                  ref={register}
                  onChange={updateRecur}
                >
                  <option value={RecurOption.NONE}>
                    {getRecurOptionLabel(RecurOption.NONE)}
                  </option>
                  <option value={RecurOption.WEEKLY}>
                    {getRecurOptionLabel(RecurOption.WEEKLY)}
                  </option>
                  <option value={RecurOption.OTHER_WEEK}>
                    {getRecurOptionLabel(RecurOption.OTHER_WEEK)}
                  </option>
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} controlId="slot-repetition">
                <Form.Label>Frequency</Form.Label>
                <Form.Control
                  as="select"
                  name="repetition"
                  ref={register}
                  isInvalid={!!errors.repetition}
                  disabled={!hasRecurOption}
                >
                  {!hasRecurOption && <option value="NONE"></option>}
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.repetition?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
          </Col>
        </Row>
        <Form.Group controlId="physician">
          <Form.Label>Therapist</Form.Label>
          <Form.Control
            type="text"
            placeholder="Search by name"
            name="physician"
            ref={register}
            isInvalid={!!errors.physician}
          />
          <Form.Control.Feedback type="invalid">
            {errors.physician?.message}
          </Form.Control.Feedback>
        </Form.Group>
        {renderEmptyPhysicians()}
        {renderPhysicians()}
      </Card.Body>
    </Card>
  );

  const updateRecur = (): void => {
    const { recur_option } = getValues();
    dispatch(setRecurOption(recur_option));
    if (recur_option === RecurOption.NONE)
      setValue('repetition', RecurOption.NONE);
  };

  const renderEmptyPhysicians = (): ReactNode => {
    if (physicians.length === 0)
      return (
        <Alert variant="warning">
          No available therapist based on your selected specialization, date,
          and time.
        </Alert>
      );
  };

  const renderPhysicians = (): ReactNode => {
    if (physicians.length > 0)
      return (
        <div className="booking__physicians">
          {physicians.map(
            (p): ReactNode => (
              <div
                key={p.uuid}
                className="booking__physician-item"
                onClick={() => selectPhysician(p)}
              >
                <div className="physician-user">
                  <img src={getAccountImage(p.gender)} alt={toFullName(p)} />
                  {toFullName(p)}
                </div>
              </div>
            ),
          )}
        </div>
      );
  };

  const selectPhysician = (physician: Physician): void => {
    const { date, hour, minute, meridiem, repetition } = getValues();
    const today = moment('0:00', 'H:mm');
    const schedule = moment(date, 'YYYY-MM-DD');
    if (schedule.diff(today, 'days') < 0) {
      setError('date', {
        type: 'min',
        message: `Date must be ${today.format('DD MMM YYYY')} or later`,
        shouldFocus: true,
      });
      return;
    }
    const consultation_date = moment(
      `${date} ${parseInt(hour)}:${minute} ${meridiem}`,
      'YYYY-MM-DD h:mm A',
    ).format('YYYY-MM-DD HH:mm:00');
    if (!topSpecialization || !subSpecializations.length) return;
    dispatch(
      completeSession({
        session,
        consultation_date,
        topSpecialization: topSpecialization,
        specializations: subSpecializations,
        physician,
        repetition,
      }),
    );
    dispatch(resetSelection());
  };

  return renderSection();
};

export default NewSession;
