import { ReactElement } from 'react';
import Container from 'react-bootstrap/Container';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

export const Faq = (): ReactElement => {
  return (
    <div className="home__faq">
      <Container>
        <div className="header">
          <h1 className="title">Frequently Asked Questions</h1>
        </div>
        <h3 className="title">Developmental Disorders</h3>
        <Accordion>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              What are the developmental red flags?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <ul>
                  <li>Strong parental concerns</li>
                  <li>Significant loss of skills</li>
                  <li>Lack of response to sound or visual stimuli</li>
                  <li>Poor interaction with adults or other children</li>
                  <li>Lack of, or limited eye contact</li>
                  <li>
                    Differences between right and left sides of body in
                    strength, movement or tone
                  </li>
                  <li>
                    Marked low tone (floppy) or high tone (stiff and tense) and
                    significantly impacting on development and functional motor
                    skills
                  </li>
                </ul>
                <p>
                  <em>
                    Source: Red Flags Early Identification Guide for children
                    aged birth to five years 2nd Edition, Children’s Health
                    Queensland Hospital and Health Service and Child and Youth
                    Community Health Service. Copyright Developed by the Child
                    Development Program in conjunction with Brisbane North
                    Primary Health Network. Updated: July 2016.
                  </em>
                </p>
                <a
                  href="https://www.childrens.health.qld.gov.au/wp-content/uploads/PDF/red-flags-a3.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.childrens.health.qld.gov.au/wp-content/uploads/PDF/red-flags-a3.pdf
                </a>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="1">
              What are the common developmental disorders?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                <ul>
                  <li>
                    <strong>Autism Spectrum Disorder</strong> - It is
                    characterized by a broad range of difficulty in social
                    communication, social interaction, and restricted and
                    repetitive behaviors across different contexts.
                  </li>
                  <li>
                    <strong>Attention Deficit Hyperactivity Disorder</strong> -
                    A condition that manifests a persistent pattern of
                    inattention, hyperactivity-impulsivity or a combination of
                    inattention and hyperactivity-implusivity that interferes
                    with functioning or development.
                  </li>
                  <li>
                    <strong>Global Developmental Delay</strong> - A diagnosis
                    given to children under 5 years old when an individual fails
                    to meet expected developmental milestones in several areas
                    of intellectual functioning, and applies to children who are
                    unable to undergo systematic assessments of intellectual
                    functioning, including children who are too young to
                    participate in standardized testing. This category requires
                    reassessment after a period of time.
                  </li>
                  <li>
                    <strong>Intellectual Disability</strong> - A disorder that
                    affects intellectual capacity of a child with &lt;70 IQ
                    level. It also results in failure meeting developmental and
                    sociocultural standards for personal independence and social
                    responsibility.
                  </li>
                  <li>
                    <strong>Down Syndrome</strong> - It is a genetic condition
                    that is caused by three copies of chromosome 21. It has
                    common physical features such as such as a short, stocky
                    stature and a protruding abdomen, small and flattened at the
                    back, and the eyes have an upward slant and abnormal
                    epicanthal folds and a single crease or line on the hand.
                    They commonly have: Intellectual disability, heart defects,
                    intestinal defects, vision problems, hearing loss, and
                    memory loss.
                  </li>
                  <li>
                    <strong>Learning Disability</strong> - An umbrella term that
                    refers to several neurologic disorders that shows difficulty
                    in how individuals learn. They have average or above-average
                    intelligence but have difficulty with at least one of the
                    following: reading, writing, language, spelling, math,
                    reasoning, or processing.
                  </li>
                  <li>
                    <strong>Cerebral Palsy</strong> - Cerebral palsy (CP)
                    encompasses several neurologic disorders that occur at birth
                    or in early infancy. It is caused by an injury to the fetus’
                    or infant’s brain and can be caused by any of the following:
                    illness, injury or inflammation of the brain, abnormal brain
                    development, severe jaundice, cerebrovascular incident, or
                    anoxia. It is manifested by problems in movement, muscle
                    tone, and coordination.
                  </li>
                </ul>
                <p>
                  <em>
                    Source: Bowyer, P. & Cahill, S. (1st Ed.). (2008). Pediatric
                    Occupational Therapy Handbook - E-Book: A Guide to Diagnoses
                    and Evidence-Based Interventions. Elsevier Health Sciences
                  </em>
                </p>
                <a
                  href="https://dsm.psychiatryonline.org/doi/book/10.1176/appi.books.9780890425596"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  American Psychiatric Association. (2013). Diagnostic and
                  Statistical Manual of Mental Disorders (5th ed.)
                </a>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </Container>
    </div>
  );
};

export default Faq;
