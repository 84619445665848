import { useCurrentUser, useFullSpecializations } from 'hooks';
import { FC, ReactElement, ReactNode, ReactNodeArray } from 'react';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import { useSelector } from 'react-redux';
import {
  FullSpecialization,
  selectFullSpecialization,
  setFullSpecialization,
} from 'redux/specialization';
import { useAppDispatch } from 'redux/store';

export const SpecializationList: FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const { data: user } = useCurrentUser();
  const fullSpecialization = useSelector(selectFullSpecialization);
  const { data: fullSpecializations = [] } = useFullSpecializations({
    isEnabled: !!user,
    phy_uuid: user?.uuid,
  });

  const renderMain = (): ReactElement => (
    <Card body className="mt-3">
      <Table responsive borderless className="specialization-table">
        <thead>
          <tr>
            <th>Specialization</th>
            <th>Level</th>
            <th>Accredited By</th>
            <th>Completed On</th>
          </tr>
        </thead>
        <tbody>{renderRows()}</tbody>
      </Table>
    </Card>
  );

  const renderRows = (): ReactNodeArray =>
    fullSpecializations.map(
      (fs, k): ReactNode => (
        <tr
          key={k}
          onClick={() => activeFullSpecialization(fs)}
          className={fullSpecialization?.id === fs.id ? 'active' : ''}
        >
          <td>{fs.specialization}</td>
          <td>{fs.level}</td>
          <td>{fs.accredited_by}</td>
          <td>{fs.completed_on}</td>
        </tr>
      ),
    );

  const activeFullSpecialization = (
    fullSpecialization: FullSpecialization,
  ): void => {
    dispatch(setFullSpecialization(fullSpecialization));
  };

  return renderMain();
};

export default SpecializationList;
