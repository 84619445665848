import { FC, ReactElement, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { resetPatient, setIsUpdatingTo } from 'redux/patient';
import { useAppDispatch } from 'redux/store';
import { getPatientImage } from 'utils/profile';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { toFullName } from 'utils/user';
import { useCurrentUser, usePatient } from 'hooks';

export const PatientView: FC<{ uuid: string }> = (props): ReactElement => {
  const { uuid } = props;
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { data: user } = useCurrentUser();
  const { isLoading, data: patient } = usePatient(uuid, user?.uuid);

  useEffect(() => {
    return () => {
      dispatch(resetPatient());
    };
  }, [dispatch, uuid]);

  if (!patient || isLoading) return <>Loading... Please Wait</>;

  const renderView = (): ReactElement => (
    <Card className="mt-3 profile-view">
      <Card.Body className="body">
        <div className="left">
          <div className="section picture">
            <img
              src={getPatientImage(patient.gender)}
              alt={toFullName(patient)}
            />
          </div>
          <div className="section">
            <Button variant="light" onClick={startUpdating} block>
              Edit Profile
            </Button>
            <Button variant="light" onClick={openDiary} block>
              Diary
            </Button>
            <Button variant="primary" onClick={redirectToBookingPage} block>
              Book Session
            </Button>
          </div>
        </div>
        <div className="right">
          <Row>
            <Col>
              <h4>{toFullName(patient)}</h4>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="description-item">
                <span>Gender</span>
                {patient.gender}
              </div>
            </Col>
            <Col md={6}>
              <div className="description-item">
                <span>Birth Date</span>
                {moment(patient.birth_date, 'M/D/YYYY').format('MMM. D, YYYY')}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="description-item">
                <span>Address</span>
                {patient.address}
              </div>
            </Col>
            <Col md={6}>
              <div className="description-item">
                <span>Diagnosis</span>
                {patient.diagnosis}
              </div>
            </Col>
          </Row>
        </div>
      </Card.Body>
    </Card>
  );

  const startUpdating = (): void => {
    dispatch(setIsUpdatingTo(true));
  };

  const openDiary = (): void => {
    window.open('https://bit.ly/TheraWeeHW', '_blank');
  };

  const redirectToBookingPage = (): void => {
    history.push(`/sessions/new?patient_uuid=${uuid}`);
  };

  return renderView();
};

export default PatientView;
