import { RecurOption } from 'core/enums';

export const getRecurOptionLabel = (recur_option: RecurOption): string => {
  switch (recur_option) {
    case RecurOption.WEEKLY:
      return 'Every week';
    case RecurOption.OTHER_WEEK:
      return 'Every other week';
    default:
      return 'Does not repeat';
  }
};
