import { FC, ReactElement, ReactNode, ReactNodeArray, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { useSelector } from 'react-redux';
import { User } from 'redux/auth';
import { useAppDispatch } from 'redux/store';
import {
  BookedSession,
  nextWeek,
  previousWeek,
  resetSession,
  selectBeginDate,
  selectEndDate,
  selectPatientsSession,
  PatientsSession,
  setIsViewingTo,
  setPatientsSession,
} from 'redux/session';
import moment from 'moment';
import { OrderDir } from 'core/enums';
import { useHistory } from 'react-router-dom';
import { useCurrentUser, useSessions } from 'hooks';

export const SessionCalendar: FC = (): ReactElement => {
  const scheduleUrl = '/schedule';
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { data: user, isPhysicianUser } = useCurrentUser();
  const patientsSession = useSelector(selectPatientsSession);
  const beginDate = useSelector(selectBeginDate);
  const endDate = useSelector(selectEndDate);
  const beginDateMoment = moment(beginDate);
  const endDateMoment = moment(endDate);
  const f = moment(beginDate);
  const t = moment(endDate);
  const { isLoading, mergedSessions } = useSessions({
    user_uuid: (user as User).uuid,
    date_from: f.format('YYYY-MM-DD 00:00:00'),
    date_to: t.format('YYYY-MM-DD 23:59:00'),
    order_dir: OrderDir.ASC,
  });

  useEffect(() => {
    return () => {
      dispatch(resetSession());
    };
  }, [dispatch]);

  const renderMain = (): ReactElement => (
    <Card>
      <Card.Body className="session-calendar-body">
        <Card.Title className="session-header">
          <Button
            variant="secondary"
            className="mr-2"
            onClick={viewPreviousWeek}
          >
            &lt;
          </Button>
          <Button variant="secondary" className="mr-2" onClick={viewNextWeek}>
            &gt;
          </Button>
          {renderDateRange()}
        </Card.Title>
        <table className="session-calendar">
          <thead>
            <tr>
              <th></th>
              {renderDateCols()}
            </tr>
          </thead>
          <tbody>
            {emptyRow()}
            {renderRows()}
          </tbody>
        </table>
      </Card.Body>
    </Card>
  );

  const viewPreviousWeek = (): void => {
    dispatch(previousWeek());
  };

  const viewNextWeek = (): void => {
    dispatch(nextWeek());
  };

  const renderDateRange = (): string => {
    const f = 'MMM. DD, YYYY';
    return `${beginDateMoment.format(f)} - ${endDateMoment.format(f)}`;
  };

  const renderDateCols = (): ReactNodeArray => {
    const bm = beginDateMoment.clone();
    const ns: ReactNodeArray = [];
    for (let i = 0; i < 7; i++) {
      ns.push(<th key={i}>{bm.format('D ddd')}</th>);
      bm.add(1, 'day');
    }
    return ns;
  };

  const emptyRow = (): ReactNode => {
    if (isLoading)
      return (
        <tr>
          <td colSpan={8} className="text-center">
            Loading... Please Wait
          </td>
        </tr>
      );
    if (mergedSessions.length === 0)
      return (
        <tr>
          <td colSpan={8} className="text-center">
            <Alert variant="info" className="mb-0">
              No confirmed therapy sessions. Click{' '}
              <a href={scheduleUrl} onClick={redirectToSchedulePage}>
                here
              </a>{' '}
              if you want to update your schedule?
            </Alert>
          </td>
        </tr>
      );
  };

  const redirectToSchedulePage = (
    event: React.MouseEvent<HTMLAnchorElement>,
  ): void => {
    event.preventDefault();
    history.push(scheduleUrl);
  };

  const renderRows = (): ReactNodeArray => {
    return mergedSessions.map(
      (ms, k): ReactNode => {
        return (
          <tr key={k}>
            <td>{moment(ms.time, 'Hmm').format('h:mm A')}</td>
            {renderRowCols(ms.sessions)}
          </tr>
        );
      },
    );
  };

  const renderRowCols = (patientsSessions: PatientsSession[]): ReactNodeArray =>
    patientsSessions.map(
      (ps): ReactNode => {
        if (ps.patients.length > 0)
          return (
            <td
              key={ps.day}
              onClickCapture={() => activeCell(ps)}
              onDoubleClickCapture={showCell}
              className={
                !!patientsSession && patientsSession.cellId === ps.cellId
                  ? 'active'
                  : ''
              }
            >
              <ul className="basic-ul">{renderCellItems(ps.patients)}</ul>
            </td>
          );
        else return <td key={ps.day}></td>;
      },
    );

  const renderCellItems = (patients: BookedSession[]): ReactNodeArray =>
    patients.map((p, k): ReactNode => <li key={k}>{p.patient_name}</li>);

  const activeCell = (patientsSessionParam: PatientsSession): void => {
    if (
      !patientsSession ||
      patientsSession.cellId !== patientsSessionParam.cellId
    )
      dispatch(setPatientsSession(patientsSessionParam));
  };

  const showCell = (): void => {
    dispatch(setIsViewingTo(true));
  };

  if (!isPhysicianUser) return <></>;

  return renderMain();
};

export default SessionCalendar;
