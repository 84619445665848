import React, { FC, ReactElement, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { AlertMessage } from 'components';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { setIsLicenseUpdatingTo, selectIsLicenseUpdating } from 'redux/auth';
import { useAppDispatch } from 'redux/store';
import moment from 'moment';
import { ErrorMessage } from 'core/enums';
import { useCurrentUser, useUpdateUser } from 'hooks';

export type LicenseInfoFormInputs = {
  license_number: string;
  registration_month: string;
  registration_day: string;
  registration_year: string;
  valid_month: string;
  valid_day: string;
  valid_year: string;
};

const yearMinimum = 1900;
const yearToday = moment().year();
const maxYear = 9999;
const schema: yup.SchemaOf<LicenseInfoFormInputs> = yup
  .object()
  .shape({
    license_number: yup.string().required(ErrorMessage.REQUIRED),
    registration_month: yup
      .string()
      .required(ErrorMessage.REQUIRED)
      .test('range', ErrorMessage.MONTH, (value) => {
        if (!value) return false;
        const i = parseInt(value);
        return i >= 1 && i <= 12;
      })
      .test('date', ErrorMessage.DATE, (month, ctx) => {
        const { registration_day: day, registration_year: year } = ctx.parent;
        if (!month || !day || !year) return true;
        return moment(`${month}/${day}/${year}`, 'M/D/YYYY').isValid();
      })
      .test(
        'date',
        ErrorMessage.REGISTRATION_EXPIRED,
        (registration_month, ctx) => {
          const {
            registration_day,
            registration_year,
            valid_month,
            valid_day,
            valid_year,
          } = ctx.parent;
          if (
            !registration_month ||
            !registration_day ||
            !registration_year ||
            !valid_month ||
            !valid_day ||
            !valid_year
          )
            return true;
          const registration = moment(
            `${registration_month}/${registration_day}/${registration_year}`,
            'M/D/YYYY',
          );
          const until = moment(
            `${valid_month}/${valid_day}/${valid_year}`,
            'M/D/YYYY',
          );
          return registration.diff(until, 'days') < 0;
        },
      ),
    registration_day: yup
      .string()
      .required(ErrorMessage.REQUIRED)
      .test('range', ErrorMessage.DAY, (value) => {
        if (!value) return false;
        const i = parseInt(value);
        return i >= 1 && i <= 31;
      }),
    registration_year: yup
      .string()
      .required(ErrorMessage.REQUIRED)
      .test(
        'range',
        `Between ${yearMinimum} and ${yearToday} only`,
        (value) => {
          if (!value) return false;
          const i = parseInt(value);
          return i >= yearMinimum && i <= yearToday;
        },
      ),
    valid_month: yup
      .string()
      .required(ErrorMessage.REQUIRED)
      .test('range', ErrorMessage.MONTH, (value) => {
        if (!value) return false;
        const i = parseInt(value);
        return i >= 1 && i <= 12;
      })
      .test('date', ErrorMessage.DATE, (month, ctx) => {
        const { valid_day: day, valid_year: year } = ctx.parent;
        if (!month || !day || !year) return true;
        return moment(`${month}/${day}/${year}`, 'M/D/YYYY').isValid();
      })
      .test('date', ErrorMessage.LICENSE_EXPIRED, (month, ctx) => {
        const { valid_day: day, valid_year: year } = ctx.parent;
        if (!month || !day || !year) return true;
        const m = moment();
        const today = moment(
          `${m.format('M')}/${m.format('D')}/${m.format('YYYY')}`,
          'M/D/YYYY',
        );
        const until = moment(`${month}/${day}/${year}`, 'M/D/YYYY');
        return today.diff(until, 'days') <= 0;
      }),
    valid_day: yup
      .string()
      .required(ErrorMessage.REQUIRED)
      .test('range', ErrorMessage.DAY, (value) => {
        if (!value) return false;
        const i = parseInt(value);
        return i >= 1 && i <= 31;
      }),
    valid_year: yup
      .string()
      .required(ErrorMessage.REQUIRED)
      .test('range', `Between ${yearMinimum} and ${maxYear} only`, (value) => {
        if (!value) return false;
        const i = parseInt(value);
        return i >= yearMinimum && i <= maxYear;
      }),
  })
  .defined();

export const LicenseUpdate: FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const { data: user } = useCurrentUser();
  const { isLoading: isSubmitting, isError, mutateAsync } = useUpdateUser();
  const {
    register,
    handleSubmit,
    errors,
    setValue,
  } = useForm<LicenseInfoFormInputs>({
    resolver: yupResolver(schema),
  });
  const isUpdating = useSelector(selectIsLicenseUpdating);

  useEffect(() => {
    return () => {
      dispatch(setIsLicenseUpdatingTo(false));
    };
  }, [dispatch]);

  useEffect(() => {
    if (!isUpdating || !user) return;
    const registration = moment(user.prc_reg_date);
    const validity = moment(user.prc_val_date);
    setValue('license_number', user.prc);
    setValue('registration_month', registration.format('MM'));
    setValue('registration_day', registration.format('DD'));
    setValue('registration_year', registration.format('YYYY'));
    setValue('valid_month', validity.format('MM'));
    setValue('valid_day', validity.format('DD'));
    setValue('valid_year', validity.format('YYYY'));
  }, [isUpdating, user, setValue]);

  const renderModal = (): ReactElement => (
    <Modal show={isUpdating} onHide={stopUpdating}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit PRC License</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertMessage visible={isError} />
          <Form.Group controlId="license_number">
            <Form.Label className="required">License Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your license number"
              name="license_number"
              ref={register}
              isInvalid={!!errors.license_number}
            />
            <Form.Control.Feedback type="invalid">
              {errors.license_number?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Row>
            <Form.Group as={Col} controlId="registration-month">
              <Form.Label className="required">Registration</Form.Label>
              <Form.Control
                min="1"
                max="12"
                type="number"
                placeholder="MM"
                name="registration_month"
                ref={register}
                isInvalid={!!errors.registration_month}
              />
              <Form.Text className="text-muted">Month (Ex. 12)</Form.Text>
              <Form.Control.Feedback type="invalid">
                {errors.registration_month?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId="registration-day">
              <Form.Label className="text-white">Day</Form.Label>
              <Form.Control
                min="1"
                max="31"
                type="number"
                placeholder="DD"
                name="registration_day"
                ref={register}
                isInvalid={!!errors.registration_day}
              />
              <Form.Text className="text-muted">Day (Ex. 31)</Form.Text>
              <Form.Control.Feedback type="invalid">
                {errors.registration_day?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId="registration-year">
              <Form.Label className="text-white">Year</Form.Label>
              <Form.Control
                min={yearMinimum}
                max={yearToday}
                type="number"
                placeholder="YYYY"
                name="registration_year"
                ref={register}
                isInvalid={!!errors.registration_year}
              />
              <Form.Text className="text-muted">Year (Ex. 1980)</Form.Text>
              <Form.Control.Feedback type="invalid">
                {errors.registration_year?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="valid-month">
              <Form.Label className="required">Valid Until</Form.Label>
              <Form.Control
                min="1"
                max="12"
                type="number"
                placeholder="MM"
                name="valid_month"
                ref={register}
                isInvalid={!!errors.valid_month}
              />
              <Form.Text className="text-muted">Month (Ex. 12)</Form.Text>
              <Form.Control.Feedback type="invalid">
                {errors.valid_month?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId="valid-day">
              <Form.Label className="text-white">Day</Form.Label>
              <Form.Control
                min="1"
                max="31"
                type="number"
                placeholder="DD"
                name="valid_day"
                ref={register}
                isInvalid={!!errors.valid_day}
              />
              <Form.Text className="text-muted">Day (Ex. 31)</Form.Text>
              <Form.Control.Feedback type="invalid">
                {errors.valid_day?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId="valid-year">
              <Form.Label className="text-white">Year</Form.Label>
              <Form.Control
                min={yearMinimum}
                max={maxYear}
                type="number"
                placeholder="YYYY"
                name="valid_year"
                ref={register}
                isInvalid={!!errors.valid_year}
              />
              <Form.Text className="text-muted">Year (Ex. 1980)</Form.Text>
              <Form.Control.Feedback type="invalid">
                {errors.valid_year?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" disabled={isSubmitting} onClick={stopUpdating}>
            Cancel
          </Button>
          <Button variant="primary" type="submit" disabled={isSubmitting}>
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );

  const stopUpdating = (): void => {
    if (isSubmitting) return;
    dispatch(setIsLicenseUpdatingTo(false));
  };

  const onSubmit = async (data: LicenseInfoFormInputs): Promise<void> => {
    if (!user) {
      return;
    }
    await mutateAsync({
      ...user,
      prc: data.license_number,
      prc_reg_date: `${data.registration_year}-${data.registration_month}-${data.registration_day}`,
      prc_val_date: `${data.valid_year}-${data.valid_month}-${data.valid_day}`,
    });
    stopUpdating();
  };

  return renderModal();
};

export default LicenseUpdate;
