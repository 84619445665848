import React, { ReactElement } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { AlertMessage } from 'components';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { selectIsCompleting, setIsCompletingTo } from 'redux/session';
import { useAppDispatch } from 'redux/store';
import { useCompleteSession, useSession, useSessionInfoParams } from 'hooks';

export const SessionComplete = (): ReactElement => {
  const dispatch = useAppDispatch();
  const params = useSessionInfoParams();
  const { handleSubmit } = useForm();
  const isCompleting = useSelector(selectIsCompleting);
  const { data: sessionView } = useSession(
    params.session_uuid,
    params.pat_uuid,
  );
  const {
    isLoading: isSubmitting,
    isError: isSubmitError,
    mutateAsync,
  } = useCompleteSession(params.session_uuid);

  const renderModal = (): ReactElement => (
    <Modal
      backdrop="static"
      keyboard={false}
      show={isCompleting}
      onHide={stopCompleting}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>Complete Session</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertMessage visible={isSubmitError} />
          Are you sure you want to submit patient's therapy notes? If you click
          yes, it means you have completed the information you needed and cannot
          edit the therapy notes anymore. Any information written here will be
          considered as a final document.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            disabled={isSubmitting}
            onClick={stopCompleting}
          >
            Cancel
          </Button>
          <Button variant="primary" type="submit" disabled={isSubmitting}>
            Yes
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );

  const stopCompleting = (): void => {
    if (isSubmitting) return;
    dispatch(setIsCompletingTo(false));
  };

  const onSubmit = async (): Promise<void> => {
    if (!sessionView) {
      return;
    }
    await mutateAsync(sessionView.uuid);
    stopCompleting();
  };

  return renderModal();
};

export default SessionComplete;
