import { ScheduleCreate, ScheduleService } from 'api';
import { AxiosError } from 'axios';
import moment from 'moment';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { DayNumbers, Slot, WeekSlot } from 'redux/schedule';
import { UseScheduleSlots } from '.';
import { UseScheduleSlotsResult } from './schedule.types';

export const useScheduleSlots = (
  data: UseScheduleSlots,
): UseScheduleSlotsResult & UseQueryResult<Slot[]> => {
  const { isEnabled = true, phy_uuid } = data;
  const result = useQuery(
    ['schedule-slots', phy_uuid],
    () => ScheduleService.getSlots(phy_uuid),
    {
      enabled: isEnabled,
    },
  );
  const wss: WeekSlot[] = [];
  if (result.data) {
    result.data.forEach((s): void => {
      const m = moment(s.consultation_date, 'M/D/YYYY h:mm:ss A');
      const time_id = parseInt(m.format('Hmm'));
      const d = m.day();
      const ws = wss.find((_ws): boolean => _ws.time_id === time_id);
      if (ws) ws[d as DayNumbers] = s.status;
      else
        wss.push({
          time_id,
          time_period: m.format('h:mm A'),
          [d]: s.status,
        });
    });
    wss.sort((a, b): number => a.time_id - b.time_id);
  }
  return {
    weekSlots: wss,
    ...result,
  };
};

export const useCreateSchedule = (
  phy_uuid: string,
): UseMutationResult<void, AxiosError, ScheduleCreate> => {
  const queryClient = useQueryClient();
  return useMutation(ScheduleService.createSchedule, {
    onSuccess: () => {
      queryClient.invalidateQueries(['schedule-slots', phy_uuid]);
    },
  });
};

export const useDeleteSchedule = (
  phy_uuid: string,
): UseMutationResult<void, AxiosError, number> => {
  const queryClient = useQueryClient();
  return useMutation(ScheduleService.deleteSchedule, {
    onSuccess: () => {
      queryClient.invalidateQueries(['schedule-slots', phy_uuid]);
    },
  });
};
