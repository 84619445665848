import { FC, ReactElement, ReactNode, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import logoDark from 'images/therawee-logo-dark-compressed.png';
import { removeToken } from 'utils/auth';
import { Role } from 'core/enums';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useCurrentUser } from 'hooks';
import { useQueryClient } from 'react-query';

export const TopbarPrivate: FC = (): ReactElement => {
  const homeUrl = '/';
  const loginUrl = '/login';
  const dashboardUrl = '/dashboard';
  const scheduleUrl = '/schedule';
  const specializationsUrl = '/specializations';
  const bookingUrl = '/sessions/new';
  const accountUrl = '/users/me';
  const history = useHistory();
  const { data: user } = useCurrentUser();
  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const renderTopbar = (): ReactElement => (
    <nav className="custom-navbar">
      <Container className="content">
        <NavLink to={homeUrl} onClick={redirectToUrl}>
          <img src={logoDark} alt="TheraWee Logo" className="logo" />
        </NavLink>
        <div className="menu">
          {user?.username}
          <button className="btn ml-2" onClick={toggleMenu}>
            <FontAwesomeIcon icon={isMenuVisible ? 'times' : 'bars'} />
          </button>
        </div>
        <div className={`custom-navbar-links ${isMenuVisible ? 'active' : ''}`}>
          <ul>
            {renderBookLink()}
            <li className="link">
              <NavLink to={dashboardUrl} onClick={redirectToUrl}>
                Dashboard
              </NavLink>
            </li>
            {renderScheduleLink()}
            {renderSpecializationLink()}
            <li className="link account">
              <NavLink to={accountUrl} onClick={redirectToUrl}>
                My Account
              </NavLink>
            </li>
            <li className="link center logout">
              <button className="btn" onClick={logOut}>
                Log Out
              </button>
            </li>
            <li className="center username">
              <NavDropdown title={user?.username} id="user-dropdown">
                <NavDropdown.Item href={accountUrl} onClick={redirectToUrl}>
                  My Account
                </NavDropdown.Item>
                <NavDropdown.Item href="#" onClick={logOut}>
                  Log Out
                </NavDropdown.Item>
              </NavDropdown>
            </li>
          </ul>
        </div>
      </Container>
    </nav>
  );

  const redirectToUrl = (event: React.MouseEvent<HTMLAnchorElement>): void => {
    event.preventDefault();
    const url = event.currentTarget.getAttribute('href');
    if (!!url) redirectTo(url);
    if (isMenuVisible) setIsMenuVisible(false);
  };

  const redirectTo = (path: string): void => history.push(path);

  const toggleMenu = (): void => {
    setIsMenuVisible(!isMenuVisible);
  };

  const renderBookLink = (): ReactNode => {
    if (user?.role === Role.PATIENT)
      return (
        <li className="link">
          <NavLink to={bookingUrl} onClick={redirectToUrl} className="primary">
            Book Now
          </NavLink>
        </li>
      );
  };

  const renderScheduleLink = (): ReactNode => {
    if (user?.role === Role.PHYSICIAN)
      return (
        <li className="link">
          <NavLink to={scheduleUrl} onClick={redirectToUrl}>
            Schedule
          </NavLink>
        </li>
      );
  };

  const renderSpecializationLink = (): ReactNode => {
    if (user?.role === Role.PHYSICIAN)
      return (
        <li className="link">
          <NavLink to={specializationsUrl} onClick={redirectToUrl}>
            Specializations
          </NavLink>
        </li>
      );
  };

  const logOut = (): void => {
    removeToken();
    queryClient.clear();
    redirectToLoginPage();
  };

  const redirectToLoginPage = (): void => redirectTo(loginUrl);

  return renderTopbar();
};

export default TopbarPrivate;
