import { ReactElement } from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useCurrentUser } from 'hooks';
import moment from 'moment';
import { Role } from 'core/enums';

export const LicenseInfo = (): ReactElement => {
  const { data: user } = useCurrentUser();

  if (!user || user.role !== Role.PHYSICIAN) return <></>;

  return (
    <Card className="mt-3 mb-3">
      <Card.Body>
        <Row>
          <Col>
            <div className="description-item">
              <span>License Number</span>
              {user.prc}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <div className="description-item">
              <span>Registration</span>
              {moment(user.prc_reg_date).format('MMMM D, YYYY')}
            </div>
          </Col>
          <Col md={6}>
            <div className="description-item">
              <span>Valid Until</span>
              {moment(user.prc_val_date).format('MMMM D, YYYY')}
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default LicenseInfo;
