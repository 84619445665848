import { FC, ReactElement, ReactNode, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import logoDark from 'images/therawee-logo-dark-compressed.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const TopbarHome: FC = (): ReactElement => {
  const homeUrl = '/';
  const aboutUrl = '#about';
  const contactUrl = '/contact';
  const loginUrl = '/login';
  const patientRegistrationUrl = '/register/patient';
  const physicianRegistrationUrl = '/register/physician';
  const history = useHistory();
  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false);

  const renderTopbar = (): ReactElement => (
    <nav className="custom-navbar">
      <Container className="content">
        <NavLink to={homeUrl} onClick={scrollToTop}>
          <img src={logoDark} alt="TheraWee Logo" className="logo" />
        </NavLink>
        <div className="menu">
          {renderRegistrationDropdown(true)}
          <button className="btn ml-2" onClick={toggleMenu}>
            <FontAwesomeIcon icon={isMenuVisible ? 'times' : 'bars'} />
          </button>
        </div>
        <div className={`custom-navbar-links ${isMenuVisible ? 'active' : ''}`}>
          <ul>
            <li className="link home">
              <NavLink to={homeUrl} onClick={anchorScrollToTop}>
                Home
              </NavLink>
            </li>
            <li className="link about">
              <a href={aboutUrl} onClick={closeMenuIfOpen}>
                About
              </a>
            </li>
            <li className="link contact">
              <NavLink to={contactUrl} onClick={redirectToUrl}>
                Contact
              </NavLink>
            </li>
            <li className="link">
              <NavLink
                to={loginUrl}
                onClick={redirectToUrl}
                className="primary"
              >
                Login
              </NavLink>
            </li>
            <li className="register center">{renderRegistrationDropdown()}</li>
          </ul>
        </div>
      </Container>
    </nav>
  );

  const scrollToTop = (): void => {
    window.scrollTo(0, 0);
    closeMenuIfOpen();
  };

  const closeMenuIfOpen = (): void => {
    if (isMenuVisible) setIsMenuVisible(false);
  };

  const renderRegistrationDropdown = (isSmall: boolean = false): ReactNode => {
    return (
      <DropdownButton
        title="Register As"
        size={isSmall ? 'sm' : undefined}
        className="ml-2"
      >
        <Dropdown.Item href={patientRegistrationUrl} onClick={redirectToUrl}>
          Patient
        </Dropdown.Item>
        <Dropdown.Item href={physicianRegistrationUrl} onClick={redirectToUrl}>
          Therapist
        </Dropdown.Item>
      </DropdownButton>
    );
  };

  const toggleMenu = (): void => {
    setIsMenuVisible(!isMenuVisible);
  };

  const anchorScrollToTop = (
    event: React.MouseEvent<HTMLAnchorElement>,
  ): void => {
    event.preventDefault();
    scrollToTop();
  };

  const redirectToUrl = (event: React.MouseEvent<HTMLAnchorElement>): void => {
    event.preventDefault();
    const url = event.currentTarget.getAttribute('href');
    if (!!url) redirectTo(url);
  };

  const redirectTo = (path: string): void => history.push(path);

  return renderTopbar();
};

export default TopbarHome;
