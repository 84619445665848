import React, { FC, ReactElement } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { AlertMessage } from 'components';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage, Gender } from 'core/enums';
import moment from 'moment';
import Col from 'react-bootstrap/Col';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { User } from 'redux/auth';
import * as yup from 'yup';
import { selectIsCreating, setIsCreatingTo } from 'redux/patient';
import { useAppDispatch } from 'redux/store';
import { useCreatePatient, useCurrentUser } from 'hooks';

export type PatientInfoFormInputs = {
  first_name: string;
  middle_name: string;
  last_name: string;
  diagnosis: string;
  sex: Gender;
  month: string;
  day: string;
  year: string;
  address: string;
};

const noGender = 'NONE';
const yearMinimum = 1900;
const yearToday = moment().year();
const schema: yup.SchemaOf<PatientInfoFormInputs> = yup
  .object()
  .shape({
    first_name: yup.string().required(ErrorMessage.REQUIRED),
    last_name: yup.string().required(ErrorMessage.REQUIRED),
    diagnosis: yup.string().required(ErrorMessage.REQUIRED),
    sex: yup.string().test('sex', ErrorMessage.REQUIRED, (value) => {
      if (!value || value === noGender) return false;
      return true;
    }),
    month: yup
      .string()
      .required(ErrorMessage.REQUIRED)
      .test('range', ErrorMessage.MONTH, (value) => {
        if (!value) return false;
        const i = parseInt(value);
        return i >= 1 && i <= 12;
      })
      .test('date', ErrorMessage.DATE, (month, ctx) => {
        const { day, year } = ctx.parent;
        if (!month || !day || !year) return true;
        return moment(`${month}/${day}/${year}`, 'M/D/YYYY').isValid();
      }),
    day: yup
      .string()
      .required(ErrorMessage.REQUIRED)
      .test('range', ErrorMessage.DAY, (value) => {
        if (!value) return false;
        const i = parseInt(value);
        return i >= 1 && i <= 31;
      }),
    year: yup
      .string()
      .required(ErrorMessage.REQUIRED)
      .test(
        'range',
        `Between ${yearMinimum} and ${yearToday} only`,
        (value) => {
          if (!value) return false;
          const i = parseInt(value);
          return i >= yearMinimum && i <= yearToday;
        },
      ),
    address: yup.string().required(ErrorMessage.REQUIRED),
  })
  .defined();

export const PatientCreate: FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const { data: user } = useCurrentUser();
  const { register, handleSubmit, errors } = useForm<PatientInfoFormInputs>({
    resolver: yupResolver(schema),
  });
  const isCreating = useSelector(selectIsCreating);
  const { isLoading, isError, mutateAsync } = useCreatePatient(
    (user as User).uuid,
  );

  const renderModal = (): ReactElement => (
    <Modal show={isCreating} onHide={stopCreating}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Patient</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertMessage visible={isError} />
          <Form.Group controlId="first_name">
            <Form.Label className="required">First Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter patient's first name"
              name="first_name"
              ref={register}
              isInvalid={!!errors.first_name}
            />
            <Form.Control.Feedback type="invalid">
              {errors.first_name?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="middle_name">
            <Form.Label>Middle Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter patient's middle name"
              name="middle_name"
              ref={register}
            />
          </Form.Group>
          <Form.Group controlId="last_name">
            <Form.Label className="required">Last Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter patient's last name"
              name="last_name"
              ref={register}
              isInvalid={!!errors.last_name}
            />
            <Form.Control.Feedback type="invalid">
              {errors.last_name?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="diagnosis">
            <Form.Label className="required">Diagnosis</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter diagnosis"
              name="diagnosis"
              ref={register}
              isInvalid={!!errors.diagnosis}
            />
            <Form.Control.Feedback type="invalid">
              {errors.diagnosis?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="sex">
            <Form.Label className="required">Sex</Form.Label>
            <Form.Control
              as="select"
              placeholder="Select patient's sex"
              name="sex"
              ref={register}
              isInvalid={!!errors.sex}
            >
              <option value={noGender}>Select patient's sex</option>
              <option value={Gender.MALE}>Male</option>
              <option value={Gender.FEMALE}>Female</option>
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.sex?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Row>
            <Form.Group as={Col} controlId="birth-month">
              <Form.Label className="required">Birth Date</Form.Label>
              <Form.Control
                min="1"
                max="12"
                type="number"
                placeholder="MM"
                name="month"
                ref={register}
                isInvalid={!!errors.month}
              />
              <Form.Text className="text-muted">Month (Ex. 12)</Form.Text>
              <Form.Control.Feedback type="invalid">
                {errors.month?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId="birth-day">
              <Form.Label className="text-white">Day</Form.Label>
              <Form.Control
                min="1"
                max="31"
                type="number"
                placeholder="DD"
                name="day"
                ref={register}
                isInvalid={!!errors.day}
              />
              <Form.Text className="text-muted">Day (Ex. 31)</Form.Text>
              <Form.Control.Feedback type="invalid">
                {errors.day?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId="birth-year">
              <Form.Label className="text-white">Year</Form.Label>
              <Form.Control
                min={yearMinimum}
                max={yearToday}
                type="number"
                placeholder="YYYY"
                name="year"
                ref={register}
                isInvalid={!!errors.year}
              />
              <Form.Text className="text-muted">Year (Ex. 1980)</Form.Text>
              <Form.Control.Feedback type="invalid">
                {errors.year?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Group controlId="address">
            <Form.Label className="required">Address</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              placeholder="Enter patient's address"
              name="address"
              ref={register}
              isInvalid={!!errors.address}
            />
            <Form.Control.Feedback type="invalid">
              {errors.address?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" disabled={isLoading} onClick={stopCreating}>
            Cancel
          </Button>
          <Button variant="primary" type="submit" disabled={isLoading}>
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );

  const stopCreating = (): void => {
    if (isLoading) return;
    dispatch(setIsCreatingTo(false));
  };

  const onSubmit = async (data: PatientInfoFormInputs): Promise<void> => {
    if (!user) {
      return;
    }
    await mutateAsync({
      ...data,
      gender: data.sex,
      birth_date: `${parseInt(data.year)}-${parseInt(data.month)}-${parseInt(
        data.day,
      )}`,
    });
    stopCreating();
  };

  return renderModal();
};

export default PatientCreate;
