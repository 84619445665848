import { RootState } from 'redux/store';
import { WeekSlot, ScheduleState } from '.';
import { createSelector } from '@reduxjs/toolkit';

export {
  selectScheduleState,
  selectIsCreating,
  selectIsDeleting,
  selectWeekSlot,
  selectDay,
};

const selectScheduleState = (state: RootState): ScheduleState => state.schedule;

const selectIsCreating = createSelector(
  selectScheduleState,
  (schedule): boolean => schedule.isCreating,
);

const selectIsDeleting = createSelector(
  selectScheduleState,
  (schedule): boolean => schedule.isDeleting,
);

// const selectWeekSlots = createSelector(
//   selectScheduleState,
//   (schedule): WeekSlot[] => {
//     const wss: WeekSlot[] = [];
//     schedule.slots.forEach((s): void => {
//       const m = moment(s.consultation_date, 'M/D/YYYY h:mm:ss A');
//       const time_id = parseInt(m.format('Hmm'));
//       const d = m.day();
//       const ws = wss.find((_ws): boolean => _ws.time_id === time_id);
//       if (ws) ws[d as DayNumbers] = s.status;
//       else
//         wss.push({
//           time_id,
//           time_period: m.format('h:mm A'),
//           [d]: s.status,
//         });
//     });
//     return wss.sort((a, b): number => a.time_id - b.time_id);
//   },
// );

const selectWeekSlot = createSelector(
  selectScheduleState,
  (schedule): WeekSlot | undefined => schedule.weekSlot,
);

const selectDay = createSelector(
  selectScheduleState,
  (schedule): number | undefined => schedule.day,
);
