export enum Role {
  PATIENT = 'PATIENT',
  PHYSICIAN = 'PHYSICIAN',
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER',
  SECRET = 'SECRET',
}

export enum WeekDay {
  SUNDAY,
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
}

export enum Specialization {
  OCCUPATIONAL_THERAPY = 'OCCUPATIONAL_THERAPY',
  SPEECH_THERAPY = 'SPEECH_THERAPY',
  PHYSICAL_THERAPY = 'PHYSICAL_THERAPY',
  SPECIAL_EDUCATION = 'SPECIAL_EDUCATION',
  SENSORY_INTEGRATION = 'SENSORY_INTEGRATION',
  BEHAVIOR_MODIFICATION = 'BEHAVIOR_MODIFICATION',
  COGNITIVE_BEHAVIOR_THERAPY = 'COGNITIVE_BEHAVIOR_THERAPY',
  FEEDING_THERAPY = 'FEEDING_THERAPY',
  DEVELOPMENTAL_PLAY = 'DEVELOPMENTAL_PLAY',
  HANDWRITING_WITHOUT_TEARS = 'HANDWRITING_WITHOUT_TEARS',
  EXECUTIVE_FUNCTION = 'EXECUTIVE_FUNCTION',
  BOBATH_THERAPY = 'BOBATH_THERAPY',
  READING_INTERVENTION = 'READING_INTERVENTION',
  OTHER = 'OTHER',
}

export enum BookStatus {
  SUBMITTED = 'SUBMITTED',
  CANCELLED = 'CANCELLED',
  PAID = 'PAID',
  COMPLETED = 'COMPLETED',
}

export enum EmailStatus {
  VERIFIED = 'VERIFIED',
  UNVERIFIED = 'UNVERIFIED',
}

export enum OrderDir {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum RecurOption {
  NONE = 'NONE',
  WEEKLY = 'WEEKLY',
  OTHER_WEEK = 'OTHER_WEEK',
}

export enum PaymentMethod {
  GCASH = 'GCASH',
  GRAB_PAY = 'GRAB_PAY',
}

export enum ErrorMessage {
  REQUIRED = 'This field is required.',
  MINIMUM_8 = 'This field must be at least 8 characters.',
  PASSWORD_UNMATCH = 'Passwords must match.',
  EMAIL = 'This field must be an email format.',
  DATE = 'Invalid date.',
  MONTH = 'Between 1 and 12 only.',
  DAY = 'Between 1 and 31 only.',
  YEAR = 'Between 1 and 12 only.',
  HOUR = 'Between 1 and 12 only.',
  REGISTRATION_EXPIRED = 'Registration is expired.',
  LICENSE_EXPIRED = 'License is expired.',
  ONLINE_RATE = 'Between 100 and 2,000 only.',
  AGREEMENT = 'Please check the box to proceed.',
}
