import { RootState } from 'redux/store';
import { FullSpecialization, SpecializationCard, SpecializationState } from '.';
import { createSelector } from '@reduxjs/toolkit';
import {
  getTopSpecializations,
  getSpecializationId,
  getSpecializationLabel,
  getSpecializations,
  specializationWiki,
} from 'utils/specialization';
import { Specialization } from 'core/enums';

export {
  selectSpecialization,
  selectTopSpecializations,
  selectSpecializations,
  selectFullSpecialization,
  selectIsCreating,
  selectTopSpecialization,
  selectSubSpecializations,
  selectMergedSpecialization,
  selectSubSpecialization,
  selectIsOtherSpecialization,
  selectIsDeleting,
  selectIsLearning,
  selectLearningSpecialization,
  selectLearningTitle,
  selectLearningDescription,
};

const selectSpecialization = (state: RootState): SpecializationState =>
  state.specialization;

const selectFullSpecialization = createSelector(
  selectSpecialization,
  (specialization): FullSpecialization | undefined =>
    specialization.fullSpecialization,
);

const selectIsCreating = createSelector(
  selectSpecialization,
  (specialization): boolean => specialization.isCreating,
);

const selectTopSpecialization = createSelector(
  selectSpecialization,
  (specialization): Specialization | undefined =>
    specialization.topSpecialization,
);

const selectSubSpecializations = createSelector(
  selectSpecialization,
  (specialization): Specialization[] => specialization.subSpecializations,
);

const selectMergedSpecialization = createSelector(
  selectTopSpecialization,
  selectSubSpecializations,
  (top, subs): string | undefined => {
    return !!top && !!subs.length
      ? subs
          .map(
            (ss) =>
              `${getSpecializationLabel(top)} - ${getSpecializationLabel(ss)}`,
          )
          .join(',')
      : undefined;
  },
);

const selectSubSpecialization = createSelector(
  selectSpecialization,
  (specialization): Specialization | undefined => specialization.specialization,
);

const selectIsOtherSpecialization = createSelector(
  selectSubSpecialization,
  (subSpecialization): boolean => subSpecialization === Specialization.OTHER,
);

const selectTopSpecializations = createSelector(
  selectSpecialization,
  (_specialization): SpecializationCard[] =>
    getTopSpecializations().map(
      (s): SpecializationCard => {
        return {
          type: s,
          id: getSpecializationId(s),
          label: getSpecializationLabel(s),
        };
      },
    ),
);

const selectSpecializations = createSelector(
  selectTopSpecialization,
  (specialization): SpecializationCard[] => {
    return getSpecializations(specialization).map(
      (s): SpecializationCard => {
        return {
          type: s,
          id: getSpecializationId(s),
          label: getSpecializationLabel(s),
        };
      },
    );
  },
);

const selectIsDeleting = createSelector(
  selectSpecialization,
  (specialization): boolean => specialization.isDeleting,
);

const selectIsLearning = createSelector(
  selectSpecialization,
  (specialization): boolean => specialization.isLearning,
);

const selectLearningSpecialization = createSelector(
  selectSpecialization,
  (specialization): Specialization | undefined =>
    specialization.learningSpecialization,
);

const selectLearningTitle = createSelector(
  selectLearningSpecialization,
  (learningSpecialization): string => {
    if (!learningSpecialization) return '';
    return getSpecializationLabel(learningSpecialization);
  },
);

const selectLearningDescription = createSelector(
  selectLearningSpecialization,
  (learningSpecialization): string => {
    if (!learningSpecialization) return '';
    return specializationWiki[learningSpecialization];
  },
);
