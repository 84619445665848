import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Specialization } from 'core/enums';
import { AppThunk } from 'redux/store';
import { FullSpecialization, SpecializationState } from '.';

const initialState: SpecializationState = {
  fullSpecialization: undefined,
  isCreating: false,
  topSpecialization: undefined,
  subSpecializations: [],
  specialization: undefined,
  isDeleting: false,
  isLearning: false,
  learningSpecialization: undefined,
};

export const specializationSlice = createSlice({
  name: 'specialization',
  initialState,
  reducers: {
    setFullSpecialization: (
      state,
      action: PayloadAction<FullSpecialization>,
    ): void => {
      state.fullSpecialization = action.payload;
    },
    unsetFullSpecialization: (state, _action: PayloadAction): void => {
      state.fullSpecialization = undefined;
    },
    setIsCreatingTo: (state, action: PayloadAction<boolean>): void => {
      state.isCreating = action.payload;
    },
    resetSpecializationCreate: (state, _action: PayloadAction): void => {
      state.isCreating = false;
    },
    setTopSpecialization: (
      state,
      action: PayloadAction<Specialization>,
    ): void => {
      state.topSpecialization = action.payload;
      state.subSpecializations = [];
      state.specialization = undefined;
    },
    setSubSpecializations: (
      state,
      action: PayloadAction<Specialization>,
    ): void => {
      const isExist = state.subSpecializations.some(
        (ss) => ss === action.payload,
      );
      if (isExist)
        state.subSpecializations = state.subSpecializations.filter(
          (ss) => ss !== action.payload,
        );
      else state.subSpecializations.push(action.payload);
    },
    setSpecialization: (state, action: PayloadAction<Specialization>): void => {
      state.specialization = action.payload;
    },
    resetSelection: (state, _action: PayloadAction): void => {
      state.topSpecialization = undefined;
      state.subSpecializations = [];
      state.specialization = undefined;
    },
    setIsDeletingTo: (state, action: PayloadAction<boolean>): void => {
      state.isDeleting = action.payload;
    },
    setLearnSpecialization: (
      state,
      action: PayloadAction<Specialization>,
    ): void => {
      state.isLearning = true;
      state.learningSpecialization = action.payload;
    },
    unsetLearnSpecialization: (state, _action: PayloadAction): void => {
      state.isLearning = false;
      state.learningSpecialization = undefined;
    },
    reset: (_state, _action: PayloadAction): SpecializationState => ({
      ...initialState,
    }),
  },
});

const { reset } = specializationSlice.actions;
export const {
  setFullSpecialization,
  unsetFullSpecialization,
  setIsCreatingTo,
  resetSpecializationCreate,
  setTopSpecialization,
  setSubSpecializations,
  setSpecialization,
  resetSelection,
  setIsDeletingTo,
  setLearnSpecialization,
  unsetLearnSpecialization,
} = specializationSlice.actions;

export const resetSpecialization = (): AppThunk => async (
  dispatch,
  _getState,
): Promise<void> => {
  dispatch(reset());
};
