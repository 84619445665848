import { ReactElement } from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';
import { StatusBadge } from 'components';
import { useSession, useSessionInfoParams } from 'hooks';

export const SessionInfo = (): ReactElement => {
  const params = useSessionInfoParams();
  const { data: sessionView } = useSession(
    params.session_uuid,
    params.pat_uuid,
  );

  return (
    <Card className="mt-3">
      <Card.Body>
        <Row>
          <Col md={6}>
            <div className="description-item">
              <span>Patient</span>
              {sessionView?.patient_name}
            </div>
          </Col>
          <Col md={6}>
            <div className="description-item">
              <span>Date &amp; Time</span>
              {moment(sessionView?.consultation_date).format(
                'ddd. MMM. DD, YYYY h:mm A',
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <div className="description-item">
              <span>Chief Complaint (Health Concerns)</span>
              {sessionView?.health_query}
            </div>
          </Col>
          <Col md={6}>
            <div className="description-item">
              <span>Diagnosis</span>
              {sessionView?.diagnosis}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <div className="description-item">
              <span>Therapist</span>
              {sessionView?.physician_name}
            </div>
          </Col>
          <Col md={6}>
            <div className="description-item">
              <span>Status</span>
              {sessionView && <StatusBadge status={sessionView.status} />}
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default SessionInfo;
