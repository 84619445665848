import { useCurrentUser, useScheduleSlots } from 'hooks';
import { FC, ReactElement, ReactNode, ReactNodeArray, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import { useSelector } from 'react-redux';
import { User } from 'redux/auth';
import {
  resetSchedule,
  selectDay,
  selectWeekSlot,
  setWeekSlot,
  WeekSlot,
} from 'redux/schedule';
import { useAppDispatch } from 'redux/store';

export const WeeklySchedule: FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const { data: user } = useCurrentUser();
  const weekSlot = useSelector(selectWeekSlot);
  const day = useSelector(selectDay);
  const { weekSlots } = useScheduleSlots({
    phy_uuid: (user as User).uuid,
  });

  useEffect(() => {
    return () => {
      dispatch(resetSchedule());
    };
  }, [dispatch]);

  const renderSchedule = (): ReactElement => (
    <Card body className="mt-3">
      <Table responsive borderless className="schedule-table">
        <thead>
          <tr>
            <th>Time</th>
            <th>Monday</th>
            <th>Tuesday</th>
            <th>Wednesday</th>
            <th>Thursday</th>
            <th>Friday</th>
            <th>Saturday</th>
            <th>Sunday</th>
          </tr>
        </thead>
        <tbody>{renderRows()}</tbody>
      </Table>
    </Card>
  );

  const renderRows = (): ReactNodeArray =>
    weekSlots.map(
      (ws): ReactNode => {
        const isSelectedRow = !!weekSlot && weekSlot.time_id === ws.time_id;
        return (
          <tr key={ws.time_id}>
            <td>{ws.time_period}</td>
            <td
              onClick={!!ws[1] ? () => selectRow(ws, 1) : undefined}
              className={isSelectedRow && day === 1 ? 'active' : ''}
            >
              {!!ws[1] ? 'APPROVED' : undefined}
            </td>
            <td
              onClick={!!ws[2] ? () => selectRow(ws, 2) : undefined}
              className={isSelectedRow && day === 2 ? 'active' : ''}
            >
              {!!ws[2] ? 'APPROVED' : undefined}
            </td>
            <td
              onClick={!!ws[3] ? () => selectRow(ws, 3) : undefined}
              className={isSelectedRow && day === 3 ? 'active' : ''}
            >
              {!!ws[3] ? 'APPROVED' : undefined}
            </td>
            <td
              onClick={!!ws[4] ? () => selectRow(ws, 4) : undefined}
              className={isSelectedRow && day === 4 ? 'active' : ''}
            >
              {!!ws[4] ? 'APPROVED' : undefined}
            </td>
            <td
              onClick={!!ws[5] ? () => selectRow(ws, 5) : undefined}
              className={isSelectedRow && day === 5 ? 'active' : ''}
            >
              {!!ws[5] ? 'APPROVED' : undefined}
            </td>
            <td
              onClick={!!ws[6] ? () => selectRow(ws, 6) : undefined}
              className={isSelectedRow && day === 6 ? 'active' : ''}
            >
              {!!ws[6] ? 'APPROVED' : undefined}
            </td>
            <td
              onClick={!!ws[0] ? () => selectRow(ws, 7) : undefined}
              className={isSelectedRow && day === 7 ? 'active' : ''}
            >
              {!!ws[0] ? 'APPROVED' : undefined}
            </td>
          </tr>
        );
      },
    );

  const selectRow = (weekSlot: WeekSlot, day: number): void => {
    dispatch(setWeekSlot({ weekSlot, day }));
  };

  return renderSchedule();
};

export default WeeklySchedule;
