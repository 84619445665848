import { FC, ReactElement, useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { useCurrentUser, useSendEmailVerification } from 'hooks';

const BEFORE_RESEND = 60;

export const EmailVerification: FC = (): ReactElement => {
  const [remaining, setRemaining] = useState<number>(0);
  const { data: user, isEmailVerificationStep } = useCurrentUser();
  const {
    isLoading: isEmailSending,
    mutateAsync,
    reset,
  } = useSendEmailVerification();

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  useEffect(() => {
    const t =
      remaining > 0 &&
      setInterval(() => {
        if (remaining > 0) setRemaining(remaining - 1);
      }, 1000);
    return () => {
      if (t) clearInterval(t);
    };
  }, [remaining]);

  const renderMain = (): ReactElement => (
    <Card body>
      <Alert variant="info">
        Please check your email to verify your account. If you did not see it in
        the inbox you may check it in the spam folder.
      </Alert>
      <Button
        variant="secondary"
        disabled={remaining > 0 || isEmailSending}
        onClick={resendEmail}
      >
        {renderCounter()}Resend Email
      </Button>
    </Card>
  );

  const renderCounter = (): string => {
    if (remaining > 0) return `(${remaining}) `;
    return '';
  };

  const resendEmail = async (): Promise<void> => {
    if (!user) return;
    await mutateAsync(user.uuid);
    setRemaining(BEFORE_RESEND);
  };

  if (!isEmailVerificationStep) return <></>;

  return renderMain();
};

export default EmailVerification;
