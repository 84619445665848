import { ReactElement } from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';
import { Service } from './components';

export const Services = (): ReactElement => {
  const history = useHistory();

  const renderMain = (): ReactElement => (
    <div className="home__specialization">
      <Container className="content">
        <h1>Our Services</h1>
        <div className="services">
          <Service
            title="Occupational Therapy"
            description="Teaching children how to be kids by having fun, participating in
              everyday activities in home, school, and community"
            imgSrc="./services/occupational_therapy.svg"
          />
          <Service
            title="Speech Therapy"
            description="Helping your child to express themselves appropriately, and
              understand people, things and events happening around them"
            imgSrc="./services/speech_therapy.svg"
          />
          <Service
            title="Physical Therapy"
            description="Leading your child to explore the environment through functional
              movement"
            imgSrc="./services/physical_therapy.svg"
          />
          <Service
            title="Special Education"
            description="Developing academic skills needed for school and adaptive skills
              necessary for them to survive"
            imgSrc="./services/special_education.svg"
          />
        </div>
        <Button variant="light" size="lg" onClick={redirectToContactPage}>
          Still unsure? Contact us!
        </Button>
      </Container>
    </div>
  );

  const redirectToContactPage = (): void => {
    history.push('/contact');
  };

  return renderMain();
};

export default Services;
