import { useParams } from 'react-router-dom';

type Params = {
  pat_uuid: string;
  session_uuid: string;
};

export const useSessionInfoParams = (): Params => {
  const params = useParams<Params>();
  return params;
};
