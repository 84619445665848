import { FC, ReactElement, useEffect, useState } from 'react';
import { AlertMessage, TopbarPublic } from 'components';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from 'core/enums';
import { useForgotPassword } from 'hooks';

export type PasswordInputs = {
  email: string;
};

const schema: yup.SchemaOf<PasswordInputs> = yup
  .object()
  .shape({
    email: yup.string().required(ErrorMessage.REQUIRED),
  })
  .defined();

export const PasswordForgotPage: FC = (): ReactElement => {
  const [isSuccess, setIsSuccess] = useState(false);
  const {
    isLoading: isSubmitting,
    isError,
    mutateAsync,
    reset: resetApi,
  } = useForgotPassword();
  const {
    register,
    unregister,
    errors,
    handleSubmit,
    reset: resetForm,
  } = useForm<PasswordInputs>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    return () => {
      unregister(['email']);
    };
  }, [unregister]);

  const renderPage = (): ReactElement => (
    <>
      <header>
        <TopbarPublic />
        <div className="hero password-forgot">
          <Container className="content">
            <h1>Forgot Password</h1>
          </Container>
        </div>
      </header>
      <main className="hero-main">
        <Container className="hero-form">
          <Card body>
            <AlertMessage visible={isSuccess} variant="success">
              Please check your email for a new password. If you cannot find the
              email confirmation from your Inbox, please check your Spam folder.
            </AlertMessage>
            <AlertMessage visible={isError} />
            <Form onSubmit={handleSubmit(contact)}>
              <Form.Group controlId="email">
                <Form.Label className="required">Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  name="email"
                  ref={register}
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email?.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Button variant="primary" type="submit" disabled={isSubmitting}>
                Submit
              </Button>
            </Form>
          </Card>
        </Container>
      </main>
    </>
  );

  const contact = async (inputs: PasswordInputs): Promise<void> => {
    resetApi();
    setIsSuccess(false);
    await mutateAsync(inputs.email);
    setIsSuccess(true);
    resetForm({
      email: '',
    });
  };

  return renderPage();
};

export default PasswordForgotPage;
