import { FC, ReactElement, ReactNode, ReactNodeArray } from 'react';
import Linkify from 'react-linkify';

export interface ParagraphListProps {
  text?: string[];
  children?: string;
}

export const ParagraphList: FC<ParagraphListProps> = (props): ReactElement => {
  const { text, children } = props;

  const renderParagraphs = (): ReactNodeArray => {
    let t: string[];
    if (!!text) t = text;
    else if (!!children) {
      t = children.split('\n');
    } else t = [];
    return t.map((t, key): ReactNode => <p key={key}>{t}</p>);
  };

  return (
    <div className="paragraphs">
      <Linkify>{renderParagraphs()}</Linkify>
    </div>
  );
};

export default ParagraphList;
