import { RootState } from 'redux/store';
import { UiState } from '.';
import { createSelector } from '@reduxjs/toolkit';
import { Role } from 'core/enums';

export {
  selectUi,
  selectRegistrationTitle,
  selectRegistrationClass,
  selectRegistrationRole,
};

const selectUi = (state: RootState): UiState => state.ui;

const selectRegistrationTitle = createSelector(
  selectUi,
  (ui): string => ui.registrationTitle,
);

const selectRegistrationClass = createSelector(
  selectUi,
  (ui): string => ui.registrationClass,
);

const selectRegistrationRole = createSelector(
  selectUi,
  (ui): Role => ui.registrationRole,
);
