import { ReactElement } from 'react';
import Container from 'react-bootstrap/Container';
import facebook from 'images/facebook.svg';
import instagram from 'images/instagram.svg';
import linkedin from 'images/linkedin.svg';
import playStoreBadge from 'images/play-store-badge.png';
import appStoreBadge from 'images/app-store-badge.png';
import { useHistory } from 'react-router-dom';

export const Footer = (): ReactElement => {
  const history = useHistory();

  const renderMain = (): ReactElement => (
    <div className="home__footer">
      <Container className="content">
        <div className="footer-items">
          <div className="footer-item">
            <span className="footer-title">TheraWee</span>
            <div className="socials">
              <a
                href="https://www.facebook.com/TheraWeeAPP"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={facebook} alt="TheraWee Facebook" />
              </a>
              <a
                href="https://www.linkedin.com/company/therawee"
                target="_blank"
                rel="noopener noreferrer"
                className="ml-2"
              >
                <img src={linkedin} alt="TheraWee LinkedIn" />
              </a>
              <a
                href="https://www.instagram.com/therawee.app"
                target="_blank"
                rel="noopener noreferrer"
                className="ml-2"
              >
                <img src={instagram} alt="TheraWee Instagram" />
              </a>
            </div>
          </div>
          <div className="footer-item">
            <span className="footer-title">Navigation</span>
            <div className="navigations">
              <a href="/register/patient" onClick={redirectToUrl}>
                Book Now
              </a>
              <a href="/contact" onClick={redirectToUrl}>
                Contact Us
              </a>
              <a href="/tncandprivacypolicy" onClick={redirectToUrl}>
                Terms and Conditions and Privacy Policy
              </a>
            </div>
          </div>
          <div className="footer-item">
            <span className="footer-title">Coming Soon</span>
            <div className="stores">
              <img src={playStoreBadge} alt="Google Play Store" />
              <img src={appStoreBadge} alt="Apple App Store" />
            </div>
          </div>
        </div>
        <hr />
        <div className="powered-by">Powered by Advent IT Solutions</div>
      </Container>
    </div>
  );

  const redirectToUrl = (event: React.MouseEvent<HTMLAnchorElement>): void => {
    event.preventDefault();
    const url = event.currentTarget.getAttribute('href');
    if (!!url) history.push(url);
  };

  return renderMain();
};

export default Footer;
