import { TaskCreate, TaskService, TaskUpdate } from 'api';
import { AxiosError } from 'axios';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { Task } from 'redux/task';

export const useTasks = (session_uuid: string): UseQueryResult<Task[]> => {
  return useQuery(['tasks', session_uuid], () =>
    TaskService.getTasks(session_uuid),
  );
};

export const useCreateTask = (
  session_uuid: string,
): UseMutationResult<void, AxiosError, TaskCreate> => {
  const queryClient = useQueryClient();
  return useMutation(TaskService.createTask, {
    onSuccess: () => {
      queryClient.invalidateQueries(['tasks', session_uuid]);
    },
  });
};

export const useUpdateTask = (
  session_uuid: string,
): UseMutationResult<void, AxiosError, TaskUpdate> => {
  const queryClient = useQueryClient();
  return useMutation(TaskService.updateTask, {
    onSuccess: () => {
      queryClient.invalidateQueries(['tasks', session_uuid]);
    },
  });
};

export const useDeleteTask = (
  session_uuid: string,
): UseMutationResult<void, AxiosError, number> => {
  const queryClient = useQueryClient();
  return useMutation(TaskService.deleteTask, {
    onSuccess: () => {
      queryClient.invalidateQueries(['tasks', session_uuid]);
    },
  });
};
