import { User } from 'redux/auth';
import { CoreService } from '.';
import queryParam from 'query-string';

export { AuthService };

export type Login = {
  username: string;
  password: string;
};

export type Auth = {
  access_token: string;
};

const w = window as any;
w.getCurrentUser = null;
w.login = null;
w.postPasswordForgot = null;

class AuthService {
  public static async getCurrentUser(): Promise<User> {
    const data = await CoreService.get<any[]>(
      '/api/cc-service/users/user',
      'getCurrentUser',
    );
    if (data.length === 1) return data[0] as User;
    else if (data.length === 2) {
      return {
        ...data[0][0],
        ...data[1][0],
      } as User;
    } else throw new Error(`Response has ${data.length} item(s).`);
  }

  public static cancelGetCurrentUser(): void {
    CoreService.cancelRequest('getCurrentUser');
  }

  public static login(user: Login): Promise<Auth> {
    return CoreService.postCore<Auth, string>(
      '/api/cc-service/login',
      queryParam.stringify({
        ...user,
        grant_type: 'password',
      }),
      'login',
    );
  }

  public static cancelLogin(): void {
    CoreService.cancelRequest('login');
  }
  public static async postPasswordForgot(email: string): Promise<void> {
    await CoreService.post(
      '/api/cc-service/users/reset-password',
      {
        params0: email,
      },
      'postPasswordForgot',
    );
  }

  public static cancelPostPasswordForgot(): void {
    CoreService.cancelRequest('postPasswordForgot');
  }
}
