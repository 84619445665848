import { FC, ReactElement } from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { setIsLicenseUpdatingTo } from 'redux/auth';
import { useAppDispatch } from 'redux/store';
import { Role } from 'core/enums';
import moment from 'moment';
import { useCurrentUser } from 'hooks';

export const PhysicianLicense: FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const { data: user } = useCurrentUser();

  if (!user || user.role !== Role.PHYSICIAN) return <></>;

  const renderProfile = (): ReactElement => (
    <Card className="mt-3">
      <Card.Body>
        <Card.Title className="card-controls">
          PRC License
          <Button
            variant="link"
            className="btn-link-secondary"
            onClick={startUpdating}
          >
            Edit
          </Button>
        </Card.Title>
        <Row>
          <Col>
            <div className="description-item">
              <span>License Number</span>
              {user.prc}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <div className="description-item">
              <span>Registration</span>
              {moment(user.prc_reg_date).format('MMMM D, YYYY')}
            </div>
          </Col>
          <Col md={6}>
            <div className="description-item">
              <span>Valid Until</span>
              {moment(user.prc_val_date).format('MMMM D, YYYY')}
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );

  const startUpdating = (): void => {
    dispatch(setIsLicenseUpdatingTo(true));
  };

  return renderProfile();
};

export default PhysicianLicense;
