import React, { FC, ReactElement, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { AlertMessage } from 'components';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage, Gender } from 'core/enums';
import moment from 'moment';
import Col from 'react-bootstrap/Col';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { CoreService } from 'api';
import { setIsUpdatingTo, selectIsUpdating, resetUpdate } from 'redux/auth';
import { useAppDispatch } from 'redux/store';
import NumberFormat from 'react-number-format';
import { useCurrentUser, useUpdateUser } from 'hooks';

export type AccountInfoFormInputs = {
  first_name: string;
  middle_name: string;
  last_name: string;
  username: string;
  email: string;
  gender: Gender;
  month: string;
  day: string;
  year: string;
  mobile_number: string;
  landline_number: string;
  address: string;
};

const yearMinimum = 1900;
const yearToday = moment().year();
const schema: yup.SchemaOf<AccountInfoFormInputs> = yup
  .object()
  .shape({
    first_name: yup.string().required(ErrorMessage.REQUIRED),
    last_name: yup.string().required(ErrorMessage.REQUIRED),
    username: yup.string().required(ErrorMessage.REQUIRED),
    email: yup
      .string()
      .required(ErrorMessage.REQUIRED)
      .email(ErrorMessage.EMAIL),
    month: yup
      .string()
      .required(ErrorMessage.REQUIRED)
      .test('date', ErrorMessage.DATE, (month, ctx) => {
        const { day, year } = ctx.parent;
        if (!month || !day || !year) return true;
        return moment(`${month}/${day}/${year}`, 'M/D/YYYY').isValid();
      }),
    day: yup.string().required(ErrorMessage.REQUIRED),
    year: yup.string().required(ErrorMessage.REQUIRED),
    mobile_number: yup
      .string()
      .test('mobile_number', ErrorMessage.REQUIRED, (value) => {
        if (!value || value.indexOf('_') !== -1 || value.length < 13)
          return false;
        return true;
      }),
    address: yup.string().required(ErrorMessage.REQUIRED),
  })
  .defined();

export const AccountUpdate: FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const {
    register,
    handleSubmit,
    control,
    errors,
    setValue,
  } = useForm<AccountInfoFormInputs>({
    resolver: yupResolver(schema),
  });
  const isUpdating = useSelector(selectIsUpdating);
  const { data: user } = useCurrentUser();
  const { isLoading: isSubmitting, isError, mutateAsync } = useUpdateUser();

  useEffect(() => {
    return () => {
      dispatch(resetUpdate());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!isUpdating || !user) return;
    setValue('first_name', user?.first_name);
    setValue('middle_name', user?.middle_name);
    setValue('last_name', user?.last_name);
    setValue('username', user?.username);
    setValue('email', user?.email);
    setValue('gender', user?.gender);
    const bd = moment(user?.birth_date, 'M/D/YYYY');
    setValue('month', bd.format('M'));
    setValue('day', bd.format('D'));
    setValue('year', bd.format('YYYY'));
    setValue('mobile_number', user?.mobile_number);
    setValue('landline_number', user?.landline_number);
    setValue('address', user?.address);
  }, [isUpdating, user, setValue]);

  const renderModal = (): ReactElement => (
    <Modal show={isUpdating} onHide={stopUpdating}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Account Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertMessage visible={isError} />
          <AlertMessage visible={!!errorMessage}>{errorMessage}</AlertMessage>
          <Form.Group controlId="first_name">
            <Form.Label className="required">First Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your first name"
              name="first_name"
              ref={register}
              isInvalid={!!errors.first_name}
            />
            <Form.Control.Feedback type="invalid">
              {errors.first_name?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="middle_name">
            <Form.Label>Middle Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your middle name"
              name="middle_name"
              ref={register}
            />
          </Form.Group>
          <Form.Group controlId="last_name">
            <Form.Label className="required">Last Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your last name"
              name="last_name"
              ref={register}
              isInvalid={!!errors.last_name}
            />
            <Form.Control.Feedback type="invalid">
              {errors.last_name?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="username">
            <Form.Label className="required">Username</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your username"
              name="username"
              ref={register}
              isInvalid={!!errors.username}
            />
            <Form.Control.Feedback type="invalid">
              {errors.username?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="email">
            <Form.Label className="required">Email</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your email"
              name="email"
              ref={register}
              isInvalid={!!errors.email}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="gender">
            <Form.Label className="required">Gender</Form.Label>
            <Form.Control
              as="select"
              placeholder="Select your gender"
              name="gender"
              ref={register}
              isInvalid={!!errors.gender}
            >
              <option value={Gender.MALE}>Male</option>
              <option value={Gender.FEMALE}>Female</option>
              <option value={Gender.OTHER}>Other</option>
              <option value={Gender.SECRET}>Prefer Not to Say</option>
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.gender?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Row>
            <Form.Group as={Col} controlId="birth-month">
              <Form.Label className="required">Birth Date</Form.Label>
              <Form.Control
                min="1"
                max="12"
                type="number"
                placeholder="MM"
                name="month"
                ref={register}
                isInvalid={!!errors.month}
              />
              <Form.Text className="text-muted">Month (Ex. 12)</Form.Text>
              <Form.Control.Feedback type="invalid">
                {errors.month?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId="birth-day">
              <Form.Label className="text-white">Day</Form.Label>
              <Form.Control
                min="1"
                max="31"
                type="number"
                placeholder="DD"
                name="day"
                ref={register}
                isInvalid={!!errors.day}
              />
              <Form.Text className="text-muted">Day (Ex. 31)</Form.Text>
              <Form.Control.Feedback type="invalid">
                {errors.day?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId="birth-year">
              <Form.Label className="text-white">Year</Form.Label>
              <Form.Control
                min={yearMinimum}
                max={yearToday}
                type="number"
                placeholder="YYYY"
                name="year"
                ref={register}
                isInvalid={!!errors.year}
              />
              <Form.Text className="text-muted">Year (Ex. 1980)</Form.Text>
              <Form.Control.Feedback type="invalid">
                {errors.year?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Group controlId="mobile_number">
            <Form.Label className="required">Mobile Number</Form.Label>
            <Controller
              as={<NumberFormat customInput={Form.Control} />}
              defaultValue=""
              control={control}
              format="09## ### ####"
              mask="_"
              allowEmptyFormatting
              name="mobile_number"
              isInvalid={!!errors.mobile_number}
            />
            <Form.Text className="text-muted">Ex. 0912 345 6789</Form.Text>
            <Form.Control.Feedback type="invalid">
              {errors.mobile_number?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="landline_number">
            <Form.Label>Landline Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your landline number"
              name="landline_number"
              ref={register}
            />
          </Form.Group>
          <Form.Group controlId="address">
            <Form.Label className="required">Address</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              placeholder="Enter your address"
              name="address"
              ref={register}
              isInvalid={!!errors.address}
            />
            <Form.Control.Feedback type="invalid">
              {errors.address?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" disabled={isSubmitting} onClick={stopUpdating}>
            Cancel
          </Button>
          <Button variant="primary" type="submit" disabled={isSubmitting}>
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );

  const stopUpdating = (): void => {
    if (isSubmitting) return;
    dispatch(setIsUpdatingTo(false));
  };

  const onSubmit = async (data: AccountInfoFormInputs): Promise<void> => {
    if (!user) {
      return;
    }
    setErrorMessage('');
    try {
      await mutateAsync({
        ...user,
        ...data,
        birth_date: `${parseInt(data.year)}-${parseInt(data.month)}-${parseInt(
          data.day,
        )}`,
      });
      stopUpdating();
    } catch (err) {
      if (!CoreService.isAbort(err)) {
        if (!!err.message) {
          const errMessage = err.message as string;
          if (errMessage.includes('UNIQUE KEY')) {
            if (errMessage.includes('u_username'))
              setErrorMessage('Username is already taken');
            else if (errMessage.includes('u_email'))
              setErrorMessage('Email is already taken');
          }
        }
      }
    }
  };

  return renderModal();
};

export default AccountUpdate;
