import { ReactElement } from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useSession, useSessionInfoParams } from 'hooks';

export const MeetingInfo = (): ReactElement => {
  const params = useSessionInfoParams();
  const { data: sessionView, isCompleted } = useSession(
    params.session_uuid,
    params.pat_uuid,
  );

  return (
    <Card className="mt-3 mb-3">
      <Card.Body>
        <Row>
          <Col>
            <div className="description-item">
              <span>Zoom URL</span>
              {isCompleted ? (
                <em>Not Available</em>
              ) : (
                <a
                  href={sessionView?.zoom}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Open Zoom
                </a>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <div className="description-item">
              <span>Zoom Meeting ID</span>
              {isCompleted ? (
                <em>Not Available</em>
              ) : (
                sessionView?.zoom_meeting_id
              )}
            </div>
          </Col>
          <Col md={6}>
            <div className="description-item">
              <span>Zoom Password</span>
              {isCompleted ? (
                <em>Not Available</em>
              ) : (
                sessionView?.zoom_password
              )}
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default MeetingInfo;
