import {
  Auth,
  AuthService,
  Login,
  UserCreate,
  UserService,
  UserUpdate,
} from 'api';
import { AxiosError } from 'axios';
import { EmailStatus, Role } from 'core/enums';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { User } from 'redux/auth';
import { hasToken, removeToken } from 'utils/auth';
import { UseCurrentUserResult } from '.';

export const useCurrentUser = (): UseCurrentUserResult &
  UseQueryResult<User> => {
  const queryClient = useQueryClient();
  const result = useQuery('current-user', AuthService.getCurrentUser, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: hasToken(),
    onError: () => {
      removeToken();
      queryClient.clear();
    },
  });
  const isEmailVerificationStep =
    !!result.data && result.data.email_verified === EmailStatus.UNVERIFIED;
  const isPersonalInfoStep =
    !isEmailVerificationStep && !!result.data && !result.data.mobile_number;
  const isPhysicianInfoStep =
    !isEmailVerificationStep &&
    !isPersonalInfoStep &&
    !!result.data &&
    !result.data.zoom;
  const isPhysicianLicenseStep =
    !isEmailVerificationStep &&
    !isPersonalInfoStep &&
    !isPhysicianInfoStep &&
    !!result.data &&
    !result.data.prc;
  return {
    isAuthenticated: !!result.data,
    isPatientUser: result.data?.role === Role.PATIENT,
    isPhysicianUser: result.data?.role === Role.PHYSICIAN,
    isEmailVerificationStep,
    isPersonalInfoStep,
    isPhysicianInfoStep,
    isPhysicianLicenseStep,
    isRegistrationStepsCompleted:
      isEmailVerificationStep || !result.data
        ? false
        : result.data.role === Role.PATIENT
        ? !isPersonalInfoStep
        : !isPersonalInfoStep &&
          !isPhysicianInfoStep &&
          !isPhysicianLicenseStep,
    ...result,
  };
};

export const useLogIn = (): UseMutationResult<Auth, AxiosError, Login> => {
  return useMutation(AuthService.login);
};

export const useCreateUser = (): UseMutationResult<
  User,
  AxiosError,
  UserCreate
> => {
  return useMutation(UserService.createUser);
};

export const useUpdateUser = (): UseMutationResult<
  User,
  AxiosError,
  UserUpdate
> => {
  const queryClient = useQueryClient();
  return useMutation(UserService.updateUser, {
    onSuccess: () => {
      queryClient.invalidateQueries('current-user');
    },
  });
};

export const useUpdatePassword = (): UseMutationResult<
  void,
  AxiosError,
  string
> => {
  return useMutation(UserService.changePassword);
};

export const useForgotPassword = (): UseMutationResult<
  void,
  AxiosError,
  string
> => {
  return useMutation(AuthService.postPasswordForgot);
};

export const useSendEmailVerification = (): UseMutationResult<
  void,
  AxiosError,
  string
> => {
  return useMutation(UserService.sendEmailVerification);
};
