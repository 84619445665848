import { useCurrentUser, useSession, useSessionInfoParams } from 'hooks';
import { ReactElement } from 'react';
import Alert from 'react-bootstrap/Alert';

export const FeedbackAlert = (): ReactElement => {
  const { isPatientUser } = useCurrentUser();
  const params = useSessionInfoParams();
  const { isCompleted } = useSession(params.session_uuid, params.pat_uuid);

  if (!isPatientUser || !isCompleted) return <></>;

  return (
    <Alert variant="warning">
      We would like to hear your feedback! How was your therapy session? Please
      click{' '}
      <a
        href="https://bit.ly/TheraWeeFB"
        target="_blank"
        rel="noopener noreferrer"
      >
        HERE
      </a>{' '}
      to let us know what you think.
    </Alert>
  );
};

export default FeedbackAlert;
