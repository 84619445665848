import React, { ReactElement, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { AlertMessage } from 'components';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  setIsUpdatingHomeworkTo,
  selectIsUpdatingHomework,
} from 'redux/session';
import { useAppDispatch } from 'redux/store';
import { useSession, useUpdateSession, useSessionInfoParams } from 'hooks';

export type HomeworkForm = {
  therapy_homework: string;
};

export const HomeworkUpdate = (): ReactElement => {
  const dispatch = useAppDispatch();
  const params = useSessionInfoParams();
  const { register, handleSubmit, setValue } = useForm<HomeworkForm>();
  const isUpdatingHomework = useSelector(selectIsUpdatingHomework);
  const { data: sessionView } = useSession(
    params.session_uuid,
    params.pat_uuid,
  );
  const {
    isLoading: isSubmitting,
    isError: isSubmitError,
    mutateAsync,
  } = useUpdateSession(params.session_uuid);

  useEffect(() => {
    if (!isUpdatingHomework || !sessionView) return;
    setValue('therapy_homework', sessionView.therapy_homework);
  }, [isUpdatingHomework, sessionView, setValue]);

  const renderModal = (): ReactElement => (
    <Modal show={isUpdatingHomework} onHide={stopUpdating}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Homework</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertMessage visible={isSubmitError} />
          <Form.Group controlId="therapy_homework">
            <Form.Label>Therapy homework</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              placeholder="Enter therapy homework"
              name="therapy_homework"
              ref={register}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" disabled={isSubmitting} onClick={stopUpdating}>
            Cancel
          </Button>
          <Button variant="primary" type="submit" disabled={isSubmitting}>
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );

  const stopUpdating = (): void => {
    if (isSubmitting) return;
    dispatch(setIsUpdatingHomeworkTo(false));
  };

  const onSubmit = async (data: HomeworkForm): Promise<void> => {
    if (!sessionView) {
      return;
    }
    await mutateAsync({
      booking_uuid: sessionView.uuid,
      health_query: sessionView.health_query,
      specialization: sessionView.specialization,
      physician_uuid: sessionView.physician,
      feedback_like: sessionView.feedback_like,
      feedback_improve: sessionView.feedback_improve,
      therapy_homework: data.therapy_homework,
      therapy_recommendation: sessionView.therapy_recommendation,
    });
    stopUpdating();
  };

  return renderModal();
};

export default HomeworkUpdate;
