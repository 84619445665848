import { Role } from 'core/enums';

export { getRoleFromText };

const getRoleFromText = (text: string): Role | null => {
  switch (text.toUpperCase()) {
    case Role.PATIENT:
      return Role.PATIENT;
    case Role.PHYSICIAN:
      return Role.PHYSICIAN;
    default:
      return null;
  }
};
