import { FC, ReactElement } from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { setIsPasswordChangingTo, setIsUpdatingTo } from 'redux/auth';
import moment from 'moment';
import { getAccountImage } from 'utils/profile';
import { useAppDispatch } from 'redux/store';
import { toFullName } from 'utils/user';
import { useCurrentUser } from 'hooks';

export const AccountProfile: FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const { data: user } = useCurrentUser();

  if (!user) return <></>;

  const renderProfile = (): ReactElement => (
    <Card className="mt-3 profile-view">
      <Card.Body className="body">
        <div className="left">
          <div className="section picture">
            <img src={getAccountImage(user.gender)} alt={toFullName(user)} />
          </div>
          <div className="section">
            <Button variant="light" onClick={startUpdating} block>
              Edit Profile
            </Button>
            <Button variant="link" onClick={startPasswordChanging} block>
              Change Password
            </Button>
          </div>
        </div>
        <div className="right">
          <Row>
            <Col>
              <h4>{toFullName(user)}</h4>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="description-item">
                <span>Username</span>
                {user.username}
              </div>
            </Col>
            <Col md={6}>
              <div className="description-item">
                <span>Email</span>
                {user.email}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="description-item">
                <span>Gender</span>
                {user.gender}
              </div>
            </Col>
            <Col md={6}>
              <div className="description-item">
                <span>Birth Date</span>
                {moment(user.birth_date, 'M/D/YYYY').format('MMM. D, YYYY')}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="description-item">
                <span>Mobile Number</span>
                {user.mobile_number}
              </div>
            </Col>
            <Col md={6}>
              <div className="description-item">
                <span>Landline Number</span>
                {user.landline_number}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="description-item">
                <span>Address</span>
                {user.address}
              </div>
            </Col>
          </Row>
        </div>
      </Card.Body>
    </Card>
  );

  const startUpdating = (): void => {
    dispatch(setIsUpdatingTo(true));
  };

  const startPasswordChanging = (): void => {
    dispatch(setIsPasswordChangingTo(true));
  };

  return renderProfile();
};

export default AccountProfile;
