import { FC, ReactElement } from 'react';
import Alert, { AlertProps } from 'react-bootstrap/Alert';

export interface AlertMessageProps extends AlertProps {
  visible?: boolean;
}

export const AlertMessage: FC<AlertMessageProps> = (props): ReactElement => {
  const { visible = true, children = 'Server Error', ...rest } = props;

  if (!visible) return <></>;

  return <Alert variant="danger" children={children} {...rest} />;
};

export default AlertMessage;
