import { FC, ReactElement } from 'react';
import { ReturnHomeButton } from 'components';

export const UnauthorizedPage: FC = (): ReactElement => (
  <>
    <h2>You are not authorized to this page.</h2>
    <ReturnHomeButton />
  </>
);

export default UnauthorizedPage;
