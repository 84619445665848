import { useEffect } from 'react';

export { useBackgroundOnScroll };

function useBackgroundOnScroll(selector: string): void {
  useEffect(() => {
    const $topbar = getTopbarElement();
    if ($topbar) addScrollEvent($topbar);
    return () => {
      removeScrollEvent($topbar);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTopbarElement = (): Element | null =>
    document.querySelector(selector);

  const addScrollEvent = ($element: Element): void => {
    window.addEventListener('scroll', () => {
      if (isOffset()) removeClass($element);
      else addClass($element);
    });
  };

  const isOffset = (): boolean => window.pageYOffset < 100;

  const removeClass = ($element: Element): void =>
    $element.classList.remove('top');

  const addClass = ($element: Element): void => $element.classList.add('top');

  const removeScrollEvent = ($element: Element | null): void => {
    window.removeEventListener('scroll', () => {
      return !!$element;
    });
  };
}
