import { RootState } from 'redux/store';
import { TaskState, Task } from '.';
import { createSelector } from '@reduxjs/toolkit';

export {
  selectTaskState,
  selectTask,
  selectIsCreating,
  selectIsUpdating,
  selectIsDeleting,
};

const selectTaskState = (state: RootState): TaskState => state.task;

const selectTask = createSelector(
  selectTaskState,
  (task): Task | undefined => task.task,
);

const selectIsCreating = createSelector(
  selectTaskState,
  (task): boolean => task.isCreating,
);

const selectIsUpdating = createSelector(
  selectTaskState,
  (task): boolean => task.isUpdating,
);

const selectIsDeleting = createSelector(
  selectTaskState,
  (task): boolean => task.isDeleting,
);
