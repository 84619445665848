import React, { FC, lazy, ReactElement, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {
  AnonymousRoute,
  PrivateRoute,
  ScrollToTop,
  UserSetupRoute,
} from 'components';
import { Role } from 'core/enums';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

library.add(faBars, faTimes);

const HomePage = lazy(() => import('pages/home-page'));
const LoginPage = lazy(() => import('pages/login-page'));
const PasswordForgotPage = lazy(() => import('pages/password-forgot-page'));
const RegistrationPage = lazy(() => import('pages/registration-page'));
const UserSetupPage = lazy(() => import('pages/user-setup-page'));
const AccountPage = lazy(() => import('pages/account-page'));
const DashboardPage = lazy(() => import('pages/dashboard-page'));
const PatientPage = lazy(() => import('pages/patient-page'));
const SchedulePage = lazy(() => import('pages/schedule-page'));
const SpecializationPage = lazy(() => import('pages/specialization-page'));
const SessionInfoPage = lazy(() => import('pages/session-info-page'));
const BookingPage = lazy(() => import('pages/booking-page'));
const PaymentSuccessPage = lazy(() => import('pages/payment-success-page'));
const PaymentFailedPage = lazy(() => import('pages/payment-failed-page'));
const ContactPage = lazy(() => import('pages/contact-page'));
const PolicyPage = lazy(() => import('pages/policy-page'));
const NotFoundPage = lazy(() => import('pages/not-found-page'));

export const App: FC = (): ReactElement => (
  <Router>
    <ScrollToTop />
    <Suspense fallback={<>Loading... Please Wait</>}>
      <Switch>
        <AnonymousRoute path="/" exact component={HomePage} />
        <AnonymousRoute path="/login" component={LoginPage} />
        <AnonymousRoute
          path="/passwords/forgot"
          component={PasswordForgotPage}
        />
        <AnonymousRoute path="/register/:type" component={RegistrationPage} />
        <UserSetupRoute path="/users/setup" component={UserSetupPage} />
        <PrivateRoute path="/users/me" component={AccountPage} />
        <PrivateRoute path="/dashboard" component={DashboardPage} />
        <PrivateRoute
          path="/patients/:pat_uuid/sessions/:session_uuid"
          component={SessionInfoPage}
          roles={[Role.PATIENT, Role.PHYSICIAN]}
        />
        <PrivateRoute
          path="/patients/:uuid"
          component={PatientPage}
          roles={[Role.PATIENT]}
        />
        <PrivateRoute
          path="/schedule"
          component={SchedulePage}
          roles={[Role.PHYSICIAN]}
        />
        <PrivateRoute
          path="/specializations"
          component={SpecializationPage}
          roles={[Role.PHYSICIAN]}
        />
        <PrivateRoute
          path="/sessions/new"
          component={BookingPage}
          roles={[Role.PATIENT]}
        />
        <PrivateRoute
          path="/payments/:id/success"
          component={PaymentSuccessPage}
          roles={[Role.PATIENT]}
        />
        <PrivateRoute
          path="/payments/:id/failed"
          component={PaymentFailedPage}
          roles={[Role.PATIENT]}
        />
        <Route path="/tncandprivacypolicy" component={PolicyPage} />
        <Route path="/contact" component={ContactPage} />
        <Route component={NotFoundPage} />
      </Switch>
    </Suspense>
  </Router>
);

export default App;
